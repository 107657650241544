import { useEffect, useRef, useState, } from "react";
import { Alert, Link, Paper, TextareaAutosize, ThemeProvider, Tooltip } from "@mui/material";
import Utils from "../../serverUtils/Utils";
import { STATUS, STATUS_LABEL, STATUS_OPTIONS } from "../../components/Bracket/Bracket";
import { GENDER_TYPES, getGenderLabel } from "../../components/LeagueInformationForm/WeightClasses";
import TournamentModel from "../../serverUtils/models/TournamentModel";
import TournamentPageStyles from "./TournamentPage.module.scss";
import "./DivisionRegistration.scss";
import CartModel from "../../serverUtils/models/CartModel";
import { GRAPPLING_TYPES, getDivisionDisplay, getGrapplingTypeLabel } from "../../components/LeagueInformationForm/Divisions";
import SelectFormInput from "../../components/FormInput/SelectFormInput";
import { getRefundStatusLabel, REFUND_REASONS } from "../../components/TournamentForm/Registrations";
import Icon from "../../components/Icon";
import FilterChips from "../../components/Filters/FilterChips";
import { useStore } from "../../Store";
import AlertPane from "../../components/FormInput/Message";
import Theme from "../../components/FormInput/Theme";
import { IonSpinner } from "@ionic/react";
import { GI_LEVEL_OPTIONS } from "../../components/LeagueInformationForm/Ranks";

const { STORE_ITEM_TYPE } = require("../../serverUtils/models/modelMethods");
export function convertWeight(weight, unit) {
    if (!weight || !unit) {
        return '';
    }
    if (unit !== 'lbs'){
        weight = weight * 0.45359237;
    }else {
        weight = weight * 2.20462;
    }
    return `${Math.ceil(weight)} ${unit}`;
}
const getTournamentWeightUnit = (tournament) => {
    let div = tournament &&
        tournament.getDivisions &&
        tournament.getDivisions().length > 0 &&
        tournament.getDivisions().find(d => d.getWeightClass().weight_unit);
    return div && div.getWeightClass().weight_unit || '';
}

const addDivisionToCart = async ({division, session, tournament, business, isNotQualify, _getSubmittedSkus, getAllPossibleCombinations, submittedCarts}) => {
    const createItem = (sku, unit_price, description, name) => {
        return {
            sku, unit_price, description, name,
            quantity: 0,
            item_type: STORE_ITEM_TYPE.tournament_registration,
            ref: tournament.id,
            ref_type: CartModel.REF_TYPE.tournament
        };
    }
    let sumittedIds = _getSubmittedSkus(true);
    let cartIds = [];
    let cart = session.getCarts().find(c => c.ref === tournament.id);
    if (cart) {
        let divIds = cart.items.filter(i =>
            i.item_type === STORE_ITEM_TYPE.tournament_registration
        ).map(i => i.sku.split(',')).flat();
        cart.items = [];
        cartIds = [...divIds];
    }else {
        cart = {
            status: "A",
            items: [],
            membership: session.id,
            ref: tournament.id,
            ref_type: STORE_ITEM_TYPE.tournament,
            ref_display: tournament.name,
            payment_info: business.payment_info,
            currency: business.currency,
        };
    }

    let cIds = Utils.uniqArray([...sumittedIds, ...cartIds, division.id].flat());
    let noComboFound = true;
    if (cIds.length > 1){
        let posCombos = getAllPossibleCombinations(cIds).sort((b, a) => a.length - b.length);
        while(true){
            let cb = posCombos.shift();
            if (!cb) {
                break;
            }
            let fee = tournament.getDivisionComboPricing(cb);
            if (fee && fee.combo) {
                noComboFound = false;
                let item = createItem(fee.combo, fee.amount);
                let sskus = Utils.intersection(fee.combo.split(','), sumittedIds);
                for (let ssku of sskus) {
                    let c = submittedCarts.find(c => c.items.find(i => i.sku === ssku));
                    if (c) {
                        item.unit_price -= c.items.find(i => i.sku === ssku).unit_price;
                        if (item.unit_price < 0) {
                            item.unit_price = 0;
                        }
                    }
                }
                item.description = fee.combo.split(',').map(id => {
                    let d = tournament.getDivisions().find(d => d.id === id);
                    let isSubmitted = sumittedIds.includes(d.id)? ' (already registered)': '';
                    return `${d.name}${isSubmitted}`;
                }).join(': ');
                item.description = `${fee.fee_info.fee_name}-${item.description}`;
                cart.items.push(item);
                let removeIds = fee.combo.split(',');
                cIds = cIds.filter(c => !removeIds.includes(c));
                if (cIds.length < 2) {
                    break;
                }
                posCombos = getAllPossibleCombinations(cIds);
            }
        }
        for (let c of cIds.filter(i => !sumittedIds.includes(i))) { // add non combo left over
            let div = tournament.getDivisions().find(td => td.id === c);
            if (div.isDivisionStandAlonePricing()){
                cart.items.push(
                    createItem(c, div.getFee(true), `${div.name}`, `${div.name}`)
                );
            }
        }
        if (cart.items.length === 0 || !cart.items.find(i => i.sku.split(',').includes(division.id))){
            return 'error: This is a combination division.  It must be combined with another qualified combination division.';
        }
    }else {
        let div = tournament.getDivisions().find(d => d.id === division.id);
        if (!div.isDivisionStandAlonePricing()) {
            return `warning: This is a combo division[${getDivisionDisplay(div, true)}].  There
                are no available stand alone division to combine with this
                division.  You will need to add a stand alone pricing division first.`;
        }
        cart.items.push(createItem(div.id, div.getFee(true), `${div.name}[${div.id}]`, `${div.name}-${div.code}`));
    }
    if (isNotQualify) {
        let disQualify = isNotQualify();
        if (disQualify) {
            return disQualify;
        }
    }

    let result = cart.id? await CartModel.updateCart(cart) : await CartModel.addCart(cart);
    if (result && result.id) {
        if (!cart.id) {
            cart.id = result.id;
            let carts = [...session.getCarts(), cart];
            session.getCarts = () => carts;
        }
        return;
    }
    return 'error: Error updating cart';
}

export default function DivisionRegistration({ tournament, registered, cbUpdate, getFilterCount }) {
    const session = useStore(state => state.session);
    const [business, setBusiness] = useState();
    const [submittedCarts, setSubmittedRefCarts] = useState();
    const [isEdit, setIsEdit] = useState();
    const [isRefund, setIsRefund] = useState();
    const isInit = useRef(false);
    const getFilterTypes = (session, tournament, registered) => {
        let types = [];
        if (session) {
            if (registered && registered.length > 0) {
                types.push({
                    label: 'Registered',
                    value: 'registered',
                    type: 'registered'
                });
            }
            let { age, weight, gender, gi_level: rank } = session;
            types.push({
                label: 'Recommended',
                value: 'session',
                age: age || 0,
                weight: weight || 0,
                gender,
                rank,
                type: 'session'
            });
            return types;
        }
        const createType = (type, r) => {
            let genders = tournament.getDivisions().find(d => r.genders.includes(d.gender)) && r.genders;
            let grappling_types = tournament.getDivisions().filter(d => r.grappling_types.includes(d.grappling_type)) && r.grappling_types;
            return {
                label: r.name,
                value: r.id,
                type,
                genders,
                grappling_types
            }
        }
        let ranks = (tournament.getRanks && tournament.getRanks() ||[]).map(r => createType('rank', r));
        let ageGroups = (tournament.getAgeGroups && tournament.getAgeGroups() || []).map(ag => createType('age_group', ag));
        types = [...types,
        ...GRAPPLING_TYPES.map(e => ({...e, type: 'grappling_type'})),
        ...GENDER_TYPES.map(e => ({...e, type: 'gender'})),
        ...ageGroups,
        ...ranks,
        ];
        return types;
    }
    const [divisionFilters, setDivisionFilters] = useState([]);
    const setGlobalMessage = useStore(state => state.setGlobalMessage);
    const cart = session && session.getCarts().find(c => c.ref === tournament.id);
    registered = registered || [];
    
    useEffect(() => {
        getFilterCount(getDivisonsByFilters().length);
    }, []);
    useEffect(() => {
        let divfilters = getFilterTypes(session, tournament, registered);
        if (session && divfilters && divfilters.length > 0 && !isInit?.current) {
            setDivisionFilters([divfilters[0]]);
            const {dob, weight, weight_unit, gi_level} = session;
            if (!dob || !weight || !weight_unit || !gi_level){
                setGlobalMessage({url: document.location.pathname, message: 'Your division profile is not complete.  Your can update it from your profile page.'});
            }
            isInit.current = true;
        }
        if (session){
            TournamentModel.getBusinessInfo(tournament.id)
                .then(b => setBusiness(b));
            CartModel.getCartsByRefMembership(tournament.id)
                .then(carts => {
                    carts = carts.filter(c => {
                        return c.items.find(i => i.item_type===STORE_ITEM_TYPE.tournament_registration && i.sku.split(',').length===1);
                    });
                    setSubmittedRefCarts(carts);
                });
        }
    }, [session]);

    const isNotQualify = () => {
        if (!session) {
            return '';
        }
        const {registration_requirements={}, getLeague} = tournament;
        let { gyms = [], leagues = [] } = session;
        let is_require_league = !registration_requirements.league || (registration_requirements.league && leagues.find(l => l.id===getLeague && getLeague().id) && getLeague().status===STATUS.Active);
        // let is_require_gym = !registration_requirements.gym || (registration_requirements.gym && gyms.find(g => g.status === STATUS.Active));
        let is_require_team = !registration_requirements.team || (registration_requirements.team && Utils.intersection(gyms.filter(g => g.status === STATUS.Active && g.id), getLeague().getTeams()).length > 0);
        if (!is_require_league ||
            // !is_require_gym ||
            !is_require_team){
            return <div className="tournament-registration-warning message">
                        <div className="registration-requirements">To register for this tournament, you must have the following requirement(s):
                            <ul>{[!is_require_league&&'Tournament league membership',
                                // !is_require_gym&&'Member of an affiliated gym',
                                !is_require_team&&'Member of an affiliated league team'].filter(r => r)
                                    .map((r, i)=> <li key={i}>{r}</li>)}</ul>
                        </div>
                    </div>;
        }
    }

    const getInCartDivisions = () => {
        let cart = session && session.getCarts().find(c => c.ref === tournament.id);
        if (cart) {
            let inSubmittedCartSkus = _getSubmittedSkus();
            return cart.items.map(i => i.sku.split(',')).flat()
                .filter(d => !inSubmittedCartSkus.includes(d))
                .map(d => tournament.getDivisions().find(td => td.id===d));
        }
        return [];
    }

    const getDivisonsByFilters = () => {
        if (divisionFilters.length === 0) {
            return [];
        }
        if (divisionFilters.find(f => f && f.type === 'registered')) {
            return registered.map(r => tournament.getDivisions().find(d => d.id === r.division));
        }
        let availDivisions = (tournament.getDivisions && tournament.getDivisions() || []);
        let rankFilters = divisionFilters.filter(f => f && f.type === 'rank').map(f => f.value);
        let genderFilters = divisionFilters.filter(f => f && f.type === 'gender').map(f => f.value);
        let typeFilters = divisionFilters.filter(f => f && (f.type === 'grappling_type')).map(f => f.value);
        let ageFilters = divisionFilters.filter(f => f && f.type === 'age_group').map(f => f.value);

        let registeredIds = registered.map(r => r.division);
        let inCartIds = getInCartDivisions().map(d => d.id);
        return availDivisions.filter(division => {
            if (divisionFilters.find(f => f && f.type === 'session')) {
                return getSessionDivisions(division);
            }
            return (rankFilters.length === 0 || rankFilters.includes(division.getRank()?.id)) &&
                (genderFilters.length === 0 || genderFilters.includes(division.gender)) &&
                (typeFilters.length === 0 || typeFilters.includes(division.grappling_type)) &&
                (ageFilters.length === 0 || ageFilters.includes(division.getAgeGroup()?.id))
                ;
        }).filter(d => !registeredIds.includes(d.id) && !inCartIds.includes(d.id) && (d.getFee() || d.isDivisionComboPricing()));
    }

    const isRegistered = division => {
        if (!registered) {
            return;
        }
        let tourDivs = tournament.getDivisions().map(d => d.id);
        return registered?.find(r => tourDivs.includes(r.division) && r.division===division.id);
    }

    const getEditPane = division => {
        if (isEdit && isEdit.division === division.id) {
            return <EditRegistration
                        session={session}
                        getSessionDivisions={getSessionDivisions}
                        tournament={tournament}
                        registration={isEdit}
                        setIsEdit={setIsEdit} 
                        submittedSkus={registered.map(r => r.division)}
                        />
        }
        if (isRefund && isRefund.division === division.id) {
            return <Refund registration={isRefund} setIsRefund={setIsRefund} />;
        }
        return '';
    }

    const handleFilterChange = filter => {
        let sessionSelect = ['session', 'registered'];
        if (sessionSelect.includes(filter.type)) {
            if (divisionFilters.find(f => f.type === filter.type)) {
                return setDivisionFilters([]);
            }
            return setDivisionFilters([filter]);
        }
        if (divisionFilters.find(f => f.value === filter.value)) {
            let f = divisionFilters.filter(f => f.value !== filter.value && !sessionSelect.includes(f.value));
            setDivisionFilters(f);
        } else {
            divisionFilters.push(filter);
            setDivisionFilters([...divisionFilters.filter(f => !sessionSelect.includes(f.value))]);
        }
        getFilterCount(getDivisonsByFilters().length);
    }
    let displayDivisions = getDivisonsByFilters();
    let tweightUnit = getTournamentWeightUnit(tournament);
    const getWeight = () => {
        let weight = `${session.weight} ${session.weight_unit}`;
        if (!tweightUnit || session.weight_unit === tweightUnit){
            return weight;
        }
        return `${weight} Or ${convertWeight(session.weight, tweightUnit)}`;
    }
    let availDivisions = (tournament.getDivisions && tournament.getDivisions() || []);
    let hasSelect = divisionFilters.length > 0;
    let sessionFilterChips = session && getFilterTypes(session, tournament, registered);
    let availableFilterChips = getFilterTypes(null, tournament, registered);
    
    //below is to filter the non result chips
    // const baseFilter = ['session', 'grappling_type', 'gender'];
    // let isSessionSelect = divisionFilters.find(f => f.type === 'session');
    // availableFilterChips = availableFilterChips.filter(f => {
    //     if (divisionFilters.length===0 || isSessionSelect || baseFilter.includes(f.type)){
    //         return true;
    //     }
    //     return displayDivisions.find(d => d.getRank().name === f.label || d.getAgeGroup().name === f.label);
    // });

    let filterChips = [...(sessionFilterChips||[]), ...availableFilterChips]
        .filter(f => {
            if (['registered', 'session'].includes(f.type)) {
                return true;
            }
            if (f) {
                return availDivisions.find(d => d[f.type] === f.value);
            }
        });
    const getTotalFee = () => {
        if (!session || !business) {
            return '';
        }
        let cart = session.getCarts().find(c => c.ref === tournament.id);
        if (!cart){
            return '';
        }
        let total = 0;
        for (let item of cart.items) {
           total += item.unit_price;
        }
        return `Total ${total.toFixed(2)} ${business.currency} `;
    }
    const deleteCart = async () => {
        let r = await CartModel.deleteCart(cart.id);
        if (!r || r.error) {
            return false;
        }
        let carts = session.getCarts().filter(c => c.id !== cart.id);
        session.getCarts = () => carts;
        return cbUpdate(session);
    }

    let inCartDivisions = getInCartDivisions();
    return <div className="DivisionRegistration">
        {isNotQualify()}
        <div className={`filter-chips`}>
            {session && 
                <div className={`yours`}>
                    <div className={`info-wrapper DivisionItem ${TournamentPageStyles.division}`}>
                        <b className="title">Your Division Profile</b> 
                        <div className="your-info">
                            <div><b>Age: </b><span>{Utils.getAge(session.dob)}</span></div>
                            <div><b>Weight: </b><span>{getWeight()}</span></div>
                            <div><b>Gender: </b><span>{getGenderLabel(session.gender)}</span></div>
                            <div><b>BJJ Rank: </b><span>{session.gi_level && GI_LEVEL_OPTIONS.find(g => g.value === session.gi_level).label}</span></div>
                        </div>
                    </div>
                    {inCartDivisions.length>0 && <div className="complete-registration">
                        <div className="in-cart">
                            {inCartDivisions.map((d, i) => <DivisionItem key={i} 
                                                            division={d} 
                                                            hideIncartButton 
                                                            />)}
                        </div>
                        <div className="cart-button">
                            <Link href={`/carts?ref=${tournament.id}`}>
                                <button className="button">
                                    Complete Registration - <u>{getTotalFee()}</u></button>
                            </Link>
                            <button className="button" onClick={e => {
                                e.stopPropagation();
                                deleteCart();
                            }}>Remove All</button>
                        </div>
                    </div>}
                </div>}
            <div className="all">
                <div className="title-wrapper">
                    <div className="title">Division Filters</div>
                    <Link className="option" 
                        onClick={e => {
                            e.stopPropagation();
                            setDivisionFilters(hasSelect? []:getFilterTypes(null, tournament, registered));
                        }}>{hasSelect? 'Clear':'Select All'}</Link>
                </div>
                <FilterChips className="FilterChips"
                    filters={filterChips}
                    activeFilters={divisionFilters}
                    onClick={handleFilterChange}
                />
            </div>
            
        </div>

        <div className={`divisions`}>
            <div className="count">{displayDivisions.length}/{availDivisions.length} Divisions Founds</div>
            <div className={`panes ${TournamentPageStyles.divisions}`}>
                {displayDivisions
                    .map((division, i) => {
                        return <DivisionItem key={i} division={division} />;
                    })}
            </div>
            
        </div>
    </div>

    function getSessionDivisions(division) {
        let filter = getFilterTypes(session, tournament, registered).find(f => f.type === 'session');
        let { age, weight, gender, rank } = filter;
        let a = (division.getAgeGroup()?.age_min || 0) <= age && age <= (division.getAgeGroup()?.age_max || 100);
        let dweightUnit = division.getWeightClass() && division.getWeightClass().weight_unit;
        if (dweightUnit && dweightUnit !== session.weight_unit){
            weight = convertWeight(session.weight_unit);
        }
        let w = (division.getWeightClass()?.weight_max || 2000) >= weight;
        let r = division.getRank();
        r = !rank || (r && (r.gi_ranks.includes(rank) || r.code === rank));
        return r && a && w && (division.gender === gender || age < 18);
    }

    function _getSubmittedSkus(singleOnly){
        if (!registered) {
            return [];
        }
        let skus = registered.filter(r => r.tournament === tournament.id && (singleOnly? !r.combo_ids:true))
                            .map(r => [r.division]).filter(r => r).flat();
        skus = Utils.getArrayRepeatedElements(skus, true);
        return Utils.uniqArray(skus);
    }

    function isInCart(division) {
        let submittedSkus = _getSubmittedSkus();
        return cart?.items?.find(item => {
            let skus = item.sku.split(',');
            return skus.includes(division.id) && !submittedSkus.includes(division.id);
        });
    }

    function getAllPossibleCombinations(arr) {
        const result = [];
    
        for (const value of arr) {
            const length = result.length;
            for (let i = 0; i < length; i++) {
                const newCombo = [...result[i], value];
                if (newCombo.length > 1) {
                    result.push(newCombo);
                }
            }
            // Start new combinations with the current value
            if (arr.length > 1) {
                result.push([value]);
            }
        }
    
        return result.filter(combo => combo.length > 1);
    }

    function DivisionItem({ division, hideIncartButton }) {
        const [message, setMessage] = useState();
        const [isAddingCart, setIsAddingCart] = useState(false);
        useEffect(() => {
            // console.log('DivisionItem');
        }, []);
        const addToCart = async (div) => {
            setMessage('');
            setIsAddingCart(true);
            let error;
            try{
                error = await addDivisionToCart({
                    division: div, session, tournament, 
                    business, isNotQualify, _getSubmittedSkus, 
                    getAllPossibleCombinations, submittedCarts});
            }finally {
                setIsAddingCart(false);
                if (error) {
                    setMessage(error);
                }else {
                    cbUpdate(session);
                }
            }
        }

        const deleteItem = async () => {
            let cart = session.getCarts().find(c => c.ref === tournament.id);
            if (!cart) {
                setMessage('error: Cart does not exist');
            }
            let submittedSkus = registered.map(r => r.division);
            cart.items = cart.items.map(i => {
                let cb = i.sku.split(',').filter(c => c!==division.id && !submittedSkus.includes(c));
                if (cb.length > 0){
                    i.sku = cb.join(',');
                    return i;
                }
            }).filter(i => i);
            if (cart.items.length === 0){
                if (!deleteCart()) {
                    setMessage('error: Error removing cart');
                }
                return;
            }
            let divSkus = cart.items.map(i => i.item_type === STORE_ITEM_TYPE.tournament_registration && 
                i.sku.split(',')).flat().filter(s => s).filter(s => !submittedSkus.includes(s));
            for (let div of divSkus){
                await addToCart(tournament.getDivisions().find(d => d.id === div));
            }
        }

        const getButton = (division) => {
            if (isInCart(division)) {
                return hideIncartButton &&
                    <button 
                        className="button"
                        onClick={e => {
                            e.stopPropagation();
                            deleteItem();
                        }}>
                            <Icon name="shopping-cart"/>Remove
                    </button>;
            }
            const canUpdate = () => {
                let r = { change: false, refund: false, cart: false };
                if (!session) {
                    return r;
                }
                const { member_change_date, refund_date, start_date, close_date } = tournament.dates;
                let ts = new Date();
                r.change = new Date(member_change_date) >= ts;
                r.refund = new Date(refund_date) >= ts;
                r.cart = new Date(close_date || start_date) >= ts;
                return r;
            }
            let isUpdate = canUpdate();
            if (isRegistered(division)) {
                let reg = registered.find(r => r.division === division.id);
                if (reg && reg.refund) {
                    const getServerity = () => {
                        let s = 'warning';
                        if (reg.refund.status === 'RA') {
                            s = 'success';
                        } else if (reg.refund.status === 'RD') {
                            s = 'error';
                        }
                        return s;
                    }
                    return <Alert severity={getServerity()}>Refund {getRefundStatusLabel(reg.refund.status)}</Alert>
                }
                return <div className="getButton">
                    {isUpdate.change && <button className="button"
                        onClick={e => {
                            e.stopPropagation();
                            setIsEdit(reg);
                        }}>Change</button>}
                    <div className="change-dates">
                        {isUpdate.change && <span className="timezone">Athlete deadline {getDate(tournament.dates.member_change_date_tz)}</span>}
                        {isUpdate.change && <span className="timezone">Coach deadline {getDate(tournament.dates.coach_change_date_tz)}</span>}
                    </div>
                    
                    {isUpdate.refund && <button className="button"
                        onClick={e => {
                            e.stopPropagation();
                            setIsRefund(reg);
                        }}>Request Refund</button>}
                    {isUpdate.refund && <span className="timezone">Refund deadline {getDate(tournament.dates.refund_date_tz)}</span>}
                </div>;
            }
            return isUpdate.cart
                && <button className="button"
                    disabled={isNotQualify() && true}
                    onClick={e => {
                        e.stopPropagation();
                        !isAddingCart && addToCart(division);
                    }}>
                    <Icon name="shopping-cart-add" />Add to cart {isAddingCart && <IonSpinner />}</button>;
        }
        const getRange = (s, e) => {
            const getLimit = (n) => n = !n || n===1 || n > 99? 'No Limit':n;
            let range = Utils.uniqArray([getLimit(s), getLimit(e)]);
            return range.join(' to ');
        }
        const getDate = dt => dt? Utils.formatDateTime(TournamentModel.convertTimeZoneToLocal(dt)) : '';
        const getNextPrices = () => {
            let fees = division.getFees();
            if (!fees || fees.length === 0) {
                return '';
            }
            let nextFees = fees.filter(f => f.start_date > new Date().getTime());
            return nextFees.map((n, i) => <div key={i} className="next-fee">
                <span className="next-fee-name">{n.period_name}</span>
                <span className="next-fee-date">after {getDate(n.start_date_tz)}</span>
                <span className="next-fee-amount">{tournament.currency} {Utils.toFloatString(n.amount)}</span>
            </div>);
        }
        let isCart =  isInCart(division);
        let isReg = isRegistered(division);
        const ComboInfo = () => {
            let item = isCart || isReg;
            if (!item){
                return '';
            }
            let cIds = ((isCart? item.sku:isReg.combo_ids)||'').split(',').filter(s => s && s !== division.id);
            return cIds.length===0? '' : 
                <div className="ComboInfo">
                    <span className="head">Combo with</span>
                    <ul className="combos">
                        {cIds.map((s, i) => 
                            <li key={i} className="division">
                                {tournament.getDivisions().find(d => s === d.id).name}
                            </li>)}
                    </ul>
                </div>;
        }
        let getRegisterStatus = () => {
            let r = isRegistered(division);
            if (r) {
                return STATUS_LABEL(r.status);
            }
            return '';
        }
        
        const getCartPrice = () => {
            if (!business) {
                return '';
            }
            if (isCart) {
                return `${business.currency} ${isCart.unit_price.toFixed(2)}`;
            }
            return division.getFee();
        }

        return <div key={division.id} className={`DivisionItem ${TournamentPageStyles.division} ${isInCart(division)? 'incart':''}`}>
            <Tooltip title={division.id}><p className={TournamentPageStyles.divisionName}
                style={{ color: division.isDivisionStandAlonePricing() ? '' : 'orange' }}
                id={division.id}>
                {division?.name}</p></Tooltip>
            <div className="divisionInfo-wrapper">
                <div className={`divisionInfo ${TournamentPageStyles.divisionInfo}`}>
                    <b>BJJ Type</b>
                    <p className="value">{getGrapplingTypeLabel(division.grappling_type)}</p>
                    <b>Gender</b>
                    <p className="value">{getGenderLabel(division.gender) || 'Both'}</p>
                    <b>Rank</b>
                    <p className="value">{division.getRank()?.name}</p>
                    <b>Ages</b>
                    <p className="value">{getRange(division?.getAgeGroup()?.age_min, division?.getAgeGroup()?.age_max)} [{division?.getAgeGroup()?.name}]</p>
                    <b>Weights</b>
                    <p className="value">{getRange(division?.getWeightClass()?.weight_min, division?.getWeightClass()?.weight_max)} {division?.getWeightClass()?.weight_unit||''}</p>
                    <b>Code</b>
                    <p className="value">{division?.code}</p>
                </div>
            </div>
            <ComboInfo />
            <div className="division-update">
                {!getRegisterStatus() && <p className={TournamentPageStyles.divisionPrice}>{getCartPrice()}</p>}
                {!isEdit && !isRefund ? getButton(division) : getEditPane(division)}
                {isInCart(division) && <span className="incart">in-cart</span>}
                {getRegisterStatus() && <span className={`status ${getRegisterStatus()}`}>{getRegisterStatus()}</span>}
            </div>
            {!getRegisterStatus() && <span className="next-fees">{getNextPrices()}</span>}
            <AlertPane message={message} timeOut={3000} setMessage={setMessage}/>
        </div>
    }
}


export const getReplaceableDivisions = (tournament, registration, getSessionDivisions, submittedSkus) => {
    let alreadyRegs = Utils.uniqArray([registration.division, ...(registration.combo_ids || '').split(','), ...submittedSkus]);
    let replaceableDivisions = [];
    if (registration.combo_ids) {
        let fee = tournament.getDivisionComboPricing(registration.combo_ids.split(','));
        if (fee) {
            let logic = fee.fee_info.logic.find(l => l.divisions.includes(registration.division));
            replaceableDivisions = logic? logic.divisions:[];
        }
    }else {
        replaceableDivisions = tournament.fee_info.map(fi =>
            fi.logic.length === 1 &&  
                fi.logic.map(l => l.divisions.includes(registration.division) && l.divisions)).flat().flat();
    }
    replaceableDivisions = Utils.uniqArray(replaceableDivisions)
        .map(rd => {
            let div = tournament.getDivisions().find(td => td.id === rd);
            let allow = div; // && getSessionDivisions(div);
            return allow && {
                label: getDivisionDisplay(div, true),
                value: div.id,
            }
        });
    return replaceableDivisions.filter(d => d && !alreadyRegs.includes(d.value)).sort((a, b) => Utils.sort(a, b));
}

function EditRegistration({ tournament, registration, setIsEdit, getSessionDivisions, submittedSkus }) {
    const [selected, setSelected] = useState();
    const [message, setMessage] = useState();
    const [replaceableDivisions, setReplaceableDivisions] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    useEffect(() => {
        let rs = getReplaceableDivisions(tournament, registration, getSessionDivisions, submittedSkus);
        setReplaceableDivisions(rs);
        rs.length === 0 && setTimeout(() => setIsEdit(false), 3000);
    }, []);
    const update = async (defaultValue) => {
        setMessage('');
        if (!selected || registration.division === selected) {
            if (!defaultValue){
                return;
            }
        }
        registration.division = selected || defaultValue;
        let res = await TournamentModel.saveRegistration(registration);
        if (res && !res.error) {
            setMessage('Successfully updated');
            setTimeout(() => setIsEdit(null), 1000);
        } else {
            setMessage('Error updating')
        }
    }
    return !replaceableDivisions? '' : 
        <div className="EditRegistration">
            {replaceableDivisions.length === 0? 
                <div className="no-changeable-found">
                    <span>No changeable division found.</span>
                </div>
                :
                <div className="form">
                    <SelectFormInput options={replaceableDivisions}
                        onChange={v => {
                            setSelected(v);
                        }} />
                    <button className="button" onClick={e => {
                        e.stopPropagation();
                        setIsUpdating(true);
                        setTimeout(async () => {
                            await update(replaceableDivisions[0].value);
                            setIsUpdating(false);
                        }, 1500);
                    }}>Update {isUpdating && <IonSpinner/>}</button>
                    <button className="button" onClick={e => {
                        e.stopPropagation();
                        setIsEdit(null);
                    }}>Cancel</button>
                    {message && <Alert severity={message.startsWith('Error') ? 'error' : 'info'}>{message}</Alert>}
                </div>}
        </div>;
}

function Refund({ registration, setIsRefund }) {
    const [refund, setRefund] = useState({});
    const [message, setMessage] = useState();
    const [isRefunding, setIsRefunding] = useState(false);
    const update = async () => {
        setMessage('');
        registration.refund = refund;
        let res = await TournamentModel.requestRefund(registration.id, refund);
        if (res && !res.error) {
            refund.status = 'RR';
            setMessage('Successfully updated');
            return setTimeout(() => setIsRefund(null), 1000);
        }
        setMessage('Error requesting refund');
    }
    return <ThemeProvider theme={Theme}>
        <Paper style={{ margin: 10, display: 'grid' }}>
            <SelectFormInput options={REFUND_REASONS} label="Reason"
                onChange={v => setRefund({ ...refund, reason: v })} />
            <TextareaAutosize
                aria-label="empty textarea"
                placeholder="Empty"
                style={{ width: '98%', height: 120, backgroundColor: 'inherit' }}
                onChange={e => setRefund({ ...refund, request_notes: e.target.value })}
            />
            <button className="button" onClick={e => {
                e.stopPropagation();
                setIsRefunding(true);
                setTimeout(async () => {
                    await update();
                    setIsRefunding(false);
                }, 1500);
            }}>Send Request {isRefunding && <IonSpinner />}</button>
            <button className="button" onClick={e => {
                e.stopPropagation();
                setIsRefund(null);
            }}>Cancel</button>
            {message && <Alert severity={message.startsWith('Error') ? 'error' : 'info'}>{message}</Alert>}
        </Paper>
    </ThemeProvider>;
}

