import SelectFormInput from "../FormInput/SelectFormInput";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { IonButton } from "@ionic/react";
import {
  getDateFormat
} from "../FormInput/DateFormInput1";
import { RequestLeague, RequestUtils } from "../../serverUtils/requests";
import {
  Checkbox,
  Input,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import TableFormInput, { ToolbarButtons } from "../FormInput/TableFormInput";
import AlertPane from "../FormInput/Message";
import TransferList from "../FormInput/TransferList";
import pageStyles from "../../pages/Page.module.scss";
import { STATUS_OPTIONS } from "../Bracket/Bracket";
import "./GroupScores.scss";
import { hasTableFormChange, reloadPage } from "../Form/Form";
import { validateUniqueCode } from "./Divisions";
import { GroupScoreSchema } from "../../serverUtils/Models";
import Utils from "../../serverUtils/Utils";
import DateFormInput1 from "../FormInput/DateFormInput1";

const CRC_Fields = Utils.listObjectKeys(GroupScoreSchema().model);
export default function GroupScores({ league }) {
  const [message, setMessage] = useState("");
  return (
    <div className="GroupScores">
      <AlertPane message={message} />
      <GroupScoreTable league={league} setMessage={setMessage} />
    </div>
  );
}

const headCells = [
  {
    id: "name",
    disablePadding: false,
    label: "Point System",
    align: "left",
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "code",
    disablePadding: false,
    label: "Code",
  },
  {
    id: "rank_period",
    disablePadding: false,
    label: "Rank Period",
  },
  {
    id: "divisions",
    disablePadding: false,
    label: "Divisions",
  },
  {
    id: "placement_point",
    disablePadding: false,
    label: "Placement Point",
  },
];

const GroupScoreTable = ({ league, setMessage }) => {
  const tableRef = useRef();
  const [refresh, setRefresh] = useState(false);
  const checkCRCMessageRef = useRef();
  const checkCRCMessageRef1 = useRef();
  const toolbarButtonsRef = useRef();
  const toolbarButtonsRef1 = useRef();
  const checkCRC = () => {
    checkCRCMessageRef.current.innerHTML = '';
    checkCRCMessageRef1.current.innerHTML = '';
    if (hasTableFormChange(league.getGroupScores(), CRC_Fields)) {
        checkCRCMessageRef.current.innerHTML = 'Data has changed';
        checkCRCMessageRef1.current.innerHTML = 'Data has changed';
        toolbarButtonsRef.current.setIndicator({save: 'yellow'});
        toolbarButtonsRef1.current.setIndicator({save: 'yellow'});
    }else {
        toolbarButtonsRef.current.setIndicator({save: ''});
        toolbarButtonsRef1.current.setIndicator({save: ''});
    }
  }
  useEffect(() => league.getGroupScores().forEach(r => !r.crc && (r.crc = Utils.getCRC(r, CRC_Fields))), []);

  const save = async () => {
    setMessage("");
    let selects = league.getGroupScores();
      
    if (selects.length === 0) {
      return setMessage("info: Nothing selected.");
    }
    if (selects.find((s) => !s.name || !s.code || !s.status)) {
      return setMessage("error: Point name, code and status must be filled.");
    }
    for (let r of selects) {
      let response;
      if (!r.id.startsWith("-1")) {
        response = await RequestLeague.updateLeagueGroupScoreRequest(r);
      } else {
        r.league = league.id;
        response = await RequestLeague.addLeagueGroupScoreRequest(r);
      }
      let data = RequestUtils.getResponseData(response);
      if (data.error) {
        return setMessage("error: Updating group score " + r.name);
      } else if (!r.id) {
        r.id = response.id;
      }
      r.crc = Utils.getCRC(r, CRC_Fields);
    }
    tableRef.current.setSelected([]);
    setMessage("success: Successfully updated server.");
    checkCRC();
  };

  const deleteSelected = async () => {
    setMessage("");
    let selects = league
      .getGroupScores()
      .filter((r) => tableRef.current.selected.includes(r.id));
    if (selects.length === 0) {
      return setMessage("info: Nothing selected.");
    }
    let hasServerDelete = false;
    for (let r of selects) {
      if (r.id.startsWith("-")) {
        continue;
      }
      let response = await RequestLeague.deleteLeagueGroupScoreRequest(r.id);
      hasServerDelete = true;
      let data = RequestUtils.getResponseData(response);
      if (data.error) {
        return setMessage("error: Deleting group score " + r.name);
      }
    }
    let groupScores = league
      .getGroupScores()
      .filter((r) => !tableRef.current.selected.includes(r.id));
    league.getGroupScores = () => groupScores;
    tableRef.current.setSelected([]);
    setMessage(`success: Successfully deleted ${hasServerDelete? 'from server':''}`);
    checkCRC();
  };

  const doAdd = () => {
    let groupScores = [
      ...league.getGroupScores(),
      { id: `-${new Date().getTime()}`, name: "", league: league.id, status: 'A' },
    ];
    league.getGroupScores = () => groupScores;
    reloadPage(setRefresh, () => tableRef.current.gotoLastPage());
    checkCRC();
  };

  const toolbarButtons = ref => (
    <ToolbarButtons ref={ref} doAdd={doAdd} doSave={save} doDelete={deleteSelected} />
  );
  return (
    <TableFormInput className="GroupScoreTable" name="GroupScore"
      onSelectedUpdate={<span ref={checkCRCMessageRef} className="warning" />}
      onSelectedUpdate1={<span ref={checkCRCMessageRef1} className="warning" />}
      isEditable
      ref={tableRef}
      headCells={headCells}
      data={(league.getGroupScores && league.getGroupScores()) || []}
      toolbarButtons={() => toolbarButtons(toolbarButtonsRef)}
      toolbarButtons1={() => toolbarButtons(toolbarButtonsRef1)}
      renderTRow={({ row, isSelected, index, handleClick }) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        return (
          <TableRow
            className={`TableRow ${row.id.startsWith('-')? 'is-add':''}`}
            hover
            onClick={(event) => {
              if (event.target.tagName.toUpperCase() === "INPUT") {
                return handleClick(event, row.id);
              }
            }}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={index}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox">
              <div className={pageStyles.alignCenter}>
                <Checkbox
                  color="primary"
                  checked={isItemSelected ? true : false}
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
                <span>{index + 1}</span>
              </div>
            </TableCell>
            <TableCell>
              <Tooltip title={row.name}>
                <Input defaultValue={row.name} 
                  onChange={e => {
                    row.name = e.target.value;
                    checkCRC();
                  }}
                  onClick={e => e.stopPropagation()}  />
              </Tooltip>
            </TableCell>
            <TableCell>
              <Select onClick={e => e.stopPropagation()} 
                value={row.status || ""}
                onChange={e => {
                  row.status = e.target.value;
                  setRefresh(!refresh);
                  checkCRC();
                }}
              >
                {STATUS_OPTIONS.map((s, i) => (
                  <MenuItem key={i} value={s.value}>
                    {s.label}
                  </MenuItem>
                ))}
                ;
              </Select>
            </TableCell>
            <TableCell>
              <Tooltip title={row.code}>
                <Input defaultValue={row.code} className="capitalize"
                  onChange={e => {
                    row.code = e.target.value.toUpperCase();
                    if (!validateUniqueCode(league.getGroupScores().map(r => r.code))) {
                      setMessage('error: Code is duplicated');
                    }
                    checkCRC();
                  }}
                  onClick={e => e.stopPropagation()} />
              </Tooltip>
            </TableCell>
            <TableCell>
              <RankPeriod onChange={checkCRC}
                rank_period={row.rank_period = row.rank_period || {}}
              />
            </TableCell>
            <TableCell>
              <Divisions onChange={checkCRC}
                league={league}
                selecteds={row.divisions = row.divisions || []}
                onUpdate={divs => {
                  row.divisions = divs;
                  setRefresh(!refresh);
                }}
              />
            </TableCell>
            <TableCell>
              <PlacementPoints onChange={checkCRC}
                placement_point={row.placement_point = row.placement_point || {}}
              />
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
};

const Divisions = ({ league, selecteds, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [groupScores, setGroupScores] = useState(selecteds);
  if (isEditing) {
    let selectIds = selecteds.map(d => d.id);
    return (
      <div>
        <TransferList
          availables={league
            .getDivisions().filter(d => !selectIds.includes(d.id))
            .map((d) => ({ value: d.id, label: d.name }))}
          selecteds={league
            .getDivisions()
            .filter((d) => selecteds.includes(d.id))
            .map((d) => ({ value: d.id, label: d.name }))}
          onDone={(divs) => {
            setIsEditing(false);
            setGroupScores(divs);
            onUpdate(divs);
          }}
        />
      </div>
    );
  }
  return (
    <div className="Divisions"
      onClick={() => setIsEditing(true)}
    >
      <div className="header">{`${groupScores.length} divisions`}</div>
      <ul>
        {groupScores.map((s, i) => (
          <li key={i}>{league.getDivisions().find((g) => g.id === s).name}</li>
        ))}
      </ul>
    </div>
  );
};

const PlacementPoints = ({ placement_point }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [placementPoint, setPlacementPoint] = useState(placement_point);
  const onChange = (v, field) => {
    placementPoint[field] = parseInt(v);
    setPlacementPoint({ ...placementPoint });
    Object.assign(placement_point, placementPoint);
  };
  if (isEditing) {
    return (
      <div style={{ display: "grid", margin: 10 }}>
        <TextField
          label="First"
          type="number"
          value={placementPoint?.first || ""}
          onChange={(e) => onChange(e.target.value, "first")}
        />
        <TextField
          label="Second"
          type="number"
          value={placementPoint?.second || ""}
          onChange={(e) => onChange(e.target.value, "second")}
        />
        <TextField
          label="Third"
          type="number"
          value={placementPoint?.third || ""}
          onChange={(e) => onChange(e.target.value, "third")}
        />
        <IonButton
          onClick={(e) => {
            setIsEditing(false);
          }}
        >
          Done
        </IonButton>
      </div>
    );
  }
  return (
    <div
      style={{ display: "grid", cursor: "pointer" }}
      onClick={() => setIsEditing(true)}
    >
      <span>
        <b>
          <u>First:</u>{" "}
        </b>
        {placementPoint?.first || ""}
      </span>
      <span>
        <b>
          <u>Second:</u>{" "}
        </b>
        {placementPoint?.second || ""}
      </span>
      <span>
        <b>
          <u>Third:</u>{" "}
        </b>
        {placementPoint?.third || ""}
      </span>
    </div>
  );
};

const RankPeriod = ({ rank_period}) => {
  const { control } = useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [rankPeriod, setRankPeriod] = useState(rank_period);
  const onChange = (v, field) => {
    rankPeriod[field] = v;
    setRankPeriod({ ...rankPeriod });
    Object.assign(rank_period, rankPeriod);
  };
  if (isEditing) {
    return (
      <div
        style={{ display: "grid", margin: 10 }}
        onClick={(e) => e.stopPropagation()}
      >
        <DateFormInput1
          value={rankPeriod?.earliest_date}
          name="earliest_date"
          label="Earliest Date"
          onChange={(v) => onChange(v, "earliest_date")}
          control={control}
        />
        <DateFormInput1
          value={rankPeriod?.latest_date}
          name="latest_date"
          label="Latest Date"
          onChange={(v) => onChange(v, "latest_date")}
          control={control}
        />
        <SelectFormInput
          label="Sliding Period"
          value={rankPeriod?.sliding_period || ""}
          onChange={(v) => onChange(v, "sliding_period")}
          options={SLIDING_PERIODS}
        />
        <TextField
          label="Amount"
          type="number"
          value={rankPeriod?.amount || ""}
          onChange={(e) => onChange(e.target.value, "amount")}
        />
        <IonButton
          onClick={(e) => {
            setIsEditing(false);
          }}
        >
          Done
        </IonButton>
      </div>
    );
  }
  const getSlidingPeriodLabel = () => {
    let sl = SLIDING_PERIODS.find((s) => s.value === rankPeriod.sliding_period);
    return (rankPeriod?.sliding_period && sl && sl.label) || "";
  };
  return (
    <div style={{ display: "grid" }} onClick={() => setIsEditing(true)}>
      <span>
        <b>
          <u>Earliest Date:</u>{" "}
        </b>
        {rankPeriod?.earliest_date
          ? getDateFormat(rankPeriod?.earliest_date)
          : ""}
      </span>
      <span>
        <b>
          <u>Latest Date:</u>{" "}
        </b>
        {rankPeriod?.latest_date ? getDateFormat(rankPeriod?.latest_date) : ""}
      </span>
      <span>
        <b>
          <u>Sliding Period:</u>{" "}
        </b>
        {getSlidingPeriodLabel()}
      </span>
      <span>
        <b>
          <u>Amount:</u>{" "}
        </b>
        {rankPeriod?.amount || ""}
      </span>
    </div>
  );
};

const SLIDING_PERIODS = [
  { value: "", label: "" },
  { value: "specific", label: "Specific Dates" },
  { value: "days", label: "Days" },
  { value: "months", label: "Months" },
  { value: "years", label: "Years" },
  { value: "tournament_seasons", label: "Tournament Seasons" },
];
