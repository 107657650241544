import { IonButton, IonIcon, IonList } from "@ionic/react";
import { useEffect, useState } from "react"
import LoadingList from "../../components/LoadingList/LoadingList";
import { ENTITY } from "../../serverUtils/Models";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import UserModel from "../../serverUtils/models/UserModel";
import {
    addCircle as addIcon,
} from "ionicons/icons";
import UserChip from "../../components/UserChip/UserChip";
import pageStyles from "./../Page.module.scss";
import MembershipInfo from "../../components/Form/MembershipInfo";
import TextFormInput from "../../components/FormInput/TextFormInput";
import AddEntityMember from "../../components/Form/AddEntityMember";
import { useStore } from "../../Store";
import "./LeagueMemberships.scss";

export default function LeagueMemberships({league}) {
    const session = useStore(state => state.session);
    const [newMember, setNewMember] = useState();
    const [isAdd, setIsAdd] = useState();
    const [memberships, setMemberships] = useState();
    const [filter, setFilter] = useState('');
    useEffect(() => {
        league && LeagueModel.getMemberships({id:league.id, search_string: filter})
            .then(memberships => {
                setMemberships(memberships);
            });
    }, [league, filter]);

    let isAdmin = league && UserModel.isAdmin(session, ENTITY.league, league.id);
    return !memberships? <LoadingList /> : (
        <div className="LeagueMemberships">
            <div className="filter">
                {isAdd && <AddEntityMember 
                            entity={league}
                            session={session} 
                            memberships={memberships}
                            newMember={newMember}
                            setNewMember={setNewMember}
                            setMemberships={setMemberships}
                            setIsAdd={setIsAdd}
                            model={LeagueModel}/>
                    }
                {isAdmin && 
                    (!isAdd && <button className="button" onClick={() => setIsAdd(true)}>
                        <IonIcon icon={addIcon}/>Find a member to add
                    </button>)
                }
                <TextFormInput label="Filter by name..." name="filter"
                    value={filter}
                    onChange={e => {
                        setFilter(e.target.value);
                    }}
                />
            </div>
        
            <IonList className={pageStyles.marginBottom}>
                <div className="memberships">
                    {memberships.filter(m => !filter || (filter && UserModel.getMembershipName(m).toLowerCase().includes(filter.toLowerCase())))
                        .map((m, i) => {
                            return <div className="membership" key={i}>
                                <UserChip membership={m}/>
                                {isAdmin && 
                                    m.leagues.filter(l => l.id===league.id).map((l, j) => 
                                        <MembershipInfo info={l} key={j}
                                            onUpdate={data => {
                                                return LeagueModel.updateMembership({id: league.id, member_id: m.id, data});
                                            }}
                                        />
                                    )}
                            </div> 
                    })} 
                </div>
            </IonList>
           
        </div>
    )
}