import { FixedSizeList as List } from 'react-window';
import TextFormInput from "../FormInput/TextFormInput";
import SelectFormInput from "../FormInput/SelectFormInput";
import { STATUS, filterMultSelectFormInput, getOptions, hasTableFormChange, reloadPage, stopFormSubmitByEnter } from "../Form/Form";
import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef, memo } from "react";
import { useForm } from "react-hook-form";
import { IonButton, IonIcon, IonSpinner } from "@ionic/react";
import FormStyles from "./../Form/Form.module.scss";
import "./../Form/Form.scss";
import "./Divisions.scss";
import { GENDER, GENDER_TYPES, getGenderLabel } from "./WeightClasses";
import CheckboxFormInput from "../FormInput/CheckboxFormInput";
import { getAgeGroupDisplay } from "./AgeGroups";
import { getWeightClassDisplay } from "./WeightClasses";
import {
  saveOutline as saveIcon,
  addCircleOutline as addIcon,
  flashOutline as generateIcon,
} from "ionicons/icons";
import {
  Alert,
  Checkbox,
  Chip,
  Paper,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import Utils from "../../serverUtils/Utils";
import TableFormInput, { ToolbarButtons } from "../FormInput/TableFormInput";
import { BRACKET_TYPES } from "../Bracket/Bracket";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import AlertPane from "../FormInput/Message";
import { multiWordFilter } from "../FormInput/TransferList";
import MultiSelectFormInput from "../FormInput/MultiSelectFormInput";
import { DivisionSchema } from "../../serverUtils/Models";

const getGenderTypes = () => [...GENDER_TYPES, {value: 'both', label: 'Both'}];

const createEntry = (id) => ({
  id: `-${id}`,
  name: "",
  getRank: () => null,
  getAgeGroup: () => null,
  getWeightClass: () => null,
  default_timer: {},
});

export default function Divisions({ league }) {
  const [selected, setSelected] = useState();
  const [message, setMessage] = useState();
  const [isGenerate, setIsGenerate] = useState(false);
  const divisionTableRef = useRef();

  const saveEntry = async (data) => {
    let id = data.id;
    data.league = league.id;
    data.rank = data.rank.value;
    if (id && !id.startsWith("-")) {
      let result = await LeagueModel.updateDivision(data);
      if (result && !result.errors) {
        return true;
      }
    } else {
      delete data.id
      let result = await LeagueModel.addDivision(data);
      if (result && !result.errors) {
        data.id = result.id;
        return true;
      }
    }
    return false;
  }

  return (
    <div className={`Divisions sub_form`}>
      {isGenerate && (
        <Generate
          league={league}
          onDone={newEntries => {
            if (newEntries && newEntries.length > 0) {
              divisionTableRef.current.tableRef.current.gotoLastPage();
            }
            setIsGenerate(false);
          }}
        />
      )}
      <AlertPane message={message} setTimeout={5000} setMessage={setMessage}/>
      <div className={FormStyles.fieldDescription}>
        <DivisionTable
          ref={divisionTableRef}
          setSelected={setSelected}
          setIsGenerate={setIsGenerate}
          isHide={selected || isGenerate}
          league={league}
          setMessage={setMessage}
          onClick={(id) => setSelected(league.getDivisions().find((d) => d.id === id))}
        />
      </div>
      {selected && (
        <div className={FormStyles.fields}>
          <Division
            value={selected}
            league={league}
            reload={d => {
              if (d.id.startsWith('-')) {
                let divisions = league.getDivisions().filter(ld => ld.id !== d.id);
                league.getDivisions = () => divisions;
              }
              setSelected(null);
            }}
            saveEntry={saveEntry}
          />
        </div>
      )}
    </div>
  );
}

export const typeOrder = ['grappling_type', 'gender', 'age_group', 'rank', 'weight_class'];
const DIVISION_GROUP_LABEL = {
  grappling_type: 'Grappling Type',
  gender: 'Gender',
  age_group: 'Age Group',
  rank: 'Rank',
  weight_class: 'Weight Class'
}

const getUnuses = (league) => {
  if (!league) {
    return [];
  }
  const filterWeightClass = (w, gender, rank, age_group, grappling_type) => {
    return w.genders.includes(gender) 
      && w.ranks.includes(rank.id)
      && w.age_groups.includes(age_group.id)
      && w.grappling_types.includes(grappling_type);
  }
  let gens = [];
  for (let gtype of GRAPPLING_TYPES.map(gt => gt.value)) {
    for (let gender of getGenderTypes().map(g => g.value)) {
      for (let ag of league.getAgeGroups(ag => ag.grappling_types.includes(gtype) && ag.genders.includes(gender))) {
        for (let rank of league.getRanks().filter(r => r.grappling_types.includes(gtype) && r.genders.includes(gender))) {
          for (let wc of league.getWeightClasses().filter(w => filterWeightClass(w, gender, rank, ag, gtype))) {
            if (
              !league.getDivisions().find((div) => {
                return (
                  div.grappling_type === gtype &&
                  div.gender === gender &&
                  div.rank === rank.id &&
                  div.age_group === ag.id &&
                  div.weight_class === wc.id
                );
              })
            ) {
              let name = [
                getGrapplingTypeLabel(gtype),
                getGenderLabel(gender),
                ag.name,
                rank.name,
                wc.name,
              ];
              let newEntry = {
                name: name.filter((n) => n).join(", "),
                league: league.id,
                grappling_type: gtype,
                gender: gender==='both'? 'MF':gender,
                age_group: ag.id,
                rank: rank.id,
                weight_class: wc.id,
                getRank: () => rank,
                getWeightClass: () => wc,
                getAgeGroup: () => ag,
              };
              newEntry.code = generateDivisionCode({
                ...newEntry,
                rank: rank.code,
                age_group: ag.code,
                weight_class: wc.code,
              });
              gens.push(newEntry);
            }
          }
        }
      }
    }
  }
  return gens;
};

const Generate = ({ league, onDone }) => {
  const [divisionFilters, setDivisionFilters] = useState([]);
  const [generates, setGenerates] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const checksRef = useRef([]);
  const selectCountRef = useRef();
  const hashtagsRef = useRef();
  const renderComboListRef = useRef();

  useEffect(() => {
    setGenerates(getUnuses(league));
  }, [league]);
  const groups = {
    grappling_type: GRAPPLING_TYPES,
    gender: GENDER_TYPES,
    rank: league.getRanks().map((r) => ({ value: r.id, label: r.name })),
    age_group: league
      .getAgeGroups()
      .map((r) => ({ value: r.id, label: r.name })),
    weight_class: league
      .getWeightClasses()
      .map((r) => ({ value: r.id, label: r.name, max: r.weight_max }))
      .sort((a, b) => Utils.sorter(a, b, "max")),
  };
  const create = async () => {
    setIsCreating(true);
      try{
      let selects = checksRef.current;
      if (selects.length > 0) {
        for (let d of selects) {
          d.hashtags =  hashtagsRef.current.querySelector('input').value;
          d.league = league.id;
          d.status = 'A';
          let result = await LeagueModel.addDivision(d);
          if (result && !result.errors) {
            d.id = result.id;
          }
        }
        let divisions = [...league.getDivisions(), ...selects];
        league.getDivisions = () => divisions;
      }
      onDone(selects);
    }finally{
      setIsCreating(false);
    }
  };

  const baseGroupFilter = ['grappling_type', 'gender'];
  const FilterButton = ({ filter, gtype }) => {
    let variant = divisionFilters.find(
      (f) => !filter || f.value === filter.value
    )
      ? "filled"
      : "outlined";
    let color = divisionFilters.includes((filter || {}).value)
      ? "primary"
      : undefined;
    return (
      <Chip
        style={{ marginBottom: 2 }}
        key={filter.value}
        className="filter"
        label={filter.label}
        variant={variant}
        color={color}
        onClick={() => {
          let isBaseGroup = ['gi','no_gi','M','F'].includes(filter.value);
          let filters = divisionFilters;
          if (isBaseGroup) {
            filters = filters.filter(d => baseGroupFilter.includes(d.type));
          }
          if (filters.find(df => df.value === filter.value)) {
            filters = filters.filter(df => df.value !== filter.value);
          } else {
            filter.type = gtype;
            filters = [...filters, filter];
          }
          setDivisionFilters(filters);
          checksRef.current = [];
          selectCountRef.current.setSelects(checksRef.current.length);
          let unUses = getUnuses(league);
          setGenerates(unUses);
        }}
      />
    );
  };

  const doFilter = () => {
    let buttonFilter =
      divisionFilters.length === 0
        ? []
        : generates.filter(g => {
            for (let t of typeOrder) {
              let values = divisionFilters
                .filter(df => df.type === t)
                .map(df => df.value);
              if (values.length > 0 && !values.includes(g[t])) {
                return false;
              }
            }
            return true;
          });

    return buttonFilter;
  };

  const checkItem = (isChecked, item) => {
    if (isChecked ) {
      checksRef.current.push(item);
    }else {
      checksRef.current = checksRef.current.filter(i => i !== item);
    }
    selectCountRef.current.setSelects(checksRef.current.length);
  }

  const SelectCount = forwardRef(({}, ref) => {
    const [selects, setSelects] = useState(0);
    useImperativeHandle(ref, () => ({
      setSelects,
    }));

    return <div className="filter-count">
      <label>Filter Entries:</label>{" "}
      <span className="count">{selects}/{divFilter.length} selected</span>
    </div>;
  });

  const RenderChipList = ({list, group}) => {
    let isBaseGroup = baseGroupFilter.includes(group);
    list.sort((a, b) => Utils.sort(a.label, b.label));
    return <div className={`RenderChipList`}>
      <div className="group-name">{DIVISION_GROUP_LABEL[group]}</div>
      {list.map((f, i) => {
        let gtypeFilters = divisionFilters.filter(df => df.type === 'grappling_type').map(df => df.value);
        let genderFilters = divisionFilters.filter(df => df.type === 'gender').map(df => df.value);
        let ageGroupFilters = divisionFilters.filter(df => df.type === 'age_group').map(df => df.value);
        let rankFilters = divisionFilters.filter(df => df.type === 'rank').map(df => df.value);
        const getList = () => {
          const doBaseFilter = i => {
              let isGender = genderFilters.length===0 || Utils.intersection(i.genders, genderFilters).length>0;
              let isType = gtypeFilters.length===0 || Utils.intersection(i.grappling_types, gtypeFilters).length>0;
              return isGender && isType;
          };
          const doAgeRankFilter = i => {
              let isAgeGroup = ageGroupFilters.length===0 || Utils.intersection(i.age_groups, ageGroupFilters).length>0;
              let isRank = rankFilters.length===0 || Utils.intersection(i.ranks, rankFilters).length>0;
              return isAgeGroup && isRank;
          }
          if (group === 'rank') {
            return league.getRanks().filter(doBaseFilter);
          }
          if (group === 'age_group') {
            return league.getAgeGroups().filter(doBaseFilter);
          }
          if (group === 'weight_class') {
            return league.getWeightClasses().filter(doBaseFilter).filter(doAgeRankFilter);
          }
          return [];
        }
        let l = getList();
        if (l.length === 0 && !isBaseGroup) {
          return;
        }
        
        return (isBaseGroup || l.find(i => i.id === f.value)) && <FilterButton key={i} filter={f} gtype={group} />;
      }).filter(g => g)}
    </div>;
  }

  const RenderComboList = forwardRef(({list}, ref) => {
    const [items, setItems] = useState([]);
    useEffect(() => {
      list.sort((a, b) => Utils.sort(a.name, b.name));
      setItems(list);
    }, [list]);
    useImperativeHandle(ref, () => ({
      setItems,
    }));
    const Render = () => { 
      let isSelectAll = items.length>0 && checksRef.current.length === items.length;
      const Row = memo(({ index, style }) => {
        const item = items[index];
        return !item? '' : 
          <li style={style} key={index}>
            <Checkbox checked={checksRef?.current.find(i => i===item)} 
              onClick={e => {
                checkItem(e.target.checked, item);
                setItems([...items]);
              }} /> 
            <Tooltip title={item.name||''}><span>{item.name||''}</span> </Tooltip> 
          </li>;
      });
      return <Paper className={`list-filter Render`}>
        <div className="select-all">
          <Checkbox defaultChecked={isSelectAll} 
            onChange={e => {
              let isChecked = e.target.checked;
              checksRef.current = isChecked? items:[];
              setItems([...items]);
            }}/> {`Select ${isSelectAll? 'None':'All'}`}</div>
        <List className="List"
          height={300} // Adjust height to the viewport size
          itemCount={list.length}
          itemSize={Utils.isMobile()? 60:24} // Adjust item height as needed
          width="100%">
          {Row}
        </List>
      </Paper>;
    }
    return <div className="RenderComboList">
      <Render />
    </div>;
  });

  let divFilter = doFilter();
  return (
    <Paper elevation={3} className="Generate">
      <Alert severity="info">{`Found ${generates.length} unused division combinations.`}</Alert>
      <div className="chips-wrapper">
        <div className="chips">
          {typeOrder.map((g, k) => {
            return (
              <Paper key={k}
                elevation={2}
                className={`group-${g}`}
                style={{ marginTop: 20 }}
              >
                <RenderChipList list={groups[g]} group={g}/>
              </Paper>
            );
          })}
        </div>
        <SelectCount count={checksRef.current.length} ref={selectCountRef}/>
      </div>
      <TextField className="filter-text" label="Filter" placeholder="type to filter..."
        onChange={e => {
          let f = e.target.value.toLowerCase();
          renderComboListRef.current.setItems(multiWordFilter(f, divFilter, 'name'));
      }}/>
      <RenderComboList list={divFilter} ref={renderComboListRef}/>

      <TextField ref={hashtagsRef} label="Categories" className="hashtags" autoComplete="off"/>
      <div className="buttons">
        <button className="button" onClick={() => create()}>
          <IonIcon icon={addIcon} />
          Create {isCreating && <IonSpinner />}
        </button>
        <button className="button" onClick={() => onDone()}>
          Cancel
        </button>
      </div>
    </Paper>
  );
};

export const GRAPPLING = {
  Gi: "gi",
  No_Gi: "no_gi",
};
export const GRAPPLING_TYPES = [...getOptions(GRAPPLING)];
export const getGrapplingTypeLabel = (t) => {
  let f = GRAPPLING_TYPES.find((gt) => gt.value === t);
  return f ? f.label : "";
};

export const getDivisionDisplay = (division, isText) => {
  if (!division || !division.id) {
    return "";
  }
  let gtype = GRAPPLING_TYPES.find(gt => gt.value===division.grappling_type);
  gtype = gtype? gtype.label : '';
  let gender = GENDER_TYPES.find(g => g.value===division.gender);
  gender = gender? gender.label:'MF';
  let ag = division.getAgeGroup && getAgeGroupDisplay(division?.getAgeGroup());
  let wc = division.getWeightClass && getWeightClassDisplay(division?.getWeightClass());
  let rank = division.getRank && division.getRank()? Utils.capitalize(division.getRank().name):'';
  if (isText) {
    return [division.name, `[${gtype}][${gender}]${ag}[${rank}]${wc}`, division.code].filter(d => d).join(' ');
  }
  let name = [
    <span key={0}>{division.name}</span>,
    <b key={1}>{`[${gtype}][${gender}]${ag}[${rank}]${wc}`}</b>,
    <span key={2}>{division.code}</span>,
  ].filter(d => d);

  return <div className="division-display">
    {name.map(n => n)}
  </div>;
};

export const generateCode = (name, length = 2) => {
  if (!name) {
    return "";
  }
  let names = name
    .split(" ")
    .filter((n) => n)
    .filter((n) => n.charCodeAt(0) > 47);
  const getChar = (s, i) => s.toUpperCase().charAt(i || 0);
  const setCode = (s) => (isNaN(s) ? getChar(s) : s);
  return names
    .map((n, i) => i < length && setCode(n))
    .filter((n) => n)
    .join("");
};

const generateDivisionCode = (division) => {
  let gender = getGenderTypes().find(g => g.value === division.gender)? division.gender:'';
  gender = gender==='both'? 'MF':gender;
  return [
    generateCode(division.name),
    division.grappling_type===GRAPPLING.Gi? GRAPPLING.Gi:'NG',
    gender,
    division.age_group,
    division.rank,
    division.weight_class,
  ]
    .filter((c) => c)
    .map((c) => c.toUpperCase())
    .join("\\");
};

export const validateUniqueCode = codes => {
  let unqCodes = Utils.uniqArray(codes);
  return unqCodes.length === codes.length;
}

const CRC_Fields = Utils.listObjectKeys(DivisionSchema().model);
const Division = ({ value = {}, league, reload, saveEntry }) => {
  const [formData, setFormData] = useState(value);
  const [refresh, setRefresh] = useState(false);
  const [message, setMessage] = useState("");
  const [codeError, setCodeError] = useState('');
  const [title, setTitle] = useState(value.name);
  const codeRef = useRef();
  const codeValueRef = useRef();
  const checkCRCTimeout = useRef();
  const [hasChange, setHasChange] = useState(false);
  const checkCRC = () => {
    checkCRCTimeout.current && clearTimeout(checkCRCTimeout.current);
    checkCRCTimeout.current = setTimeout(() => {
      let d = {...formData, ...getValues()};
      let c = hasTableFormChange(d, CRC_Fields);
      setHasChange(c);
    }, 1000);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm();

  useEffect(() => {
    let isCustomCode = getValues().is_custom_code;
    codeRef?.current.setReadOnly(!isCustomCode);
    if (!isCustomCode) {
      let code = getGenerateDivisioncode();
      codeRef?.current.setInputValue(code);
    }
  }, [
    getValues().is_custom_code, 
    getValues().gender, 
    getValues().grappling_type, 
    getValues().rank, 
    getValues().age_group, 
    getValues().weight_class,
    getValues().name,
  ]);

  useEffect(() => {
    if (!getGenderTypes().find(g => g.value === value.gender)) {
      value.gender = '';
    }
    setFormData(value);
    !value.is_custom_code && updateCode();
    value.crc = Utils.getCRC(value, CRC_Fields);
  }, [value]);

  useEffect(() => {
    let codes = league.getDivisions().map(d => d.code);
    if (!validateUniqueCode(codes)) {
      setCodeError('code is duplicated');
    }else {
      setCodeError('');
    }
  }, [codeValueRef.current]);

  const onSubmit = async (d) => {
    let params = { ...formData, ...d };
    let r = await saveEntry(params);
    if (r) {
      Object.assign(formData, params);
      formData.getAgeGroup = () => league.getAgeGroups().find(ag => ag.id === formData.age_group);
      formData.getRank = () => league.getRanks().find(r => r.id === formData.rank);
      formData.getWeightClass = () => league.getWeightClasses().find(wc => wc.id === formData.weight_class);
      setMessage("success: Successfully updated.");
      return setTimeout(() => reload(formData), 1000);
    };
    setMessage("error: Error updating server.");
  };

  const getGenerateDivisioncode = () => {
    let rank = league
        .getRanks()
        .find(r => {
          let _r = getValues("rank");
          return _r && _r.value === r.id;
        });
    let ageGroup = league
      .getAgeGroups()
      .find(r => [getValues("age_group")].includes(r.id));
    let weightClass = league
      .getWeightClasses()
      .find(r =>
        [getValues("weight_class")].includes(r.id)
      );
    return generateDivisionCode({
      // name: getValues("name") || formData.name,
      grappling_type: getValues("grappling_type") || formData.grappling_type,
      gender: getValues("gender") || formData.gender,
      age_group: ageGroup && ageGroup.code,
      rank: rank && rank.code,
      weight_class: weightClass && weightClass.code,
    });
  }

  const updateCode = () => {
    try{
      if (getValues("is_custom_code")) {
        return;
      }
      setValue("code", getGenerateDivisioncode(), { shouldDirty: true });
    }finally{
      setRefresh(!refresh);
    }
  };
  
  const applyAgeGroupRankFilter = wc => {
    let ageGroup = getValues().age_group;
    let rank = getValues().rank;
    return wc.age_groups.includes(ageGroup) && 
       (!rank.value || wc.ranks.includes(rank.value));
  }

  const applyBaseFilter = t => {
    let grapplingType = getValues().grapplingType || GRAPPLING.Gi;
    let gender = getValues().gender;
    gender = gender==='both'? [GENDER.female, GENDER.male]:[gender];
    return t?.grappling_types.includes(grapplingType)
      && Utils.intersection(t.genders, gender).length > 0;
  }

  const getOptions = (getList, isApplyAgeRank) => {
    let base = getList &&
      getList()
        .filter(applyBaseFilter);
    if (isApplyAgeRank){
      base = base.filter(applyAgeGroupRankFilter)
    }
    return base.map(r => ({ value: r.id, label: r.name }));
  }

  const getValue = field => {
    if (getValues(field) === undefined) {
      if (field === 'rank') {
        let rank = league.getRanks().find(r => r.id === formData.rank);
        if (rank) {
          return {value: formData.rank, label: rank.name};
        }
        return {};
      }
      return formData[field];
    }

    if (field === 'rank') {
      let rank = getValues(field);
      if (rank){
        let lrank = league.getRanks().find(r => r.id === rank.value);
        if (lrank) {
          return {value: rank.value, label: lrank.name};
        }
        return {};
      }
    }
    return getValues(field);
  }

  return (
    <div className="Division">
      {hasChange && <AlertPane isFloat message={'warning: Data has changed'} setMessage={() => setHasChange(false)}/>}
      <form className={FormStyles.form} 
        onKeyDown={stopFormSubmitByEnter}
        onSubmit={handleSubmit(onSubmit)}>
        <div className={FormStyles.fieldDescription}>
          <h3>{title}</h3>
        </div>
        <div className={FormStyles.fields}>
          <TextFormInput
            value={formData?.name}
            name="name"
            label="Division Name"
            isRequired
            register={register} setValue={setValue}
            errors={errors}
            inputProps={{className: "capitalize",}}
            onChange={v => {
              setTitle(Utils.capitalize(v));
              checkCRC();
            }}
          />
          <SelectFormInput
            value={formData?.status}
            name="status"
            label="Status"
            isRequired
            setValue={setValue}
            errors={errors}
            placeholder="Select status"
            options={STATUS}
            control={control}
            onChange={checkCRC}
          />
          <div className="code-wrapper">
            <CheckboxFormInput
              label="Is Custom Code?"
              name="is_custom_code"
              value={formData.is_custom_code}
              register={register} setValue={setValue}
              onChange={v => {
                !v && updateCode();
                setRefresh(!refresh);
                checkCRC();
              }}
            />
            <TextFormInput ref={codeRef}
              value={getValue('code')}
              className="capitalize"
              name="code"
              label="Code"
              isRequired
              register={register} setValue={setValue}
              errorText={codeError}
              inputProps={{ style: { textTransform: "uppercase" } }}
              isLabelShrunk
              isCapitalized
              onChange={v => {
                if (!v) {
                  return;
                }
                let codes = league.getDivisions().filter(d => d.id !== formData.id).map(d => d.code);
                if (!validateUniqueCode([...codes, v])) {
                  setCodeError('code is duplicated');
                }else {
                  setCodeError('');
                }
                checkCRC();
              }}
            />
          </div>
          <div className="requirements-wrapper">
            <SelectFormInput
              value={getValue('grappling_type')}
              name="grappling_type"
              label="Grappling Type"
              isRequired
              setValue={setValue}
              errors={errors}
              placeholder="Select grappling type"
              options={GRAPPLING_TYPES}
              control={control}
              onChange={() => {
                setValue('rank', []);
                setValue('age_group', '');
                setValue('weight_class', '');
                updateCode();
                checkCRC();
              }}
            />
            <SelectFormInput
              value={getValue('gender')}
              name="gender"
              label="Gender"
              isRequired
              setValue={setValue}
              errors={errors}
              placeholder="Select gender"
              options={getGenderTypes()}
              control={control}
              onChange={() => {
                setValue('rank', []);
                updateCode();
                checkCRC();
              }}
            />
            <SelectFormInput
              value={getValue('age_group')}
              name="age_group"
              label="Age Group"
              isRequired
              setValue={setValue}
              errors={errors}
              placeholder="Select age group"
              options={getOptions(league.getAgeGroups)}
              control={control}
              onChange={v => {
                formData.getAgeGroup = () => league.getAgeGroups().find((r) => r.id === v);
                updateCode();
                checkCRC();
              }}
            />
            <MultiSelectFormInput
              multiple={false}
              name="rank"
              label="Rank"
              optionLabel="label"
              optionValue="value"
              value={getValue('rank')}
              setValue={setValue}
              fetchOptions={searchVal => {
                let filters = filterMultSelectFormInput(searchVal, getOptions(league.getRanks));
                return Promise.resolve(filters);
              }}
              onChange={v => {
                formData.rank = v.value;
                formData.getRank = () =>
                  league.getRanks().find(r => r.id === v);
                updateCode();
                checkCRC();
              }}
            />
            <SelectFormInput
              value={getValue('weight_class')}
              name="weight_class"
              label="Weight Class"
              isRequired
              setValue={setValue}
              errors={errors}
              placeholder="Select weight class"
              options={getOptions(league.getWeightClasses, true)}
              control={control}
              onChange={(v) => {
                formData.getWeigthClass = () =>
                  league.getWeightClasses().find((r) => r.id === v);
                updateCode();
                checkCRC();
              }}
            />
          </div>
        </div>

        <div className={FormStyles.fieldDescription}>
          <h6>Default Timer (minutes)</h6>
        </div>
        <div className={`default-timer-wrapper ${FormStyles.fields}`}>
          <TextFormInput
            value={formData?.default_timer?.timer}
            name="default_timer.timer"
            label="Timer"
            register={register} setValue={setValue}
            errors={errors}
            onChange={checkCRC}
          />
          <TextFormInput
            value={formData?.default_timer?.over_timer}
            type="number"
            name="default_timer.over_timer"
            label="Over Timer"
            register={register} setValue={setValue}
            errors={errors}
            onChange={checkCRC}
          />
          <TextFormInput
            value={formData?.default_timer?.interstitial}
            type="number"
            name="default_timer.interstitial"
            label="Interstitial Timer"
            register={register} setValue={setValue}
            errors={errors}
            onChange={checkCRC}
          />
        </div>
        <div className={FormStyles.fieldDescription} />
        <div className={FormStyles.fields}>
          <TextFormInput
                value={getValues("hashtags") || formData?.hashtags}
                name="hashtags"
                label="Categories"
                register={register} setValue={setValue}
                helperText="(seperate by space)"
                onChange={checkCRC}
              />
        </div>
        <div className={FormStyles.fieldDescription} />
        <div className={FormStyles.fields}>
          <CheckboxFormInput
            label="Is Block?"
            name="is_block"
            value={formData.is_block}
            register={register} setValue={setValue}
            onChange={checkCRC}
          />
          <div className="buttons">
            <IonButton type="submit">
              <IonIcon icon={saveIcon}></IonIcon>Save
            </IonButton>
            <IonButton onClick={() => reload(formData)}>Done</IonButton>
            <AlertPane message={message} setMessage={setMessage} setTimeout={5000}/>
          </div>
        </div>
      </form>
    </div>
  );
};

const headCells = [
  {
    id: "name",
    disablePadding: false,
    label: "Division",
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "code",
    disablePadding: false,
    label: "Code",
  },
  {
    id: "grappling_type",
    disablePadding: false,
    label: "Grappling Type",
  },
  {
    id: "gender",
    disablePadding: false,
    label: "Gender",
  },
  {
    id: "age_group",
    disablePadding: false,
    label: "Age Group",
  },
  {
    id: "rank",
    disablePadding: false,
    label: "Rank",
  },
  {
    id: "weight_group",
    disablePadding: false,
    label: "Weight Class",
  },
  {
    id: "default_timer",
    disablePadding: false,
    label: "Default Timer",
  },
  {
    id: "is_block",
    disablePadding: false,
    label: "Is Block",
  },
  {
    id: "hashtags",
    disablePadding: false,
    label: "Categories",
  },
];

const DivisionTable = React.forwardRef(
  ({ league, onClick, setMessage, isHide, setIsGenerate, setSelected }, ref) => {
    const tableRef = useRef();
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState();
    useImperativeHandle(ref, () => ({
      tableRef,
    }));

    useEffect(() => {
      setLoading(!loading);
    }, [league.getDivisions]);
    const getFilterData = ({ headCell, filterString }, filterData, isFilter) => {
      let words = Utils.separateWords(filterString);
      if (headCell.id === "name") {
        return (filterData || league.getDivisions()).filter((d) => {
          return isFilter(words, () => getDivisionDisplay(d, true));
        });
      } else if (headCell.id === "bracket_type") {
        return (filterData || league.getDivisions()).filter((d) => {
          return isFilter(words, () => BRACKET_TYPES.find(b => b.value === d.bracket_type));
        });
      }
    };

    const doAdd = () => {
      let division = createEntry(league.getDivisions().length);
      let divisions = [...league.getDivisions(), division];
      league.getDivisions = () => divisions;
      tableRef.current.setSelected([division.id]);
      reloadPage(setLoading, () => tableRef.current.gotoLastPage());
      setSelected(division);
    };

    const doDelete = async () => {
      let selected = tableRef.current.selected;
      if (!Array.isArray(selected)) {
        selected = [selected];
      }
      if (selected.length === 0) {
        return setMessage('warning: Nothing is selected');
      }
      let fails = [];
      let deleteds = [];
      let isServerDelete = false;
      for (let s of selected) {
        if (s.startsWith('-')){
          deleteds.push(s);
          continue;
        }
        isServerDelete = true;
        let result = await LeagueModel.deleteDivision(s);
        if (result && !result.error) {
          deleteds.push(s);
        } else {
          fails.push(s);
        }
      }
      let divisions = league
        .getDivisions()
        .filter((d) => !deleteds.includes(d.id));
      league.getDivisions = () => divisions;
      setLoading(!loading);
      if (fails.length > 0) {
        setMessage(
          `error: Error deleting ${fails
            .map((f) => league.getDivisions().find((d) => d.id === f).name)
            .join(", ")}`
        );
      } else {
        setMessage("success: Successfully deleted selected divison(s)");
        isServerDelete && setTimeout(() => document.location.reload(), 2000);
      }
    };

    return (
      <div
        className="DivisionTable"
        style={{ display: isHide ? "none" : "block" }}
      >
        <TableFormInput name="DivisionTable"
          isEditable
          ref={tableRef}
          getFilterData={getFilterData}
          headCells={headCells}
          data={(league.getDivisions && league.getDivisions()) || []}
          toolbarButtons={() => {
            return <ToolbarButtons doAdd={doAdd} doDelete={doDelete} 
              buttons={getUnuses(league).length === 0? [] : 
                [<button key={0}
                  className="button"
                  onClick={() => setIsGenerate(true)}
                >
                  <IonIcon icon={generateIcon} />
                  Create Multiple Divisions
                </button>]}/>
          }}
          renderTRow={({ row, isSelected, index, handleClick, rowData }) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            const getGrapplingType = (t) => {
              let gt = GRAPPLING_TYPES.find((g) => g.value === t);
              return gt ? gt.label : "";
            };
            return (
              <TableRow
                className={`TableRow ${row.id.startsWith('-')? 'is-add':''}`}
                hover
                onClick={(event) => {
                  if (event.target.tagName.toUpperCase() === "INPUT") {
                    event.stopPropagation();
                    return handleClick(event, row.id);
                  }
                  onClick(row.id);
                }}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={index}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox" >
                  <div className="index">
                    <span>{index + 1}</span>
                    <Checkbox
                      color="primary"
                      checked={isItemSelected ? true : false}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </div>
                </TableCell>
                <TableCell
                  id={labelId}
                  scope="row"
                  padding="none"
                >
                  {getDivisionDisplay(row)}
                  {row.id.startsWith("-") && !row.name && 'click to edit division'}
                </TableCell>
                <TableCell align="center">{row.status}</TableCell>
                <TableCell align="center">{row.code}</TableCell>
                <TableCell align="center">
                  {getGrapplingType(row.grappling_type)}
                </TableCell>
                <TableCell align="center">{row.gender || ""}</TableCell>
                <TableCell align="center">
                  {row.getAgeGroup() && row.getAgeGroup()?.name}
                </TableCell>
                <TableCell align="center">
                  {row.getRank() && row.getRank()?.name}
                </TableCell>
                <TableCell align="center">
                  {row.getWeightClass() && row.getWeightClass()?.name}
                </TableCell>
                <TableCell align="center">
                  {isEdit && isEdit.id === row.id ? (
                    <div
                      className="default-timer"
                      onMouseLeave={(e) => setIsEdit(null)}
                    >
                      <TextField
                        type="number"
                        value={row.default_timer.timer || ""}
                        onChange={(e) =>
                          (row.default_timer.timer = e.target.value)
                        }
                      />
                      <TextField
                        type="number"
                        value={row.default_timer.over_timer || ""}
                        onChange={(e) =>
                          (row.default_timer.over_timer = e.target.value)
                        }
                      />
                      <TextField
                        type="number"
                        value={row.default_timer.interstitial || ""}
                        onChange={(e) =>
                          (row.default_timer.interstitial = e.target.value)
                        }
                      />
                      <IonButton
                        onClick={(e) => {
                          e.stopPropagation();
                          for (let r of rowData) {
                            let found = league
                              .getDivisions()
                              .find((d) => d.id === r.id);
                            if (found) {
                              found.default_timer = { ...row.default_timer };
                            }
                          }
                          setIsEdit(null);
                        }}
                      >
                        Apply to all
                      </IonButton>
                    </div>
                  ) : (
                    <div
                      className="default-timer"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsEdit(row);
                      }}
                    >
                      <div style={{ whiteSpace: "nowrap" }}>
                        Timer: {row.default_timer?.timer}
                      </div>
                      <div style={{ whiteSpace: "nowrap" }}>
                        Over timer: {row.default_timer?.over_timer}
                      </div>
                      <div style={{ whiteSpace: "nowrap" }}>
                        Interstitial Time: {row.default_timer?.interstitial}
                      </div>
                    </div>
                  )}
                </TableCell>
                <TableCell align="center">
                  {row.is_block ? <BlockIcon style={{ color: "red" }} /> : ""}
                </TableCell>
                <TableCell align="center">
                  {row.hashtags || ''}
                </TableCell>
              </TableRow>
            );
          }}
        />
      </div>
    );
  }
);
