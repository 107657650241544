import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableFormInput from '../FormInput/TableFormInput';
import Checkbox from '@mui/material/Checkbox';
import Utils from '../../serverUtils/Utils';
import { IonButtons, IonButton, IonSpinner } from '@ionic/react';
import { default as DeleteIcon } from "@mui/icons-material/Delete";
import { default as SaveIcon } from "@mui/icons-material/Save";
import { default as EditIcon } from "@mui/icons-material/Edit";
import { default as AddIcon } from "@mui/icons-material/AddCircleOutline";
import { default as UndoIcon } from "@mui/icons-material/Undo";
import { default as RedoIcon } from "@mui/icons-material/Redo";
import { default as ResetIcon } from "@mui/icons-material/RestartAlt";
import { default as MoveIcon } from "@mui/icons-material/ChevronRight";
import { default as ExpandIcon } from "@mui/icons-material/UnfoldMore";
import { default as CollapseIcon } from "@mui/icons-material/UnfoldLess";

import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import { BRACKET, BRACKET_TYPES } from '../Bracket/Bracket';
import SelectFormInput from '../FormInput/SelectFormInput';
import { Tooltip, TextField, Radio, Menu, MenuItem, ListItemText, Divider } from '@mui/material';
import './Poolings.scss';
import TournamentModel from '../../serverUtils/models/TournamentModel';
import UserModel from '../../serverUtils/models/UserModel';
import AlertPane from '../FormInput/Message';
import { useStore } from "../../Store";
import { calculateTotalRounds } from "../Bracket/bracketUtils";
import { RequestUtils } from "../../serverUtils/requests";
import { RangeSlider } from "./TournamentDivisions";
import { hasTableFormChange } from "../Form/Form";
import { convertWeight } from "../../pages/TournamentPage/DivisionRegistration";

const UNDO_STACK = [];
let REDO_STACK = [];
const Poolings = React.forwardRef(({ tournament, onChange }, ref) => {
    const localServer = useStore(state => state.local_server);
    const setIsBracketEntriesUpdate = useStore(state => state.setIsBracketEntriesUpdate);
    const [message, setMessage] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [selected, setSelected] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [checkCRC, setCheckCRC] = useState(false);
    const [hasChange, setHasChange] = useState(false);
    const CRC_Fields = ['poolings', 'pool'];
    const pool_crc = 'pool_crc';
    const poolings_crc = 'poolings_crc';
    const [expands, setExpands] = useState([POOL_AVAILABLES]);
    useEffect(() => {
        let params = RequestUtils.getURLParameters();
        if (params.pool) {
            setSelected(tournament.poolings.find(p => p.pool == params.pool));
        }
        tournament.poolings = tournament.poolings || [];
        tournament.poolings_crc = Utils.getCRC(tournament, CRC_Fields, poolings_crc);
        tournament.getRegistrations().forEach(r => r.pool_crc = Utils.getCRC(r, CRC_Fields, pool_crc));
    }, []);
    useEffect(() => setHasChange(hasTableFormChange(tournament, CRC_Fields, poolings_crc) 
        || tournament.getRegistrations().find(r => hasTableFormChange(r, CRC_Fields, pool_crc))
    ), [checkCRC]);

    const doSave = async () => {
        setIsSaving(true);
        try{
            let result = await TournamentModel.updateTournamentPool(tournament.id, tournament.poolings);
            if (!result || result.error) {
                return setMessage('Error updating poolings.');
            }
            for (let reg of tournament.getRegistrations()) {
                let r = await TournamentModel.saveRegistration(reg);
                if (!r || r.error) {
                    return setMessage(`error: Error updating pooling registrations: ${UserModel.getMembershipName(reg.getMembership())}`);
                }
                r.pool_crc = Utils.getCRC(r, CRC_Fields, pool_crc);
            }
            tournament.poolings_crc = Utils.getCRC(tournament, CRC_Fields, poolings_crc);
            setMessage('success: Successfully updated server.');
        }finally {
            setIsSaving(false);
            setCheckCRC(!checkCRC);
        }
    }

    const doAdd = () => {
        if (!tournament) {
            return;
        }
        tournament.poolings = tournament.poolings || []
        if (tournament.poolings.find(p => !p.pool)) {
            return;
        }
        let pool = `New Pool ${tournament.poolings.length + 1}`;
        let bracket_type = BRACKET.Single_Elimination;

        let p = { 
            id: pool,
            pool, 
            bracket_type,
            getRegistrations: () => getRegs(pool, tournament),
            getBracketType: () => bracket_type,
            getFinalRound: () => calculateTotalRounds(getRegs(pool, tournament).length, bracket_type) - 1,
            getTournament: () => tournament,
         };
        tournament.poolings.push(p);
        let availsDivisions = [...tournament.divisions, ...tournament.poolings];
        tournament.getAvailableDivisions = () => {
            return availsDivisions;
        }
        setSelected(p);
        setCheckCRC(!checkCRC);
        onChange && onChange();
    }

    const doDeleteAll = () => {
        tournament && tournament.getRegistrations && tournament.getRegistrations()?.forEach(r => r.pool = null);
        tournament.poolings = [];
        setRefresh(!refresh);
        onChange && onChange();
        setCheckCRC(!checkCRC);
        triggerUpdate();
    }

    const updateState = current => {
        tournament.getRegistrations().forEach(r => {
            let found = current.find(c => c.id === r.id);
            if (found) {
                r.pool = found.pool;
            }
        });
        triggerUpdate();
        setRefresh(!refresh);
        setCheckCRC(!checkCRC);
        onChange && onChange();
    }

    const doUndo = () => {
        let last = UNDO_STACK.pop();
        if (last) {
            REDO_STACK.push(getCurrent(tournament));
        }
        updateState(last);
        console.log('undo: ', UNDO_STACK.length)
        console.log('redo: ', REDO_STACK.length)
    }

    const doRedo = () => {
        let prev = REDO_STACK.pop();
        if (prev) {
            UNDO_STACK.push(getCurrent(tournament));
        }
        updateState(prev);
        console.log('undo: ', UNDO_STACK.length)
        console.log('redo: ', REDO_STACK.length)
    }

    const triggerUpdate = () => {
        poolRefs.forEach(ref => {
            ref?.current.updateRegistrations();
        });
        setRefresh(!refresh);
        setIsBracketEntriesUpdate();
        setCheckCRC(!checkCRC);
    }

    const selectPool = (p) => {
        return p &&
               p.pool!==POOL_AVAILABLES &&
               setSelected(selected===null || selected.pool!==p.pool? p:null);
    }

    const poolRefs = [];
    const availRef = React.useRef();
    poolRefs.push(availRef);

    return (
        <div className="Poolings" >
            <IonButtons className="buttons" slot="end">
                {!localServer && 
                    <button className="button" onClick={doSave}>
                        <SaveIcon />Save {isSaving && <IonSpinner />}
                    </button>}
                <button className="button" onClick={doAdd}>
                    <AddIcon />New Pool
                </button>
                <button className="button" onClick={doDeleteAll}>
                    <DeleteIcon />Delete All Pools
                </button>
                {UNDO_STACK.length > 0 &&
                    <button className="button" onClick={doUndo} fill="solid" color="primary">
                        <UndoIcon />Undo
                    </button>}
                {REDO_STACK.length > 0 &&
                    <button className="button" onClick={doRedo} fill="solid" color="primary">
                        <RedoIcon />Redo
                    </button>}
            </IonButtons>
            {hasChange && <span className="warning">Data has changed</span>}
            <AlertPane message={message} timeOut={2000} setMessage={setMessage}/>
            <div className="pools-wrapper" >
                <div className={`pool-wrapper`}>
                    <div className="availables">
                        <Pool tournament={tournament}
                            ref={availRef}
                            data={{pool: POOL_AVAILABLES}}
                            triggerUpdate={triggerUpdate}
                            selected={selected}
                            setExpands={isExpand => {
                                if (isExpand){
                                    setExpands([...expands, POOL_AVAILABLES])
                                }else {
                                    setExpands(expands.filter(e => e !== POOL_AVAILABLES));
                                }
                            }}
                        />
                    </div>
                </div>
                    {tournament && tournament.poolings && tournament?.poolings.map((p, i) => {
                        let ref = React.createRef();
                        poolRefs.push(ref);
                        return <div key={i} className={`pool-wrapper`}>
                            <div className={`pool-${p.pool}`} 
                                     onClick={e => {
                                        e.stopPropagation();
                                        selectPool(p);
                                     }}>
                                <Pool tournament={tournament} data={p}
                                    triggerUpdate={triggerUpdate}
                                    ref={ref}
                                    selected={selected}
                                    setSelected={selectPool}
                                    setExpands={isExpand => {
                                        if (isExpand){
                                            setExpands([...expands, p.pool])
                                        }else {
                                            setExpands(expands.filter(e => e !== p.pool));
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    })}
            </div>
        </div>
    );
});

const headCells = [
    {
        id: 'first_name',
        numeric: false,
        disablePadding: true,
        label: 'First',
    },
    {
        id: 'last_name',
        numeric: false,
        disablePadding: true,
        label: 'Last',
    },
    {
        id: 'gym',
        numeric: false,
        disablePadding: false,
        label: 'Gym',
    },
    {
        id: 'gender',
        numeric: true,
        disablePadding: false,
        label: 'Gender',
    },
    {
        id: 'gi_level',
        numeric: false,
        disablePadding: false,
        label: 'Level',
    },
    {
        id: 'age',
        numeric: false,
        disablePadding: false,
        label: 'Age',
    },
    {
        id: 'weight',
        numeric: false,
        disablePadding: false,
        label: 'Weight',
    },
    {
        id: 'division',
        numeric: false,
        align: 'left',
        disablePadding: false,
        label: 'Division',
    },
];

const POOL_AVAILABLES = 'Availables';
const filterReg = (r, pool) => {
    if (pool === POOL_AVAILABLES) {
        return true;
    }
    if (r.pool) {
        return r.pool.split('|').includes(pool);
    }
    return false;
}
export const getRegs = (pool, tournament) => {
    if (!pool || !tournament || !tournament.getRegistrations) {
        return [];
    }
    if (pool === POOL_AVAILABLES) {
        return tournament.getRegistrations().filter((obj, index, arr) => {
            return index === arr.findIndex((o) => o.membership === obj.membership);
        });
    }
    return tournament.getRegistrations().filter(r => r && filterReg(r, pool));
}

const getCurrent = (tournament) => tournament.getRegistrations().map(r => ({ id: r.id, pool: r.pool }));

const Pool = forwardRef(({ tournament, data, triggerUpdate, selected, setExpands }, ref) => {
    const ageSize = [0, 99];
    const weightSize = [0, 300];
    const [ageRange, setAgeRange] = useState(ageSize);
    const [weightRange, setWeightRange] = useState(weightSize);
    const [message, setMessage] = useState('');
    const [registrations, setRegistrations] = useState([]);
    const [isExpand, setIsExpand] = useState(data.pool===POOL_AVAILABLES);
    const [isMove, setIsMove] = useState(false);
    const poolName = useRef('');
    const tableRef = useRef();
    const selectPoolsRef = useRef([]);

    const updateRegistrations = () => {
        let regs = getRegs(data.pool, tournament);
        let div = tournament.getAvailableDivisions().find(d => d.id === data.pool);
        if (div) {
            div.getRegistrations = () => regs;
        }
        setRegistrations(regs);
    }
    useEffect(() => {
        let regs = getRegs(data && data.pool, tournament);
        setRegistrations(regs);
    }, [data && data.pool]);

    useImperativeHandle(ref, () => ({
        updateRegistrations,
    }));

    const pushUndo = () => {
        UNDO_STACK.push(getCurrent(tournament));
        REDO_STACK = [];
    }

    const updatePool = (r, pool) => {
        let pools = (r.pool || '').split('|').filter(r => r);
        if (pools.includes(pool)) {
            return;
        }
        pushUndo();
        pools = new Set(pools);
        pools.add(pool);
        r.pool = [...pools].join('|');
    }

    const drop = (ev) => {
        ev.preventDefault();
        let from = JSON.parse(ev.dataTransfer.getData("text"));
        let r = tournament.getRegistrations().find(r => r.id===from.id);
        if (data.pool === POOL_AVAILABLES){
            tournament.getRegistrations().forEach(_r => _r.membership === r.membership && (_r.pool = null));
        }else if (data.pool) {
            updatePool(r, data.pool);
        }
        if (data.pool) {
            updateRegistrations();
            triggerUpdate(ref);
        }
    }

    const drag = (ev) => {
        const {id, pool} = ev.target;
        ev.dataTransfer.setData("text", JSON.stringify({id, pool}));
    }

    const EditName = () => {
        if (!poolName.current) {
            return <div className="Pool-Header">
                <span onClick={() => {
                    poolName.current = data.pool;
                }}>
                    {data.pool !== POOL_AVAILABLES && <Tooltip title="Edit pool name">
                        <EditIcon />
                    </Tooltip>}
                    {data.pool} {data.bracket_type && ` - ${BRACKET_TYPES.find(b => b.value===data.bracket_type).label}`}
                </span>
            </div>;
        }
        data.getBracketType = () => data.bracket_type || BRACKET.Single_Elimination;
        data.getRegistrations = () => getRegs(poolName.current, tournament);

        const setName = () => {
            setMessage('');
            if (!poolName.current) {
                return;
            }
            if (data.pool !== poolName.current){
                if (tournament.poolings.find(p => p.pool===poolName.current)) {
                    return setMessage('error: Pool name not available.');
                }
                tournament && tournament.getRegistrations && tournament.getRegistrations().forEach(r => {
                    if (r.pool) {
                        let pools = r.pool.split('|');
                        if (pools.includes(data.pool)) {
                            pools = pools.filter(p => p !== data.pool);
                            pools.push(poolName.current);
                            r.pool = pools.join('|');
                        }
                    }
                });
                data.pool = data.id = poolName.current;
            }
            poolName.current = null;
        }

        return <div className="EditName">
            <TextField label={"Enter Pool Name"}
                defaultValue={poolName.current}
                name="pool"
                onChange={e => {
                    poolName.current = e.target.value;
                }}
                onClick={e => e.stopPropagation()}
            />
            <SelectFormInput
                value={data.bracket_type = data.bracket_type ? data.bracket_type : BRACKET.Single_Elimination}
                name="bracket_type"
                label="Bracket Type"
                placeholder="Select bracket type"
                options={BRACKET_TYPES}
                onChange={v => data.bracket_type = v}
                onClick={e => e.stopPropagation()}
            />
            <IonButton onClick={setName} fill="solid">Done</IonButton>

        </div>;
    }

    const doDelete = () => {
        setMessage('');
        pushUndo();
        tournament && tournament.getRegistrations && tournament.getRegistrations().forEach(r => {
            if (r.pool) {
                r.pool = r.pool.split('|').filter(p => p!==data.pool).join('|');
            }
        });
        let i = tournament.poolings.indexOf(data);
        tournament.poolings.splice(i, 1);
        triggerUpdate(ref);
        setMessage('');
    }

    const doMove = (pool) => {
        setMessage('');
        const regs = tableRef.current.selected;
        if (!regs || regs.length === 0) {
            return setMessage('info: No reg is selected.');
        }
        
        pushUndo();
        if (pool === POOL_AVAILABLES){
            removeSelected();
        }else {
            let uniques = Utils.uniqArrayByKey(tournament.getRegistrations().filter(r => regs.includes(r.id)), 'id');
            uniques.forEach(r => {
                updatePool(r, pool);
            });
        }
        triggerUpdate(ref);
        setMessage('');
    }

    const doResetRegistrants = () => {
        setMessage('');
        pushUndo();
        tournament && tournament.getRegistrations && tournament.getRegistrations().forEach(r => {
            if (r.pool) {
                if (data.pool === POOL_AVAILABLES) {
                    r.pool = '';
                } else {
                    r.pool = r.pool.split('|').filter(p => p !== data.pool).join('|');
                }
            }
        });
        triggerUpdate(ref);
    }

    const removeSelected = () => {
        let reg = tournament.getRegistrations().find(r => r.pool && r.pool.split('|').includes(data.pool));
        if (reg) {
            if (reg.pool) {
                reg.pool = reg.pool.split('|').filter(p => p !== data.pool).join('|');
            }
        }
    }

    const ToolbarButtons = ({}) => {
        const [refresh, setRefresh] = useState(false);
        const [moveMenu, setMoveMenu] = useState(null);
        useEffect(() => {
            setRefresh(!refresh);
        },  [tournament.poolings && tournament.poolings.length]);

        const handleMoveMenuOpen = (e) => {
            e.stopPropagation();
            setMoveMenu(e.currentTarget);
        };
        const handleMoveMenuClose = (e) => {
            e && e.stopPropagation();
            setMoveMenu(null);
        };

        const SelectPools = ({}) => {
            let list = tournament.poolings.map((p, i) => {
                 return <MenuItem key={i}>
                     <ListItemText >
                         <span 
                             onClick={e => {
                                 handleMoveMenuClose(e);
                                 doMove(p.pool);
                             }}>
                             <div className="flex-column gap">
                                 {p.pool}
                             </div>
                         </span>
                     </ListItemText>
                 </MenuItem>;
            });
     
            if (data.pool !== POOL_AVAILABLES) {
                list.unshift(<Divider key={-2}/>)
                list.unshift(<MenuItem key={-1}>
                    <ListItemText >
                        <span 
                            onClick={e => {
                                handleMoveMenuClose(e);
                                doMove(POOL_AVAILABLES);
                            }}>
                            <div className="flex-column gap">
                                Remove Selected
                            </div>
                        </span>
                    </ListItemText>
                </MenuItem>);
            }
            return list;
        }

        const isMoveMenuOpen = Boolean(moveMenu);
        return <div className="ToolBar">
            <IonButtons>
                {data.pool !== POOL_AVAILABLES && 
                    <Tooltip title="Delete this pool.">
                        <button className="button icon_button"  onClick={doDelete} >
                            <DeleteIcon/>
                        </button>
                    </Tooltip>}
                <Tooltip title="Reset all registrants">
                    <button className="button icon_button" onClick={doResetRegistrants}>
                        <ResetIcon />
                    </button>
                </Tooltip>
                {tournament.poolings && tournament.poolings.length > 0 &&
                <div className="move-menu">
                    <Tooltip title={isMove? "Move the selected registrants to the selected pool" : 'Select pools to move registrants'}>
                        <button className="button icon_button"  
                            onClick={handleMoveMenuOpen} >
                            <MoveIcon />
                        </button>
                    </Tooltip>
                    <Menu
                        anchorEl={moveMenu}
                        open={isMoveMenuOpen}
                        onClose={handleMoveMenuClose}
                        MenuListProps={{'aria-labelledby': 'basic-button'}}
                    >
                        <SelectPools />
                    </Menu>
                </div>}
                
            </IonButtons>
            {/* {pool==POOL_AVAILABLES && <RangeFilter />} */}
            <AlertPane message={message} isFloat setMessage={setMessage} timeOut={2000} />
        </div>;
    }
    const tableComparator = (a, b, orderby) => {
        if (['age', 'weight'].includes(orderby)) {
            return Utils.sorter(a, b, orderby);
        }
    }

    const getFilterData = ({ headCell, filterString }, filterData, isFilter, isRange) => {
        filterString = filterString.toLowerCase();
        let words = Utils.separateWords(filterString);
        if (headCell.id === "gi_level") {
            return (filterData || registrations).filter(r => {
                return isFilter(words, () =>  {
                    let m = r.getMembership();
                    return m && m.gi_level;
                });
            });
        }
        if (headCell.id === "age") {
            return (filterData || registrations).filter(r => {
                return isRange(filterString, () => {
                    let m = r.getMembership();
                    return m && Utils.getAge(m.dob);
                });
            });
        }
        if (headCell.id === "weight") {
            return (filterData || registrations).filter(r => {
                return isRange(filterString, () => {
                    let m = r.getMembership();
                    return m && m.weight;
                });
            });
        }
    }

    const getHeaderCell = () => {
        if (data.pool === POOL_AVAILABLES) {
            return [headCells[0], headCells[1], {
                id: 'pool',
                align: 'center',
                numeric: false,
                disablePadding: true,
                label: 'Pool',
            }, ...headCells.slice(2)];
        }
        return [...headCells];
    }

    const RangeFilter = () => {
        return <div className="RangeFilter">
          <RangeSlider label={<span>Age Range</span>} size={ageSize} range={ageRange} onChange={(v) => setAgeRange(v)}/>
          <RangeSlider label={<span>Weight Range</span>} size={weightSize} range={weightRange} onChange={v => setWeightRange(v)}/>
        </div>
    }

    const filterAgeWeight = () => {
        let regs = registrations.filter(r => {
            if (r.getMembership && r.getMembership()) {
                let age = Utils.getAge(r.getMembership().dob);
                let weight = r.getMembership().weight;
                let result = ageRange[0]<=age && age <= ageRange[1] && weightRange[0] <= weight && weight <= weightRange[1];
                return result;
            }
            return false;
        });
        return regs;
    }

    return (
        <div className={`Pool ${!isExpand? 'collapse':''}`}
            pool={data? data.pool:POOL_AVAILABLES}
            onDrop={drop}
            onDragOver={(ev) => {
                return ev.preventDefault();
            }}>
            <div className="header">
                <h6>{data? data.pool : POOL_AVAILABLES}</h6>
                {!isExpand && <div className="expand" onClick={() => {
                    setExpands(true);
                    setIsExpand(true);
                }}><ExpandIcon /> <span>Expand</span></div>}
                {isExpand && <div className="expand" onClick={() => {
                    setExpands(false);
                    setIsExpand(false);
                }}><CollapseIcon /> <span>Collapse</span></div>}
            </div>
            {isExpand && 
                <TableFormInput name="Poolings"
                    title={data ? <EditName /> : POOL_AVAILABLES} isDense ref={tableRef}
                    getFilterData={getFilterData}
                    isEditable={true}
                    toolbarButtons={() => <ToolbarButtons pool={data.pool} />}
                    headCells={getHeaderCell()}
                    comparator={tableComparator}
                    data={filterAgeWeight()}
                    renderTRow={({row, isSelected, index, handleClick}) => {
                        const membership = row.getMembership() || {};
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let division = tournament.getDivisions().find(d => d.id === row.division);
                        const getDivWeight = () => {
                            if (!division) {
                                return;
                            }
                            if (membership.weight_unit === division.getWeightClass().weight_unit) {
                                return `${membership.weight} ${membership.weight_unit||''}`;
                            }
                            return convertWeight(membership.weight, membership.weight_unit==='lbs'? 'kgs':'lbs');
                        }
                        const PoolAbbr = ({poolNames}) => {
                            if (!poolNames){
                                return '';
                            }
                            let abbrs = [];
                            let pools = poolNames.split('|');
                            for (let p of pools) {
                                let words = p.split(' ');
                                if (words.length === 1){
                                    let word = words.pop();
                                    abbrs.push(`${word.charAt(0)}${word.charAt(word.length-1)}`);
                                }else {
                                    abbrs.push(words.map(n => n.charAt(0).toUpperCase()).join(''));
                                }
                            }
                            return <Tooltip title={pools.join(', ')}>
                                <b>{abbrs.join(' ')}</b>
                            </Tooltip>;
                        }
                        return <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                        >
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </TableCell>
                            <TableCell
                                draggable={true}
                                onDragStart={drag}
                                component="th"
                                id={row.id}
                                pool={data? data.pool:POOL_AVAILABLES}
                                scope="row"
                                padding="none">
                                {membership?.first_name}
                            </TableCell>
                            <TableCell
                                draggable={true}
                                onDragStart={drag}
                                component="th"
                                id={row.id}
                                pool={data? data.pool:POOL_AVAILABLES}
                                scope="row"
                                padding="none">
                                {membership?.last_name}
                            </TableCell>
                            {data.pool === POOL_AVAILABLES && 
                                <TableCell align="center"><PoolAbbr poolNames={row.pool}/></TableCell>}
                            <TableCell align="left">{membership.getGym && membership.getGym()?.name}</TableCell>
                            <TableCell align="center">{membership.gender}</TableCell>
                            <TableCell align="center">{membership.gi_level}</TableCell>
                            <TableCell align="center">{Utils.getAge(membership.dob)}</TableCell>
                            <TableCell align="center">{getDivWeight()}</TableCell>
                            <TableCell align="left">{division && division.name}</TableCell>
                        </TableRow>
                    }}
                />}
        </div>
    )
});

export default Poolings;
