import {
  IonContent, IonItem,
  IonItemGroup,
  IonList,
  IonPage,
  IonThumbnail, useIonViewWillEnter, IonSpinner
} from "@ionic/react";
import { useEffect, useState } from "react";
import PageStyles from "./../Page.module.scss";
import LoadingList from "../../components/LoadingList/LoadingList";
import classNames from "classnames";
import styles from "./TournamentsPage.module.scss";
import {
  downloadOutline as downLoadIcon,
  playSkipForwardCircleOutline as runTournamentIcon
} from "ionicons/icons";
import { useHistory } from "react-router-dom";
import TournamentModel from "../../serverUtils/models/TournamentModel";
import { Link, ThemeProvider } from "@mui/material";
import { useStore } from "../../Store";
import LocalServerModel from "../../serverUtils/models/LocalServerModel";
import TournamentsPageStyles from "./TournamentsPage.module.scss";
import Utils from "../../serverUtils/Utils";
import Theme from "../../components/FormInput/Theme";
import { default as RunIcon } from "@mui/icons-material/DirectionsRun";
import { default as DownloadIcon } from "@mui/icons-material/Download";
import { getTournamentDates } from "../TournamentPage/TournamentPage";
import AlertPane from "../../components/FormInput/Message";
import { FilterInput } from "../../components/Form/Form";
import "./TournamentsPage.scss";


const TournamentsPage = (props) => {
  const history = useHistory();
  const localServer = useStore((state) => state.local_server);
  const isGraphQLOnline = useStore(state => state.isGraphQLOnline);
  const session = useStore((state) => state.session);
  const [tournaments, setTournaments] = useState(null);
  const [message, setMessage] = useState();
  const [localTournamentList, setLocalTournamentList] = useState([]);
  const [busy, setBusy] = useState();
  const searchStrings = useStore(state => state.searchStrings);
  const setSearchStrings = useStore(state => state.setSearchStrings);
  const setLocalTournament = useStore(state => state.setLocalTournament);
  const getData = async (search_string = searchStrings.tournaments) => {
    setTournaments(null);
    let tournaments = await TournamentModel.getTournaments({ search_string });
    let localTournaments = [];
    if (localServer) {
      localTournaments = await LocalServerModel.getTournamentList();
      setLocalTournamentList(localTournaments || []);
    }
    if (tournaments) {
      tournaments = tournaments.filter(t => {
        return !localServer ||
          (session && (session.is_super || t.admins.find(a => a.id === session.id)));
      });
      if (!isGraphQLOnline){
        tournaments = tournaments.filter(t => !localServer || 
          (localServer && isGraphQLOnline) ||
          isTournamentLocal(t, localTournaments));
      }
      setTournaments(tournaments);
    }
    let t = Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament);
    if (t && localTournaments.find(l => l.includes(t))){
      history.push(`/local/tournaments/${t}`);
    }
  };

  useEffect(() => {
    getData();
  }, [session]);
  useIonViewWillEnter(() => {
    if (localServer && !session) {
      return;
    }
    getData(searchStrings.tournaments);
  }, [tournaments, localServer, session, searchStrings.tournaments, isGraphQLOnline]);

  const getImage = (tournament) => {
    const image = tournament.getImage();
    if (image) {
      return <img alt="tournament" src={image.data.join("")} />;
    }
    return (
      <img
        alt="user"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjYzVkYmZmIiBkPSJNMCAwaDUxMnY1MTJIMHoiLz48cGF0aCBkPSJNMjU2IDMwNGM2MS42IDAgMTEyLTUwLjQgMTEyLTExMlMzMTcuNiA4MCAyNTYgODBzLTExMiA1MC40LTExMiAxMTIgNTAuNCAxMTIgMTEyIDExMnptMCA0MGMtNzQuMiAwLTIyNCAzNy44LTIyNCAxMTJ2NTZoNDQ4di01NmMwLTc0LjItMTQ5LjgtMTEyLTIyNC0xMTJ6IiBmaWxsPSIjODJhZWZmIi8+PC9zdmc+"
      />
    );
  };

  const doFilter = (s) => {
    setSearchStrings('tournaments', s);
    getData(s);
  };

  const downLoad = async (tournament) => {
    setBusy(tournament);
    setMessage("");
    setTimeout(() => {
      if (LocalServerModel.downloadTournament(tournament.id)) {
        setMessage(
          `success: Successfully download tournament ${tournament.name} to local server.`
        );
        setBusy(null);
        getData();
      }
    }, 1000);
  };

  const runTournament = async (tournament) => {
    Utils.cookie("local_tournament", tournament.id);
    let t = await LocalServerModel.getTournament(tournament.id)
    setLocalTournament(t);
    history.push(`/local/tournaments/${tournament.id}`);
  };

  const getLocation = (tournament) => {
    if (tournament.locations && tournament.locations.length > 0) {
      let loc = tournament.locations[0];
      return [
        loc.name || "",
        loc.city || "",
        loc.state || "",
        loc.country || "",
      ]
        .filter((l) => l)
        .join(", ");
    }
  };

  const getDates = (t) => {
    if (!t.dates || !t.dates.start_date) {
      return '';
    }
    let startd = TournamentModel.convertTimeZoneToLocal(t.dates.start_date);
    let endd = TournamentModel.convertTimeZoneToLocal(t.dates.end_date);
    return getTournamentDates(startd, endd);
  }

  const getLastDownLoad = (t) => {
    let found = localTournamentList.find(l => l.includes(t.id));
    if (found) {
      let ts = found.split('.').pop();
      return `Last Download ${Utils.formatDateTime(ts.split('|')[1])}`;
    }
    return '';
  }

  const isTournamentLocal = (tournament, locals = localTournamentList) => {
    return tournament && locals.find(l => l.includes(tournament.id));
  };
  const LocalServerButtons = ({ tournament }) => {
    return !localServer ? '' :
      <div key={1} className={TournamentsPageStyles.localServerButtons}>
        {isGraphQLOnline && <button
          className="button icon_button"
          key={0}
          onClick={() => downLoad(tournament)}
          icon={downLoadIcon}
        >
          <DownloadIcon />
        </button>}
        {isTournamentLocal(tournament) &&
          <button
            className="button icon_button"
            onClick={() => runTournament(tournament)}
            icon={runTournamentIcon}
          >
            <RunIcon />
          </button>}
        <div className={TournamentsPageStyles.downloadDate}>{getLastDownLoad(tournament)}</div>
      </div>;
  }

  const TournamentList = ({title, isPast}) => {
    const getList = () => {
      let ts = new Date().getTime();
      return tournaments.filter(t => {
        let st = t && t?.dates?.start_date;
        if (!st) {
          return false;
        }
        st = TournamentModel.convertTimeZoneToLocal(st);
        if (isPast) {
          return st < ts;
        }
        return st >= ts;
      });
    };

    let tours = getList();
    return tours.length == 0? '' : 
      <IonList >
        <h4>{title}</h4>
        {tours.map((tournament, i) => (
          <IonItem lines="none" key={tournament.id}>
            <IonItemGroup>
              <IonThumbnail className={PageStyles.listImage}>
                {getImage(tournament)}
              </IonThumbnail>
            </IonItemGroup>
            <IonItemGroup className={PageStyles.listContent}>
              <IonItem
                routerLink={!localServer && `/tournaments/${tournament.id}`}
              >
                <h5>
                  {busy && busy.id === tournament.id ?
                      <IonSpinner name="circular"></IonSpinner>
                    :
                    <div className="flex-row">
                      <LocalServerButtons tournament={tournament} />
                      <div className="tournament">{tournament.name}</div>
                      {tournament.getLeague()?.name && 
                        <div className={TournamentsPageStyles.league}>
                          <Link
                            href={
                              !localServer && tournament.getLeague()
                                ? `/leagues/${tournament.getLeague().id}`
                                : "#"
                            }
                          >
                            {tournament.getLeague()?.name || ""}
                          </Link>
                          <span className="entity-type">@league</span>
                      </div>}
                    </div>}
                </h5>
              </IonItem>
              <p className={styles.date}>
                {getDates(tournament)}<span className={styles.timezone}>@{tournament.time_zone}</span>
              </p>
              <div className={TournamentsPageStyles.location}>
                {getLocation(tournament)}
              </div>
            </IonItemGroup>
          </IonItem>
        ))}
      </IonList>;
  }

  return (
    <IonPage className={`${PageStyles.page} TournamentsPage`}>
      <ThemeProvider theme={Theme}>
        <IonContent className={TournamentsPageStyles.TournamentsPage}>
          <div className={PageStyles.pageHeader}>
            <div className={PageStyles.pageHeaderImage} />
            <div className={PageStyles.pageHeaderTitleWrapper}>
              <h1 id="tournaments_page" className={classNames("display-font", PageStyles.pageHeaderTitle)}>
                Tournaments
              </h1>
              <p className={PageStyles.pageHeaderSubtitle}>
                Find a tournament to register and compete
              </p>
              <FilterInput label="Search Tournament" entity="tournaments" onFilter={v => doFilter(v)} />
            </div>
          </div>
          <AlertPane message={message} timeOut={3000} setMessage={setMessage}/>
          <div className={PageStyles.marginBottom}>
            {tournaments ? (
              <div className="tournaments">
                <TournamentList title="Current" />
                <TournamentList title="Past" isPast />
              </div>
            ) : (
              <LoadingList />
            )}
          </div>
        </IonContent>
      </ThemeProvider>
    </IonPage>
  );
};

export default TournamentsPage;
