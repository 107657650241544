import {
  RequestUser,
  RequestSession,
  RequestUtils,
  RequestMessage,
  RequestImage,
} from "../requests";
import Utils from "../Utils";
import { convertDate } from "../Models";
import {
  PROFILE_ICON
} from "../../components/MembershipForm/Profile";
import { STATUS } from "../../components/Form/Form";
import { IMAGE_TYPE } from "../../components/Form/Form";
import { WINS_BY } from "../../components/Bracket/bracketUtils";
import moment from "moment";
import MessageModel from "./MessageModel";

export const getMembershipName = (m) =>
  m? m.first_name && `${m.first_name} ${m.last_name}` : '';
export default class UserModel {
  static async getMemberhipByIds(ids) {
    let response = await RequestUser.getMembershipByIdsRequest(ids);
    let rs = RequestUtils.getResponseData(response);
    if (rs && !rs.errors) {
      const { memberships, images_doc } = rs;
      memberships.forEach(m => m.getImage = () => images_doc.find(i => i.ref === m.id));
    }
    return rs.memberships;
  }
  static async sendTemporaryPassword(id) {
    let response = await RequestUser.sendTemporaryPassword(id);
    return RequestUtils.getResponseData(response);
  }

  static async resendEmailValidation() {
    let response = await RequestUser.resendValidationEmail();
    return RequestUtils.getResponseData(response);
  }

  static async confirmValidationEmail(id) {
    let response = await RequestUser.confirmValidationEmail(id);
    return RequestUtils.getResponseData(response);
  }

  static async deleteMembership(id) {
    let response = await RequestUser.deleteMembership(id);
    return RequestUtils.getResponseData(response);
  }

  static async registerMembership(params) {
    let response = await RequestUser.registrationRequest(params);
    return RequestUtils.getResponseData(response);
  }

  static updateAdminInfo(session, setSession, entity, data, isRemove) {
    if (session.is_super) {
      return;
    }
    let adminInfo = session?.getAdminInfo();
    adminInfo[entity] = [...adminInfo[entity].filter((a) => a.id !== data.id)];
    if (!isRemove) {
      adminInfo[entity].push(data);
    }
    session.getAdminInfo = () => adminInfo;
    setSession({ ...session });
  }

  static async gihSubscription(payment_detail) {
    let response = await RequestSession.gihSubscription(payment_detail);
    return RequestUtils.getResponseData(response);
  }

  static async getGIHSubscription() {
    let response = await RequestSession.getGIHSubscription();
    return RequestUtils.getResponseData(response);
  }

  static addSessionAsAdmin(session) {
    return (
      session && {
        admins: [
          { id: session.id, display: UserModel.getMembershipName(session) },
        ],
      }
    );
  }

  static async membershipRegistration(
    model,
    entity,
    sessionEntity,
    setMessage,
    addToCart
  ) {
    setMessage("");
    let result;
    if (!entity.registration_fee) {
      result = await model.membershipRegistration(entity.id);
      if (result && !result.error) {
        sessionEntity.push({
          id: entity.id,
          status: "P",
        });
        setMessage(`info: You have request for membership registration.  
                    Please contact ${entity.name} to check on your registration status.`);
        return setTimeout(() => setMessage(""), 5000);
      }
      setMessage("error: Error during membership registration.");
    } else {
      await addToCart(entity);
    }
  }

  static getMembershipStatus = (
    session,
    entity,
    entityId,
    item_type,
  ) => {
    if (!session) {
      return;
    }
    
    let ls = session[entity]
      .filter((l) => l.id === entityId)
      .sort(Utils.sorter);
    if (ls.length === 0) {
      if (
        session.getCarts &&
        session
          .getCarts()
          ?.find(
            c => {
              return c.ref === entityId && 
                c.items.find(i => {
                  return i.item_type === item_type && i.sku === entityId;
                });
            }
          )
      ) {
        return 'warning: Please pay your membership registration from your cart.';
      }
      return ;
    }
    let l = ls[ls.length - 1];
    if (l.status === "A") {
      return 'success: Registered';
    }
    if (l.status === "P") {
      return 'info: Pending Membership';
    }
  };

  static getMembershipName(m) {
    return getMembershipName(m);
  }

  static getMembershipGym(m) {
    return m && m.getGym() ? ` [${m.getGym().name}]` : "";
  }

  static getMembershipNameInitial(m) {
    return (
      m && `${m?.first_name.toUpperCase()[0]}.${m?.last_name.toUpperCase()[0]}.`
    );
  }

  static getAdminEnityIds = (member) => {
    return [...member.getAdminInfo().gyms.map(g => g.id),
    ...member.getAdminInfo().teams.map(t => t.id),
    ...member.getAdminInfo().leagues.map(l => l.id)].filter(f => f);
  }

  static getAdminEnities = (member) => {
    return [...member.getAdminInfo().gyms.map(g => g.id && ({ id: g.id, name: g.name, type: 'gym' })),
    ...member.getAdminInfo().teams.map(t => t.id && ({ id: t.id, name: t.name, type: 'team' })),
    ...member.getAdminInfo().leagues.map(l => l.id && ({ id: l.id, name: l.name, type: 'league' }))].filter(f => f);
  }

  static isAdmin(session, entity, id) {
    if (!session) {
      return false;
    }
    if (session.is_super) {
      return true;
    }
    if (entity) {
      return session.getAdminInfo && session.getAdminInfo()[`${entity}s`]?.find((a) => a.id === id);
    }
    if (session.id === id){
      return true;
    }
    if (id && typeof id === "object") {
      const isBelongAdmin = (t) => {
        let adminList = id[`${t}s`] || [];
        let belongs = adminList.map(g => g.id);
        let entities = UserModel.getAdminEnities(session);
        if (!entities) {
          return false;
        }
        let admins =entities.filter(e => e.type === t).map(e => e.id);
        return Utils.intersection(belongs, admins).length > 0;
      }
      return isBelongAdmin('gym') || isBelongAdmin('team') || isBelongAdmin('league');
    }
  }

  static async searchUser(search_string) {
    let response = await RequestUser.searchUser(search_string);
    let users = RequestUtils.getResponseData(response);
    return users? users.map((u) => ({
      id: u.id,
      display: `${u.first_name} ${u.last_name} - ${[u.city, u.state, u.country]
        .filter((e) => e)
        .join(", ")}`,
    })):[];
  }

  static async getSession(setSession, force, isLocalServer) {
    let gih_session = Utils.cookie("gih_session");
    if (force || gih_session) {
      let response = await RequestSession.getSessionRequest();
      let session = RequestUtils.getResponseData(response, "session");
      if (!session) {
        Utils.deleteCookie('gih_session');
        setSession(null);
        return;
      }
      let adminInfo = RequestUtils.getResponseData(response, "getAdminInfo");
      session = this.formatResult(session);
      if (session.length > 0) {
        session = session[0];
        session.getRoles = () => session.roles.filter(r => r);
        session.getAdminInfo = () => adminInfo;
        session.gih.sort(Utils.sorterDate);
        // let messages = isLocalServer? [] : await UserModel.getMessages({});
        session.getMessages = () => [];
        setSession && setSession(session);
        return session;
      }
    }
  }

  static async getPhotos(params) {
    let response = await RequestImage.getImagesByRefRequest({
      ...params,
      ids: [params.id],
      image_type: IMAGE_TYPE.photo,
    });
    return RequestUtils.getResponseData(response);
  }

  static async checkUserName(user_name) {
    let response = await RequestUser.checkUserNameRequest(user_name);
    return RequestUtils.getResponseData(response);
  }

  static async resetPassword(email) {
    let response = await RequestUser.resetPassword(email);
    return RequestUtils.getResponseData(response);
  }

  static async addUser(params) {
    let response = await RequestUser.addMembershipRequest(params);
    return RequestUtils.getResponseData(response);
  }

  static async updateUser(params) {
    let response = await RequestUser.updateUser(params);
    return RequestUtils.getResponseData(response);
  }

  static async updateMembershipRegistration(params) {
    let response = await RequestUser.updateMembershipRegistration(params);
    return RequestUtils.getResponseData(response);
  }

  static async updateMembershipStatus(id, status) {
    let response = await RequestUser.updateMembershipStatus({ id, status });
    return RequestUtils.getResponseData(response);
  }

  static async updateEntityStatus(id, entity_type, entity_id, status) {
    let response = await RequestUser.updateEntityStatus({
      id,
      entity_type,
      entity_id,
      status,
    });
    return RequestUtils.getResponseData(response);
  }

  static async switchUser(id) {
    let response = await RequestUser.switchUser(id);
    return RequestUtils.getResponseData(response);
  }

  static async deleteUser(id) {
    let response = await RequestUser.deleteUser(id);
    return RequestUtils.getResponseData(response);
  }

  static async sendMessage(params) {
    delete params.id;
    let response = await RequestMessage.sendMessageRequest(params);
    return RequestUtils.getResponseData(response);
  }

  static async deleteMessage(id) {
    let response = await RequestMessage.deleteMessage(id);
    return RequestUtils.getResponseData(response);
  }

  static async markMessageRead(ids) {
    let response = await RequestMessage.markReadMessage(ids);
    return RequestUtils.getResponseData(response);
  }

  static setBracketEntry(b, tournaments_docs, divisions_doc, memberships_doc, brackets) {
    b.getTournament = () => tournaments_docs.find(t => t.id === b.tournament);
    b.getDivision = () => {
      const { poolings } = b.getTournament();
      let found = divisions_doc.find(d => d.id === b.division);
      if (!found) {
        found = poolings.find(p => p.pool === b.division);
        if (found) {
          found.name = found.pool;
          found.id = found.pool;
        }
      }
      return found;
    };
    b.getMembership = () => memberships_doc.find(m => m.id === b.membership);
    b.getOpponent = () => {
      let oppIndex = b.index % 2 === 0 ? b.index + 1 : b.index - 1;
      let opp = brackets.find(match => match.tournament === b.tournament
        && match.division === b.division
        && match.membership !== b.membership
        && match.round === b.round
        && match.index === oppIndex);
      let result, outcome, place;
      const getStatus = () => {
        let _opp = opp || {};
        if (result) {
          return 'Completed';
        }
        if (b.score || b.score2 || b.scorep || _opp.score || _opp.score2 || _opp.score) {
          return 'In Progress';
        }
        return 'Up Coming';
      };

      let _result;
      if (opp) {
        opp.getMembership = () => memberships_doc.find(mem => mem.id === opp.membership);
        outcome = brackets.find(match => {
          return match.tournament === b.tournament
            && match.division === b.division
            && match.round === b.round + 1
            && [b.membership, opp.membership].includes(match.membership)
        });
        
        if (outcome) {
          place = outcome.place;
          result = outcome.result;
          if (outcome.membership === b.membership) {
            outcome = 'Won';
            _result = 'W';
          } else {
            outcome ='Lost';
            _result = 'L';
          }
        }else if (opp.result) {
          outcome = 'Lost';
          _result = 'L';
        }
      }
      return { round: b.round, place, opp, outcome, result, _result, status: getStatus() };
    }
    return b;
  }

  static async getMatches(id) {
    let response = await RequestUser.getMatches(id);
    let result = RequestUtils.getResponseData(response);
    const { brackets, divisions_doc, tournaments_doc, memberships_doc } = result;
    return brackets.map(b => {
      return this.setBracketEntry(b, tournaments_doc, divisions_doc, memberships_doc, brackets);
    });
  }

  static async getSupportMessage() {
    let response = await RequestSession.getSupportMessages();
    return RequestUtils.getResponseData(response);
  }

  static async getMessages({ setMessages, ts }) {
    let response = await RequestSession.getSessionMessagesFromRequest(ts);
    return RequestUtils.getResponseData(response);
  }

  static async getAdminInfo() {
    let response = await RequestSession.getAdminInfo();
    return RequestUtils.getResponseData(response);
  }

  static async login(userName, pwd) {
    let response = await RequestSession.loginRequest(userName, pwd);
    let result = RequestUtils.getResponseData(response);
    if (result && !result.error) {
      let membership = result.memberships[0];
      Utils.cookie("gih_session", membership.session.key, 365);
      return membership;
    }
  }

  static async logout() {
    Utils.deleteCookie("gih_session");
    Utils.deleteCookie("last_url");
    Utils.deleteCookie("orgin_request");
    await RequestSession.logoutRequest();
  }

  static getAge(m) {
    if (!m || isNaN(m.dob)) {
      return;
    }
    return Utils.getAge(m.dob);
  }

  static calculateDob(age) {
    let pastDate = moment();
    return pastDate.add(-parseInt(age), 'years').toDate();
  }

  static renderImage = (user) => {
    const image = user.getImage();
    if (image) {
      return <img alt="user" src={image.data.join("")} />;
    }
    return (
      <img
        alt="user"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjYzVkYmZmIiBkPSJNMCAwaDUxMnY1MTJIMHoiLz48cGF0aCBkPSJNMjU2IDMwNGM2MS42IDAgMTEyLTUwLjQgMTEyLTExMlMzMTcuNiA4MCAyNTYgODBzLTExMiA1MC40LTExMiAxMTIgNTAuNCAxMTIgMTEyIDExMnptMCA0MGMtNzQuMiAwLTIyNCAzNy44LTIyNCAxMTJ2NTZoNDQ4di01NmMwLTc0LjItMTQ5LjgtMTEyLTIyNC0xMTJ6IiBmaWxsPSIjODJhZWZmIi8+PC9zdmc+"
      />
    );
  };

  static formatResult(data) {
    let docs = { ...data };
    docs.tournaments_doc &&
      docs.tournaments_doc.forEach((t) => {
        t.dates = {
          start_date: convertDate(t.dates.start_date),
          end_date: convertDate(t.dates.end_date),
          close_date: convertDate(t.dates.close_date),
          coach_change_date: convertDate(t.dates.coach_change_date),
          refund_date: convertDate(t.dates.refund_date),
          member_change_date: convertDate(t.dates.member_change_date),
        };
      });
    if (!data) {
      throw "UserModel.formatResult: data is null";
    }
    let { memberships=[] } = data;
    docs.memberships_doc &&
      docs.memberships_doc.forEach((m) => {
        m.getImage = () =>
          docs.images_doc.find((i) => i && i.id && i.ref === m.id) ||
          PROFILE_ICON;
      });
    docs.divisions_doc &&
      docs.divisions_doc.forEach((d) => {
        d.getTournament = () => docs.tournaments_doc.find(d.tournament);
      });
    docs.registrations_doc &&
      docs.registrations_doc.forEach((r) => {
        r.getDivisions = () => {
          return (r.division || '').split(',').map(div => {
            return docs.divisions_doc.find(d => d.id === div);
          }).flat();
        }
        r.getTournament = () =>
          docs.tournaments_doc.find((d) => r.tournament === d.id);
      });
    memberships.forEach((membership) => {
      membership.getPrimaryGym = () => {
        return docs.gyms_doc.find(g => g.id === membership.primary_gym);
      }
      membership.getPrimaryTeam = () => {
        return docs.teams_doc.find(t => t.id === membership.primary_team);
      }
      membership.getName = () => getMembershipName(membership);
      membership.getDivisionsDoc = () => docs.divisions_doc;
      membership.getMembershipsDoc = () => docs.memberships_doc;
      membership.getCarts = (isSubmitted) => {
        let status = isSubmitted? 'S':'A';
        let carts = docs.carts_doc.filter(c => c.membership === membership.id && c.status === status);
        carts.forEach((cart) => {
          cart.items.forEach((i) => (i.quantity = parseInt(i.quantity)));
          return cart;
        });
        return carts;
      };
      membership.deleteCart = (id) => {
        docs.carts_doc = docs.carts_doc.filter((c) => c.id !== id);
      };
      membership.setCart = (cart) => {
        let _cart = docs.carts_doc.find((c) => c.membership === membership.id);
        if (_cart) {
          if (!cart) {
            Utils.clearObject(_cart);
          } else {
            Object.assign(_cart, cart);
          }
        } else {
          docs.carts_doc.push(cart);
        }
      };
      membership.getImage = () => {
        return docs.images_doc.find(
          (image) => image && image.id && image.ref === membership.id
        );
      };
      membership.setImage = (img) => docs.images_doc.push(img);
      membership.getFriends = () =>
        docs.friends_doc.map((f) => {
          f.getFriendDoc = () =>
            docs.memberships_doc.find(
              (m) =>
                m.id ===
                (f.requestor !== membership.id ? f.requestor : f.requestee)
            );
          return f;
        });
      membership.getFamilies = () => {
        let families = docs.families_doc.filter((f) => f && f.id);
        if (membership.impersonate) {
          return [
            ...docs.memberships_doc.filter(
              (m) => m.id === membership.impersonate
            ),
            ...families,
          ];
        }
        return families;
      };
      membership.getCoaches = () => {
        return membership.coaches.map(f => {
          f.getMembership = () => docs.memberships_doc && docs.memberships_doc.find(m => m.id === f.id);
          return f;
        });
      };
      membership.getCoachOf = () => {
        return docs.coachof_doc;
      }
      membership.getParentOf = () => {
        return docs.families_doc;
      }
      membership.getGyms = () => {
        let gymIds = membership.gyms.map((f) => f.id);
        return docs.gyms_doc
          .filter((m) => gymIds.includes(m.id))
          .map(g => {
            g.getTeams = () => docs.teams_doc.filter(t => g.teams.includes(t.id));
            return g;
          });
      };
      membership.getGym = () => {
        if (membership.primary_gym) {
          let gym = membership
            .getGyms()
            .find((g) => g.id === membership.primary_gym);
          if (gym) {
            return gym;
          }
        }
        let gym = (membership.gyms|| []).find(g => g.status === 'A');
        return gym && membership.getGyms().find(g => g && g.id === gym.id);
      };
      membership.getTeams = () => {
        let teamIds = membership
          .getGyms()
          .filter((g) => g && g.status === STATUS.Active)
          .map((g) => g && g.teams)
          .flat()
          .filter((t) => t && t.status === STATUS.Active)
          .map((t) => t && t.id);
        return docs.teams_doc
          .filter((t) => t && teamIds.includes(t.id))
          .map(t => {
            t.getLeagues = () => docs.leagues_doc.filter(l => t.leagues.includes(t.id));
            return t;
          });
      };
      membership.getTeam = () => {
        let teams = membership.getTeams();
        if (teams.length > 0) {
          let team = teams[teams.length - 1];
          team.getIcon = () =>
            docs.images_doc.find(
              (i) =>
                i && i.ref === team.id && i.image_type === IMAGE_TYPE.team_icon
            );
          return team;
        }
      };
      membership.getLeagues = () => {
        let leagueIds = membership
          .getTeams()
          .filter((g) => g.status === STATUS.Active)
          .map((g) => g.leagues)
          .flat()
          .filter((t) => t.status === STATUS.Active)
          .map((t) => t.id);
        return docs.leagues_doc.filter((l) => leagueIds.includes(l.id));
      };
      membership.getLeague = () => {
        let leagues = membership.getLeagues();
        if (leagues.length > 0) {
          return leagues[leagues.length - 1];
        }
      };
      membership.getMemberOf = () => {
        const mapMemberOf = (l, type) => {
          return l && l.id && ({ id: l.id, name: l.name, type });
        }
        return [
          ...membership.getLeagues().map(l => mapMemberOf(l, 'league')),
          ...membership.getTeams().map(l => mapMemberOf(l, 'team')),
          ...membership.getGyms().map(l => mapMemberOf(l, 'gym')),
        ].flat().filter(m => m);
      }
      membership.getTournaments = () => {
        return docs.tournaments_doc;
      };
      membership.getMatches = async () => {
        if (membership.matches) {
          return membership.matches;
        }
        let matches = await UserModel.getMatches(membership.id);
        membership.getMetals = () => {
          let metals = matches.filter(m => m.place).map(m => m.place);
          let counts = Utils.groupCount(metals)
          return counts;
        }
        return membership.matches = matches.filter(m => {
          const { opp } = m.getOpponent();
          return m.membership && m.membership === membership.id && opp;
        });
      };
      membership.getRecord = async () => {
        let matches = await membership.getMatches();
        let wins = 0, losses = 0;
        matches.forEach(m => {
          const {_result} = m.getOpponent();
          _result==='W' && wins++;
          _result==='L' && losses++;
        });
        return `${wins}W - ${losses}L`;
      }
      membership.getUpComings = () => {
        let ts = new Date().getTime();
        return docs.tournaments_doc.filter((t) => t.dates.start_date > ts);
      };
      membership.getCompetitorImage = (id) =>
        docs.images_doc.find((i) => i && i.ref === id);
      membership.getRegistrations = (tournamentId) => {
        return docs.registrations_doc
          .filter(r => {
              return !tournamentId || (r.tournament === tournamentId && r.membership === membership.id);
          })
          .map(r => {
            if (r.refund && !r.refund.request_date) {
              r.refund = null;
            }
            r.getCart = () => docs.carts_doc.find(c => c.id === r.cart);
            return r;
          });
      };
      membership.updateRegistration = (id, newDivision, currentDivision) => {
        const registration = docs.registrations_doc.find((r) => r.id === id);
        const { divisions } = registration;
        divisions.splice(divisions.indexOf(currentDivision), 1);
        divisions.push(newDivision);
        return this.service.Tournament.updateRegistration({
          id: registration.id,
          divisions,
        });
      };
      membership.getAge = () => {
        return UserModel.getAge(membership);
      };
      membership.age = UserModel.getAge(membership);
    });
    return memberships;
  }

  static getUser = async (userId, isProfile) => {
    const response = await RequestUser.getUserRequest(userId, isProfile);
    let data = RequestUtils.getResponseData(response);
    let memberships = UserModel.formatResult(data);
    if (memberships) {
      return memberships[0];
    }
  };

  static getUsers = async ({
    page,
    page_size,
    search_string,
    search_field,
  }) => {
    const response = await RequestUser.getUsersRequest(
      page,
      page_size,
      search_string,
      search_field
    );
    let data = RequestUtils.getResponseData(response);
    return UserModel.formatResult(data);
  };
}
