import styles from "./FormInput.module.scss";
import { FormControl, TextField, ThemeProvider } from "@mui/material";
import classNames from "classnames";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Theme from "./Theme";
import { isEmailFormatValid } from "../Form/Form";
import {default as ClearIcon} from "@mui/icons-material/Clear";
import "./TextFormInput.scss";

const TextFormInput = forwardRef(({ 
    style = {},
    type,
    value,
    autoComplete,
    name,
    label,
    disabled,
    isRequired = false,
    register, setValue,
    helperText, errorText,
    hidden = false,
    onChange,
    onKeyUp,
    onBlur,
    onMouseLeave,
    validateWithTrim,
    InputProps, inputProps,
    isLabelShrunk,
    isCapitalized,
}, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [readOnly, setReadOnly] = useState(disabled);

    useImperativeHandle(ref, () => ({
        textFieldRef,
        setReadOnly,
        setInputValue,
    }));
    
    const textFieldRef = useRef();
    useEffect(() => {
        setInputValue(isCapitalized && value? value.toUpperCase():value||'');
        setValue && setValue(name, value);
    }, [value]);

    const handleOnChange = e => {
        let v = e.target.value;
        if (validateWithTrim && !v.trim()) {
            v = null;
        }
        v = isCapitalized? v.toUpperCase():v;
        setInputValue(v);
        setValue && setValue(name, v);
        onChange && onChange(v);
    }
    const customValidate = () => {
        if (type==='email' && !isEmailFormatValid(inputValue)) {
            return 'Email format is valid';
        }
    }
    const reactHookProps = !register ? 
        {} :
        register(name, 
            {
                required: isRequired && !inputValue? 'This field is required':false,
                validate: customValidate
            }
        );

    const isError = () => {
        if (errorText) {
            return true;
        }
        return isRequired && (!inputValue || customValidate());
    }
    const getClassName = () => (name||'').split('.').pop();
    return <FormControl id={name} 
        className={classNames(hidden ? styles.hiddenInput : '', `TextFormInput-wrapper ${getClassName()}`)}>
        <ThemeProvider theme={Theme}>
            <div className={`TextFormInput ${getClassName()}`}>
                <TextField inputRef={textFieldRef} className="TextField"
                    InputLabelProps={{
                        shrink: isLabelShrunk,
                    }}
                    style={style}
                    autoComplete={autoComplete}
                    hidden={hidden}
                    type={type}
                    disabled={readOnly}
                    label={!label? '':`${label} ${isRequired ? '*' : ''}`}
                    value={inputValue || ''}
                    margin="normal"
                    error={isError()}
                    helperText={customValidate() || helperText || errorText}
                    FormHelperTextProps={{
                        style: { color: helperText && 'green' || errorText && 'red'},
                    }}
                    inputProps={inputProps}
                    InputProps={InputProps}
                    {...reactHookProps}
                    onClick={e => e.stopPropagation()}
                    onBlur={e => {
                        onBlur && onBlur(e);
                    }}
                    onKeyUp={e => {
                        onKeyUp && onKeyUp(e);
                    }}
                    onMouseLeave={e => {
                        onMouseLeave && onMouseLeave(e);
                    }}
                    onChange={handleOnChange}
                />
                <ClearIcon className="ClearIcon" 
                    onClick={e => {
                        e.stopPropagation();
                        setInputValue('');
                        setValue && setValue(name, '');
                        onChange && onChange('');
                    }}/>
            </div>
        </ThemeProvider>
    </FormControl>;
});

export const isError = (errors, name) => {
    if (!errors) {
        return '';
    }
    let fields = name.split('.').map(f => {
        if (isNaN(f)) {
            return f;
        }
        return `[${f}]`;
    });
    try{
        let e = eval(`errors?.${fields.join('?.')}`);
        return e && e.message;
    }catch(e) {
        console.log('errors eval:', e);
    }
}

export default TextFormInput;