import { RequestTeam, RequestMessage, RequestUtils, RequestUser, RequestStore, RequestImage } from "../requests";
import { BAN } from "./TournamentModel";
import { IMAGE_TYPE } from "../../components/Form/Form";
import UserModel from "./UserModel";

export default class TeamModel {
    static async getTeamDisplay(ids) {
        let isMulti = Array.isArray(ids);
        let response = await RequestTeam.getDisplayTeams(isMulti? [ids]:[ids]);
        let result = RequestUtils.getResponseData(response); 
        if (isMulti){
            return result;
        }
        return result[0];
    }

    static async searchTeam(search_string = '') {
        let response = await RequestTeam.searchTeam({ search_string });
        let res = RequestUtils.getResponseData(response);
        return res.filter(r => r && r.display).map(r => {
            let words = r.display.split(',');
            return {id: r.id, display: words.map(w => w.trim()).filter(w => w).join(', ')};
        });
    }

    static getTeams = async ({page, page_size, search_string}) => {
        const response = await RequestTeam.getTeamsRequest({page, page_size, search_string, search_field: 'name'});
        let teams = RequestUtils.getResponseData(response);
        return teams;
    }

    static async membershipRegistration(id, member_id) {
        let response = await RequestTeam.registerTeam(id, member_id);
        return RequestUtils.getResponseData(response);
    }

    static async updateMembership(params) {
        let response = await RequestTeam.updateTeamMembership(params);
        return RequestUtils.getResponseData(response);
    }

    static async deleteTeam(id) {
        let response = await RequestTeam.deleteTeamRequest(id);
        return RequestUtils.getResponseData(response);
    }

    static async addTeam(params) {
        let response = await RequestTeam.addTeamRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async updateTeam(params) {
        let response = await RequestTeam.updateTeamRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async getMemberships({id, search_string, search_field, page, page_size}) {
        let response = await RequestTeam.getTeamMembershipsRequest({id, search_string, search_field, page, page_size});
        return UserModel.formatResult(RequestUtils.getResponseData(response));
    }

    static async getPhotos(params) {
        let response = await RequestImage.getImagesByRefRequest(
            {...params, ids: [params.id], image_type: IMAGE_TYPE.photo});
        return RequestUtils.getResponseData(response);
    }
    
    static async getStoreItems(id) {
        let response = await RequestStore.getStoreItemsRequest({ref: id, ref_type: 'team'});
        let store = RequestUtils.getResponseData(response);
        const {store_items, images_doc} = store;
        return store_items.map(si => {
            si.getImages = () => images_doc.filter(i => i.ref === si.id);
            return si;
        });
    }

    static getUsers = (memberships_doc, members, images_doc) => {
        return memberships_doc.filter(m => members.map(m => m.id).includes(m.id))
            .map(m => {
                m.getImage = () => images_doc.find(i => i.ref === m.id);
                return m;
            });
    }

    static formatResult(data) {
        let docs = {...data};
        let {teams} = data;
        teams.forEach(team => {
            team.getCreatedBy = () => docs.memberships_doc.find(m => m.id === team.created_by);
            team.getTransactions = () => docs.team_transactions_doc;
            team.getImage = () => docs.images_doc.find(image => image && image.id && image.ref === team.id && image.image_type === IMAGE_TYPE.team);
            team.setImage = image => docs.images_doc.push(image);
            team.getIcon = () => docs.images_doc.find(image => image && image.id && image.ref === team.id && image.image_type === IMAGE_TYPE.team_icon);
            team.getTeamMembers = () => docs.memberships_doc
                                            .filter(m => m.teams.map(t => t.id).includes(team.id))
                                            .map(m => {
                                                m.getImage = () => docs.images_doc.find(image => image && image.id && image.ref === m.id);
                                                return m;
                                            });
            team.getAdmins = () => TeamModel.getUsers(docs.memberships_doc, team.admins, docs.images_doc);
            team.getCaptains = () => TeamModel.getUsers(docs.memberships_doc, team.captains, docs.images_doc);
            team.getOwner = () => docs.memberships_doc.find(m => team.owner);
            team.getExecutives = () => TeamModel.getUsers(docs.memberships_doc, team.executives, docs.images_doc);
            team.getLeagues = () => {
                let leagueIds = team.leagues.map(t => t.id);
                return docs.leagues_doc.filter(t => leagueIds.includes(t.id));
            };
            team.getLeague = () => {
                let leagues = team.getLeagues();
                return leagues.length>0? leagues[0]:null;
            };
            team.getGyms = () => {
                return docs.gyms_doc;
            };
            team.getMessages = () => docs.messages_doc.filter(m => m.from === team.id);
            team.getBanMemberships = () => this._getBans(team.id, docs.memberships_doc, docs.bans_doc, BAN.membership);
            team.getBanGyms = () => this._getBans(team.id, docs.gyms_doc, docs.bans_doc, BAN.gym);
            team.canRegister = session => {
                if (team.getBanMemberships().find(m => m.id === session.id))
                    return false;
                return session.teams.find(t => t.id===team.id);
            };
        });
        return teams;
    }

    static getTeam = async (id) => {
        let response = await RequestTeam.getTeamRequest(id);
        let teams = RequestUtils.getResponseData(response);
        teams = this.formatResult(teams);
        if(teams.length > 0){
            return teams[0];
        }
    }

    static async getMessages(params) {
        const response = await RequestTeam.getTeamMessagesRequest(params);
        let data = RequestUtils.getResponseData(response);
        const { memberships_doc, messages, images_doc } = data;
        messages.forEach(message => {
            message.getFromDoc = () => {
                let membership = memberships_doc.find(m => m.id === message.from);
                if (membership) {
                    membership.getImage = () => images_doc.find(i => i && i.ref === membership.id);
                    return membership;
                }
            }
        });
        return messages;
    }
        

    static async sendMessage(params){
        delete params.id;
        params.message_type = 'team';
        let response = await RequestMessage.sendMessageRequest(params);
        let data = RequestUtils.getResponseData(response);
        return data;
    }

    static async disassociateMembership(params) {
        params.registration_type = 'teams';
        let response = await RequestUser.disassociateGymMembershipRegistrationRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async getTeamInfo(id) {
        if (!id) {
            return;
        }
        const response = await RequestTeam.getTeamInfo(id);
        return RequestUtils.getResponseData(response);
    }
}
