import TextFormInput from "../FormInput/TextFormInput";
import { RemoveSubFormIcon } from "./Form";
import "./MembershipTypes.scss";

export default function MembershipTypes({ membership_type, register, errors, refresh, setValue }) {
    return <div className="MembershipTypes">
        {(membership_type || []).map((s, i) =>
            <div key={i} id={`membership_type_${i}`} className="membership-type">
                <div className="text-inputs">
                    <TextFormInput name={`membership_type.${i}.name`}
                        value={s.name} 
                        register={register} setValue={setValue}
                        label="Name"
                        />
                    <TextFormInput name={`membership_type.${i}.code`}
                        value={s.code} 
                        register={register} setValue={setValue}
                        label="Code"
                        />
                </div>
                <RemoveSubFormIcon
                    label={`Membership type ${i + 1}`}
                    onClick={() => {
                        membership_type.splice(i, 1);
                        refresh();
                    }} />
            </div>)}
    </div>;

}