import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
    IonContent, IonItem,
    IonItemGroup,
    IonList,
    IonPage,
    IonThumbnail,
    IonTitle, useIonViewWillEnter, IonButtons, IonIcon
} from "@ionic/react";
import pageStyles from "./../Page.module.scss";
import LoadingList from "../../components/LoadingList/LoadingList";
import classNames from "classnames";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import {
    addCircleOutline as addIcon,
} from 'ionicons/icons';
import { Link, ThemeProvider } from "@mui/material";
import { useStore } from "../../Store";
import Theme from "../../components/FormInput/Theme";
import { FilterInput } from "../../components/Form/Form";
import Utils from "../../serverUtils/Utils";


const LeaguesPage = () => {
    const history = useHistory();
    const [leagues, setLeagues] = useState(null);
    const session = useStore(state => state.session);
    const [searchString, setSearchString] = useState();

    const getData = async (search_string = searchString) => {
        const leagues = await LeagueModel.getLeagues({ search_string });
        leagues.leagues = leagues.leagues.map(l => {
            l.name = l.name.trim();
            return l;
          }).sort((a, b) => Utils.sorter(a, b, 'name'))
        setLeagues(leagues);
    }

    useIonViewWillEnter(() => {
        if (!leagues) {
            getData();
        }
    }, [leagues, searchString, session]);

    const getImage = (id) => {
        const image = leagues.images_doc.find(img => img.ref === id);
        if (image) {
            return <img
                src={image.data.join('')}
                alt={id}
            />;
        }
        return <div className={pageStyles.placeholderImage}></div>
    }

    const doFilter = (s) => {
        setSearchString('leagues', s);
        getData(s);
    }

    return <IonPage className={`${pageStyles.page} LeaguesPage`}>
        <ThemeProvider theme={Theme}>
            <IonContent>
                <div className={pageStyles.pageHeader}>
                    <div className={pageStyles.pageHeaderImage} />
                    <div className={pageStyles.pageHeaderTitleWrapper}>
                        <h1 id="leagues_page" className={classNames('display-font', pageStyles.pageHeaderTitle)}>Leagues</h1>
                        <p className={pageStyles.pageHeaderSubtitle}>Find a team and join a league</p>
                        <FilterInput label="Search League" entity="leagues" onFilter={v => doFilter(v)} />
                    </div>
                </div>
                <div className={pageStyles.marginBottom}>
                    {leagues ? <IonList >
                        {leagues.leagues.map((league) =>
                            <IonItem routerLink={`/leagues/${league.id}`} lines="none" key={league.id}>
                                <IonItemGroup>
                                    <IonThumbnail className={pageStyles.listImage}>
                                        {getImage(league.id)}
                                    </IonThumbnail>
                                </IonItemGroup>
                                <IonItemGroup>
                                    <IonTitle>{league.name}</IonTitle>
                                </IonItemGroup>
                            </IonItem>
                        )}
                    </IonList> : <LoadingList />}
                </div>
            </IonContent>
        </ThemeProvider>
    </IonPage>;
};

export default LeaguesPage;