import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableFormInput, { ToolbarButtons } from '../FormInput/TableFormInput';
import Utils from '../../serverUtils/Utils';
import { IonButtons, IonSpinner } from '@ionic/react';
import { default as ExportIcon } from "@mui/icons-material/ArrowForwardOutlined";
import { default as ImportIcon } from "@mui/icons-material/ArrowBackOutlined";
import { default as ReloadIcon } from "@mui/icons-material/Cached";
import { default as MessageIcon } from '@mui/icons-material/MailOutline';
import { default as EditIcon } from "@mui/icons-material/Edit";
import { default as RegisteredOnlineIcon } from "@mui/icons-material/Check";
import { default as PaymentReceivedIcon } from "@mui/icons-material/PriceCheck";
import { default as SendMessageIcon } from "@mui/icons-material/SendOutlined";
import { default as RemoveIcon } from "@mui/icons-material/RemoveCircle";
import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import { filterMultSelectFormInput, hasTableFormChange, MESSAGE_TYPE, reloadPage } from '../Form/Form';
import AutocompleteTextFormInput from '../FormInput/AutocompleteTextFormInput';
import { Avatar, Link, TextField, Tooltip } from '@mui/material';
import SelectFormInput from '../FormInput/SelectFormInput';
import { STATUS } from '../Form/Form';
import TournamentModel from '../../serverUtils/models/TournamentModel';
import UserModel from '../../serverUtils/models/UserModel';
import { getDivisionDisplay } from '../LeagueInformationForm/Divisions';
import './Registrations.scss';
import AlertPane from "../FormInput/Message";
import { GENDER, getGenderLabel } from "../LeagueInformationForm/WeightClasses";
import { useStore } from "../../Store";
import { getReplaceableDivisions } from "../../pages/TournamentPage/DivisionRegistration";
import { RequestCommon } from "../../serverUtils/requests";
import DateFormInput1 from "../FormInput/DateFormInput1";
import MultiSelectFormInput from "../FormInput/MultiSelectFormInput";
import { PAYMENT } from "../Form/PaymentInfos";
import TextFormInput from "../FormInput/TextFormInput";
import TextAreaFormInput from "../FormInput/TextAreaFormInput";
import { STORE_ITEM_TYPE } from "../../serverUtils/models/modelMethods";
import CheckboxFormInput from "../FormInput/CheckboxFormInput";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import MessageModel from "../../serverUtils/models/MessageModel";

const PAYMENT_METHOD = [
    {value: '', label: '-None-'},
    {value: 'cash', label: 'Cash'},
    {value: 'check', label: 'Check'},
    {value: 'cc', label: 'Credit Card'},
    {value: 'venmo', label: 'Venmo'},
    {value: 'applepay', label: 'Apple Pay'},
];
export const headCells_registration = [
    {
        id: 'refund',
        numeric: false,
        disablePadding: true,
        label: 'Refund',
        align: 'left',
        disable_filter: true,
    },
    {
        id: 'first_name',
        numeric: false,
        disablePadding: true,
        label: 'First Name',
        align: 'left',
        publish: true,
        default: true,
    },
    {
        id: 'last_name',
        numeric: false,
        disablePadding: true,
        label: 'Last Name',
        align: 'left',
        publish: true,
        default: true,
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        publish: true,
        default: true,
    },
    {
        id: 'email',
        disablePadding: false,
        label: 'Email',
    },
    // {
    //     id: 'seed',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Seed',
    // },
    {
        id: 'division',
        numeric: false,
        disablePadding: false,
        label: 'Division',
        publish: true,
        default: true,
    },
    {
        id: 'pool',
        numeric: false,
        disablePadding: false,
        label: 'Pool', 
        publish: true,
        default: true,
    },
    {
        id: 'team',
        numeric: false,
        disablePadding: false,
        label: 'Team', 
        publish: true,
        default: true,
    },
    {
        id: 'gym',
        numeric: false,
        disablePadding: false,
        label: 'Gym', 
        publish: true,
        default: true,
    },
    {
        id: 'gender',
        numeric: false,
        disablePadding: false,
        label: 'Gender', 
        publish: true,
    },
    {
        id: 'age',
        numeric: false,
        disablePadding: false,
        label: 'Age', 
        publish: true,
    },
    {
        id: 'gi_level',
        numeric: false,
        disablePadding: false,
        label: 'BJJ Rank', 
        publish: true,
    },
    {
        id: 'weight',
        numeric: false,
        disablePadding: false,
        label: 'Weight',
        publish: true,
    },
    {
        id: 'registered_on',
        numeric: false,
        disablePadding: false,
        label: 'Registered Date',
        publish: true,
    },
    {
        id: 'cart_amount',
        numeric: false,
        disablePadding: false,
        label: 'Price',
    },
    {
        id: 'paid',
        numeric: false,
        disablePadding: false,
        label: 'Paid',
    },
    {
        id: 'waive_fee',
        numeric: false,
        disablePadding: false,
        label: 'Waive Fee',
    },
    {
        id: 'payment_method',
        numeric: false,
        disablePadding: false,
        label: 'Payment Method',
    },
    {
        id: 't_shirt',
        numeric: false,
        disablePadding: false,
        label: 'T-Shirt',
        publish: true,
    },
    {
        id: 'is_manual',
        label: 'Registered OnLine',
        disable_filter: false,
        align: 'center'
    },
    {
        id: 'contact_infos',
        label: 'Emergency Contacts',
        disable_filter: true,
        align: 'center'
    },
    
];

export const REFUND_REASONS = [
    { label: '- Select reason -', value: '' },
    { label: 'No reason', value: 'no_reason' },
    { label: 'Time Conflict', value: 'time_conflict' },
    { label: 'Cannot Attend', value: 'cannot_attend' },
];

export const REFUND_STATUS = [
    { label: '- Select status -', value: ''},
    { label: 'Request', value: 'RR' },
    { label: 'Approved', value: 'RA' },
    { label: 'DisApproved', value: 'RD' },
]

export const getRefundStatusLabel = (val) => {
    let s = REFUND_STATUS.find(rs => rs.value === val)
    return s ? s.label : '';
};

export const REGISTRATION_STATUS = [
    { value: 'A', label: 'Active' },
    { value: 'P', label: 'Pending' },
    { value: 'S', label: 'Suspended' },
    { value: 'D', label: 'Disqualify' },
];

const EXPORT_FIELDS = [
    { value: "cart", label: "Online"},
    { value: "first_name", label: "First Name" },
    { value: "last_name", label: "Last Name" },
    { value: "middle_name", label: "Middle Name" },
    { value: "gender", label: "Gender" },
    { value: "age", label: "Age" },
    { value: "gi_level", label: "Rank" },
    { value: "weight", label: "Weight" },
    { value: "registered_on", label: "Registered Date" },
    { value: "division", label: "Division" },
    { value: "pool", label: "Bracket Pool" },
    { value: "status", label: "Status" },
    { value: "email", label: "Email" },
    { value: "address1", label: "Address 1" },
    { value: "address2", label: "Address 2" },
    { value: "city", label: "City" },
    { value: "suburb", label: "Suburb" },
    { value: "state", label: "Region/State" },
    { value: "country", label: "Country" },
    { value: "zip", label: "Postal Code" },
    { value: "phone", label: "Phone" },
    { value: "team", label: "Team" },
    { value: "gym", label: "Gym" },
    { value: "paid", label: "Paid" },
    { value: "t_shirt", label: "T-Shirt" },
];

const IMPORT_HEADERS = [
    "email",
    "first_name",
    "last_name",
    "gender",
    "age",
    "gi_level",
    "weight",
    "division",
    "pool",
    "gym"
]
const Registrations = forwardRef(({ tournament, isEditMode, isDense, onUpdate, isWeighInStation }, ref) => {
    const session = useStore(state => state.session);
    const localServer = useStore(state => state.local_server);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState();
    const [isImporting, setIsImporting] = useState();
    const [isSendMessage, setIsSendMessage] = useState(false);

    const tableRef = useRef();
    const fileInputRef = useRef();
    const checkCRCMessageRef = useRef();
    const checkCRCMessageRef1 = useRef();
    const collectionSummaryRef = useRef();
    const toolbarButtonsRef = useRef();
    const toolbarButtonsRef1 = useRef();
    const CRC_Fields = [...headCells_registration.filter(h => h.crc).map(h => h.id), 'admin_notes', 'status', 'amount'];
    
    useEffect(() => {
        setTimeout(() => {
            tournament.getRegistrations().forEach(r => !r.crc && (r.crc = Utils.getCRC(r, CRC_Fields)));
            // let memberships =  Utils.uniqArray(tournament.getRegistrations().map(r => r.membership));
            // for (let m of memberships) {
            //     const {balance} = getBalanceStatus(tournament.getRegistrations().filter(r => r.membership === m));
            //     let reg = tournament.getRegistrations().find(r => r.membership === m);
            //     console.log(m, balance, reg.getMembership().first_name);
            // }
            setLoading(false);
        }, tournament.getRegistrations().lengh > 20? 3000:0);
    }, []);

    const checkCRC = () => {
        checkCRCMessageRef.current.innerHTML = '';
        checkCRCMessageRef1.current.innerHTML = '';

        if (hasTableFormChange(tournament.getRegistrations(), CRC_Fields)) {
            checkCRCMessageRef.current.innerHTML = 'Data has changed';
            checkCRCMessageRef1.current.innerHTML = 'Data has changed';
            toolbarButtonsRef.current.setIndicator({save: 'yellow'});
            toolbarButtonsRef1.current.setIndicator({save: 'yellow'});
        }else {
            toolbarButtonsRef.current.setIndicator({save: ''});
            toolbarButtonsRef1.current.setIndicator({save: ''});
        }
    }

    useImperativeHandle(ref, () => ({
        refresh: () => setRefresh(!refresh),
    }));

    const doImport = async (fileId) => {
        setIsImporting(true);
        let result = await TournamentModel.importRegistrations(fileId, tournament.id);
        if (result && !result.error){
            setMessage('success: Successfully imported registrations');
            setTimeout(() => document.location.reload(), 3000);
        }else {
            setMessage('error: Error importing registrations');
        }
    }

    const doSave = async () => {
        setMessage('');
        let regs = tournament.getRegistrations()
            .filter(r => {
                let crc = Utils.getCRC(r, CRC_Fields);
                return r.crc !== crc;
            });
        if (regs.length === 0){
            return;
        }
        regs = regs
            .map(r => {
                r.tournament = tournament.id;
                r.status = r.status || 'A';
                return r;
            });
        let errors = [];
        for (let reg of regs) {
            let result = await TournamentModel.saveRegistration(reg);
            if (!result || result.error) {
                reg.id = result.id;
                errors.push(reg);
            }else {
                reg.crc = Utils.getCRC(reg, CRC_Fields);
            }
        }
        setMessage(errors.length === 0 ? 'success: Successfully update selected registrations' : 'error: Errors updating registrations');
        checkCRC();
    }

    const doExport = () => {
		let fields = EXPORT_FIELDS;
		let text =
			fields
				.map((f) => f.label)
				.join("\t") +
			"\n" +
			tournament.getRegistrations(true)
				.map(r =>
					fields
						.map(f => {
                            let found = '';
                            try {
                                found = renderCell(r, f.value, true);
                            }catch(e) {
                                console.log('Export error: ', e);
                            }
                            return found;
						})
						.join("\t")
				)
				.join("\n");

		if (!navigator.clipboard) {
			return setMessage('error: Current browser does not support clipboard');
		}
		return navigator.clipboard.writeText(text)
                    .then(() => setMessage('success: Export data has been copied to clipboard'));
	}

    const doAdd = () => {
        let registrations = [...tournament.getRegistrations(), { id: `-${tournament.getRegistrations().length}` }];
        tournament.getRegistrations = () => registrations;
        reloadPage(setLoading, () => {
            const loadLastPage = () => {
                setTimeout(() => {
                    if (tableRef.current) {
                        tableRef.current.gotoLastPage();
                        checkCRC();
                    }else {
                        loadLastPage();
                    }
                   
                }, 60);
                
            }
            loadLastPage();
        });
    }

    const doDelete = async () => {
        let fails=[], deletes = [];
        for (let s of tableRef.current.selected) {
            let d = tournament.getRegistrations().find(d => d.id === s);
            if (d) {
                if (!d.id.startsWith('-')) {
                    let status = await TournamentModel.deleteRegistration(d.id);
                    if (status){
                        deletes.push(d.id);
                    }else {
                        fails.push(d.id);
                    }
                }else {
                    deletes.push(d.id);
                }
            }
        }
        let registrations = tournament.getRegistrations().filter(r => !deletes.includes(r.id))
        tournament.getRegistrations = () => registrations;
        if (fails.length > 0) {
            setMessage('error: Failed to delete one or more registrations');
        }else {
            setMessage('success: Successfully deleted selected registrations');
        }

        checkCRC();
        reloadPage(setLoading, () => {
            tableRef.current.gotoPage(0);
            tableRef.current.setSelected([]);
        });
    }

    const isNonGateway = row => {
        const isGateWay = () => {
            let paymentTypes = Object.values(PAYMENT);
            let payment_type = row.getCart().payment.payment_type
            return paymentTypes
                .filter(p => p.value!=='inperson')
                .map(p => p.value)
                .includes(payment_type);
        }
        return row && (row.is_manual || !row.getCart ||  !row.getCart() || !isGateWay());
    }

    const CoachChange = ({row, options}) => {
        const [changeMessage, setChangeMessage] = useState();
        return <div className="coach-change">
            <SelectFormInput 
                value={row.division}
                options={options}
                onChange={v => {
                    row.division = v;
                }} /> 
            <button className="button"
                onClick={async () => {
                    const update = async () => {
                        let result = await TournamentModel.saveRegistration(row);
                        if (!result || result.error) {
                            return setChangeMessage(`error: Cannot update registration`);
                        }
                        setChangeMessage('success: Successfully updated registration');
                    }
                    setChangeMessage('');
                    update();
                }}>
                Change</button>
            {changeMessage && <AlertPane message={changeMessage} setMessage={setChangeMessage} timeOut={2000}/>}
        </div>;
    }

    const getRegistrationCartAmount = row => {
        if (row.getCart && row.getCart()) {
            let item = row.getCart().items.find(i => i.item_type === STORE_ITEM_TYPE.tournament_registration);
            let skus = item.sku.split(',');
            const getAmountIndex = () => {
                for (let i=0; i<skus.length; i++) {
                    let found = tournament.getRegistrations().find(r => r.division === skus[i] && r.amount > 0);
                    if (found) {
                        return i;
                    }
                }
                return 0;
            }
            if (skus.length === 1) {
                return item.unit_price;
            }else if (row.division === skus[getAmountIndex()]) {
                return  row.amount;
            }
        }
        return 0;
    }
    
    const getPaymentType = (row) => {
        let c = row.getCart();
        if (!c || !c.payment.payment_type || isNonGateway(row)) {
            return '';
        }
        let pt = PAYMENT[c.payment.payment_type];
        return (pt && pt.label) || '';
    }

    const RegistrationStatus = ({row}) => {
        const [statusMessage, setStatusMessage] = useState(false);
        const selectRef = useRef();
        return <div className="RegistrationStatus">
            <SelectFormInput name="status" ref={selectRef}
                value={row.status||'A'}
                label="Status"
                placeholder="Select status"
                options={STATUS}
                onChange={async v => {
                    try{
                        if (!isNonGateway(row)) {
                            let credits = getTransactionCredits();
                            if (v === 'A' && credits === 0){
                                selectRef.current.setInputValue(row.status);
                                return setStatusMessage('error: This is a gateway registration.  Your registration credits is zero.  Please purchase more credits before bracketing this registration.')
                            }
                            let r = await LeagueModel.adjustLeagueTransactionCredits(row.id, tournament.getLeague().id, v);
                            if (r) {
                                tournament.getLeague().transaction_credit.credits = parseInt(r.id);
                                collectionSummaryRef.current.refresh();
                                row.status = v;
                                row.crc = Utils.getCRC(row, CRC_Fields);
                                setStatusMessage('success: Successfully update transaction credits');
                            }else {
                                setStatusMessage('error: Error updating transaction credits');
                            }
                        }else {
                            row.status = v;
                        }
                    }finally {
                        checkCRC();
                    }
                }}
            />
            <AlertPane message={statusMessage} setMessage={setStatusMessage} timeOut={5000} />
        </div>;
    }

    const renderCell = (row, field, readOnly) => {
        const getDivisionLink = (label='') => {
            return <Link href={`/tournaments/${tournament.id}${isEditMode? '/edit':''}?tab=brackets&id=${row.division}&highlite=${row.membership}`}>{label}</Link>;
        }
        const divisionEdit = (isCoachEdit) => {
            let alreadyRegistereds = tournament.getRegistrations().filter(r => r.membership === row.membership).map(r => (r.combo_ids||'').split(','));
            alreadyRegistereds = Utils.uniqArray(alreadyRegistereds.flat());
            let division = tournament.getDivisions().find(d => d.id === row.division);
            let options = isEditMode? tournament.getDivisions().map(d => ({value: d.id, label: getDivisionDisplay(d, true)})) : 
                [{value: row.division, label: getDivisionDisplay(division, true)}, ...getReplaceableDivisions(tournament, row, null, alreadyRegistereds)];
            let label = options.find(o => o.value === row.division);
            label = (label && label.label) || '';
            let combos = (row.combo_ids || '').split(',').filter(c => c !== row.division).filter(c => c);
            return <div className="divisionEdit">
                {!isCoachEdit?
                <MultiSelectFormInput
                    multiple={false}
                    value={{label, value: row.division}}
                    name="division"
                    label={getDivisionLink('goto Division')}
                    optionLabel="label"
                    optionValue="value"
                    fetchOptions={searchVal => {
                        let filters = !searchVal? options : 
                            options.filter(a => {
                                let words = searchVal.toLowerCase().split(' ');
                                return words.every(word => Utils.extractTextFromElement(a.label).toLowerCase().includes(word));
                            });
                        return Promise.resolve(filters);
                    }}
                    onChange={v => {
                        row.division = v && v.value;
                        checkCRC();
                    }}
                /> : <CoachChange row={row} options={options} /> }
                {isEditMode && combos.length>0 &&
                    <div className="combos">
                        <span className="label">Combo with</span>
                        <ul className="warning">
                            {combos.map((c, i) => {
                                let d = tournament.getDivisions().find(d => d.id === c);
                                return d && <li key={i}>
                                    <Tooltip title={getDivisionDisplay(d, true)}>
                                        <span>{d.code}</span>
                                    </Tooltip>
                                </li>;
                            })}
                        </ul>
                    </div>}
            </div>;
        }

        if (isEditMode && !readOnly) {
            if (field === 'weight') {
                return <TextField type="number" 
                    defaultValue={row[field]} 
                    className="weight" 
                    label="Weight"
                    onChange={e => {
                        row.weight = e.target.value;
                        checkCRC();
                    }}
                    onClick={e => e.stopPropagation()}
                />;
            }
            if (field === 'seed') {
                return <TextField className="seed"
                    defaultValue={row[field]} 
                    label="Seed"
                    type="number"
                    onChange={e => {
                        row.seed = e.target.value;
                        checkCRC();
                    }}
                />;
            }
            if (field === 'registered_on'){
                return <DateFormInput1
                    name={`registered_on`}
                    hasTime
                    label="Registered Date"
                    value={row.registered_on || ""}
                    onChange={v => {
                        row.registered_on = v;
                        checkCRC();
                }}/>;
            }
            if (field === 'status') {
                return <RegistrationStatus row={row}/>;
            }
            if (field === 'payment_method') {
                return isNonGateway(row)? 
                    <SelectFormInput name="payment_method"
                        value={row.payment_method||''}
                        label="Payment Method"
                        placeholder="Select method"
                        options={PAYMENT_METHOD}
                        onChange={v => {
                            row.payment_method = v;
                            checkCRC();
                        }}
                    /> : getPaymentType(row);
            }
            if (field === 'paid') {
                return isNonGateway(row) && getRegistrationCartAmount(row)? 
                    <TextField 
                        defaultValue={row.amount} 
                        className="paid"
                        label="Paid"
                        type="number"
                        onChange={e => {
                            row.amount = parseInt(e.target.value);
                            collectionSummaryRef.current.refresh();
                            checkCRC();
                        }}
                        onClick={e => e.stopPropagation()}
                    /> :  getRegistrationCartAmount(row) || '';
            }
            if (field === 't_shirt') {
                let hasTshirt = tournament.getRegistrations().find(r => r.id !== row.id && r.getCart && r.getCart() && r.getCart().t_shirt);
                let options = [{value: ''}, ...tournament?.t_shirt?.sizes.map(ts => ({value: ts.code, label: ts.name}))];
                return (!hasTshirt || row.t_shirt)? 
                    <SelectFormInput
                        name="t_shirt"
                        value={row.t_shirt}
                        label="T-Shirt"
                        placeholder="Select t-shirt"
                        options={options}
                        onChange={v => {
                            row.getCart && row.getCart() && (row.getCart().t_shirt = v);
                            row.t_shirt = v;
                            checkCRC();
                        }}
                    /> : '';
            }
            if (field === 'division') {
                return divisionEdit();
            }
            if (field === 'waive_fee' && isNonGateway(row)) {
                return  <CheckboxFormInput
                            name="waive_fee"
                            value={row.waive_fee}
                            onChange={v => {
                                row.waive_fee = v;
                                checkCRC();
                            }}
                        />;
            }
        }

        if (field === 'cart_amount') {
            let cart_amount = getRegistrationCartAmount(row);
            if (cart_amount === 0 && !isNonGateway(row)) {
                return '';
            }
            return cart_amount;
        }

        if (field === 'division' && isCoachEdit(row)) {
            return divisionEdit(true);
        }

        if (field === 'team') {
            try{
                return row?.getMembership()?.getTeam()?.name;
            }
            catch(e) {
                console.log(e);
            }
            return;
        }
        if (field === 'registered_on') {
            if (row.registered_on){
                return Utils.formatDateTime(row.registered_on);
            }
            return '';
        }
        if (field === 'division'){
            if (readOnly) {
                return tournament.getDivisions().find(d => d.id === row.division)?.name || '';
            }
            return getDivisionLink(tournament.getDivisions().find(d => d.id === row.division)?.name);
        }
        if (field === 'weight') {
            let membership = row.getMembership && row.getMembership();
            if (membership){
                let weight = row.weight || membership.weight;
                return weight && `${weight} ${membership.weight_unit}`;
            }
            return 0;
        }
        if (field === 'status') {
            let status = STATUS.find(s => s.value === row.status)
            return status? status.label : '';
        }
        if (field === 'gender') {
            return GENDER[row.gender];
        }
        if (field === 'pool') {
            return row.pool? row.pool.split(',')
                .map(p => tournament.poolings.find(tp => tp.pool === p))
                .filter(p => p).join(', ') : '';
        }
        if (field === 't_shirt') {
            return row.t_shirt || '';
        }

        if (!readOnly) {
            return row[field];
        }
        return isEditMode && !row[field] ? <EditIcon /> : row[field];
    }

    const getTeamName = (e) => {
        if (!e || !e.getMembership || !e.getMembership()?.getTeam()){
            return ;
        }

        let team = tournament?.getTeams().find(t=>t.id===e.getMembership()?.getTeam().id);
        if (team){
            return team.name;
        }
    }

    const tableComparator = (a, b, orderBy) => {
        if (orderBy === 'team') {
            return {
                a: getTeamName(a),
                b: getTeamName(b)
            };
        }
        if (orderBy === 'email') {
            return {
                a: a.getMembership && a.getMembership().email,
                b: b.getMembership && b.getMembership().email
            };
        }
        if (orderBy === 'membership') {
            let am = a.getMembership && a.getMembership();
            let bm = b.getMembership && b.getMembership();
            return {
                a: `${am && am.first_name} ${am && am.last_name}`,
                b: `${bm && bm.first_name} ${bm && am.last_name}`,
            }
        }
        if (orderBy === 'payment_method') {
            const findPaymentMethod = r => {
                let pm = PAYMENT_METHOD.find(pm => pm.value === r.payment_method);
                return isNonGateway(r)? 
                    (pm && pm.label) : getPaymentType(r);
            }
            return {
                a: findPaymentMethod(a) || '',
                b: findPaymentMethod(b) || ''
            }
        }
        if (orderBy === 'cart_amount') {
            return {
                a: getRegistrationCartAmount(a) || 0,
                b: getRegistrationCartAmount(b) || 0
            }
        }

        if (orderBy === 'paid') {
            const getPaid = (r) => isNonGateway(r) && getRegistrationCartAmount(r)? 
                r.amount : getRegistrationCartAmount(r) || 0;
            return {
                a: getPaid(a) || 0,
                b: getPaid(b) || 0
            }
        }

        if (orderBy === 'is_manual') {
            const getIsManual = (row) => {
                if (!row.is_manual && row.getCart && row.getCart() && row.getCart().payment_status !== "false") {
                    return 'received';
                }else if (!row.is_manual) {
                    return 'registered';
                }
                return '';
            }
            return {
                a: getIsManual(a) || 0,
                b: getIsManual(b) || 0
            }
        }

        if (orderBy === 'waive_fee') {
            const getWaiveFee = r => {
                if (r.waive_fee === true) {
                    return 1;
                }if (r.waive_fee === false) {
                    return 2;
                }
                return 3;
            }
            return {
                a: getWaiveFee(a),
                b: getWaiveFee(b)
            }
        }
    }

    function Search({title, row, getOptions, initOptions=[], callback}) {
        return <div className="Search">
            <AutocompleteTextFormInput
                label={`Select ${title}`}
                placeholder={`Search ${title}`}
                onChange={o => {
                    if (o) {
                        callback(o);
                    }
                }}
                value={initOptions.length>0 && initOptions[0]}
                options={initOptions}
                optionsCallback={async search_string => {
                    let response = await getOptions(search_string);
                    if (response){
                        return Object.values(response)
                                    .map(o => ({id: o.id, label:o.display})) || [];
                    }
                }}
                />
        </div>;
    }

    const getHeaders = () => {
        if (isEditMode) {
            return headCells_registration;
        }
        let headers = headCells_registration.filter(hc => hc.default);
        if (tournament.publish.competitor_columns) {
            headers = tournament.publish.competitor_columns.split('|')
                .map(h => headCells_registration.find(hc => hc.id === h));
        }
        return headers;
    }

    const getFilterData = ({ headCell, filterString }, filterData, isFilter) => {
        let words = Utils.separateWords(filterString);
        if (headCell.id === 'division') {
            return filterData.filter(r => {
                let div = tournament.getDivisions().find(d => r.division === d.id);
                return isFilter(words, (isText) => getDivisionDisplay(div, isText));
            });
        }else if (headCell.id === 'membership') {
            return filterData.filter(r => {
                return isFilter(words, () => UserModel.getMembershipName(r.getMembership()));
            });
        }else if (headCell.id === 'team') {
            return filterData.filter(r => {
                return isFilter(words, () => r.getMembership().getTeam() && r.getMembership().getTeam().name );
            });
        }else if (headCell.id === 'gym') {
            return filterData.filter(r => {
                return isFilter(words, () => r.getMembership().getGym() && r.getMembership().getGym().name );
            });
        }else if (headCell.id === 'email') {
            return filterData.filter(r => {
                return isFilter(words, () => r.getMembership().email );
            });
        }else if (headCell.id === 'payment_method') {
            return filterData.filter(r => {
                return isFilter(words, () => {
                    let pm = PAYMENT_METHOD.find(pm => pm.value === r.payment_method);
                    if (pm) {
                        return pm.label;
                    }
                    return getPaymentType(r);
                });
            });
        }
    }

    const handleReloadList = e => {
        e.stopPropagation();
        document.location.reload();
    }

    const getButtons = () => {
        return isEditMode &&
            [<IonButtons key={5} className="extra-buttons">
                <button className="icon_button" onClick={handleReloadList}>
                    <Tooltip title="Reload competitor list"><ReloadIcon /></Tooltip></button>
                <button className="icon_button" onClick={doExport}>
                    <ExportIcon />Export
                </button>
                <button className="icon_button" 
                    onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    fileInputRef.current.click();
                }}>
                    <ImportIcon />Import
                    {isImporting && <IonSpinner />}
                </button>
            </IonButtons>];
    }

    const isCoachEdit = row => {
        let ts = new Date().getTime() 
        if (isEditMode || 
            (tournament?.dates.coach_change_date || ts) <= ts ||
            !session || 
            !session.getAdminInfo().athletes.find(a => a.id === row.membership)) {
            return false;
        }
        return true;
    }

    const RegisteredOnline = ({row}) => {
        if (!row.is_manual && row.getCart && row.getCart() && row.getCart().payment_status !== "false") {
            return <PaymentReceivedIcon 
                className={`PaymentReceivedIcon`}/>;
        }else if (!row.is_manual) {
            return <RegisteredOnlineIcon 
                className={`RegisteredOnlineIcon`}/>;
        }
        return '';
    }

    const getBalanceStatus = data => {
        let total = 0, collected = 0;
        data.forEach(r => {
            if (!r.waive_fee) {
                let cart_amount = getRegistrationCartAmount(r);
                total += cart_amount;
                if (r.amount > 0) {
                    collected += isNonGateway(r)? r.amount:cart_amount;
                }
            }
        });
        let balance = total-collected;
        return {total, collected, balance};
    }

    const getTransactionCredits = () => tournament.getLeague()?.transaction_credit?.credits ?? 0;
    const CollectionSummary = forwardRef(({data}, ref) => {
        const [rowData, setRowData] = useState([]);
        const [isApplying, setIsApplying] = useState(false);
        const [message, setMessage] = useState();
        const refreshTimeoutRef = useRef();
        useEffect(() => setRowData(data), [data]);
        useImperativeHandle(ref, () => ({
            refresh: rData => {
                if (rData) {
                    setRowData(rData);
                }else {
                    refreshTimeoutRef.current && clearTimeout(refreshTimeoutRef.current);
                    refreshTimeoutRef.current = setTimeout(() => setRowData([...rowData], 1000));
                }
            },
        }));

        const {total, collected, balance} = getBalanceStatus(rowData);
        let transaction_credits = getTransactionCredits();
        let pendings = rowData.filter(r => {
            return !isNonGateway(r) && r.status === 'P';
        });
        return <div className="CollectionSummary">
            <div className="info">
               <div className="total">
                <b>Total Price: </b><span>{tournament.currency} {total.toFixed(2)}</span>
                </div>
                <div className="collected">
                <b>Total Paid: </b><span>{tournament.currency} {collected.toFixed(2)}</span> 
                </div>
                <div className={`balance ${balance < 0? 'credit':''} ${balance === 0? 'zero':''}`}>
                <b>Balance Owe: </b><span>{tournament.currency} {balance.toFixed(2)}</span> 
                </div>
                <div className={`transaction-credit ${transaction_credits > 0? 'credit':''} ${transaction_credits === 0? 'zero':''}`}>
                <b>Available Gateway Credits: </b><span>{transaction_credits}</span>
                <div className="pending">
                    <b>Pending Gateway Registration: </b><span className="">{pendings.length}</span>
                </div>
                </div>
            </div>
            <div className="apply">
                {transaction_credits>0 && <button className="button"
                    onClick={e => {
                        e.stopPropagation();
                        setIsApplying(true);
                        const apply = async () => {
                            for (let p of pendings) {
                                let r = await LeagueModel.adjustLeagueTransactionCredits(p.id, tournament.getLeague().id, 'A');
                                if (!r || r.error) {
                                    return setMessage('error: Error updating transaction credits');
                                }
                                p.status = 'A';
                                p.crc = Utils.getCRC(p, CRC_Fields);
                                let creditLeft = parseInt(r.id)
                                tournament.getLeague().transaction_credit.credits = creditLeft;
                                if (creditLeft === 0) {
                                    break;
                                }
                            }
                            setMessage('success: Successfully update transaction credits');
                            collectionSummaryRef.current.refresh();
                            tableRef.current.refresh();
                            checkCRC();
                        }
                        apply();
                }}><span>Apply Credits to Pending Gateway Registrations</span>{isApplying && <IonSpinner />}</button>}
                <AlertPane message={message} setMessage={setMessage} timeOut={4000} />
            </div>
        </div>;
    });

    return loading? <div className="tab-busy"><IonSpinner className="IonSpinner"/><span className="warning">Loading list...</span></div> :
        <div className="Registrations">
            <input
                ref={fileInputRef}
                hidden
                accept="*.csv"
                type="file"
                onChange={e => {
                    loadLocalFile(200, fileInputRef, file => {
                        if (file.error){
                            setIsImporting(false);
                            fileInputRef.current.value = '';
                            return setMessage(`error: ${file.error}`);
                        }
                        let base64Segments = file.data.split(',');
                        let data = atob(base64Segments[1]).trim();
                        let delimitter = '\n';
                        if (data.includes()) {
                            delimitter = '\r\n';
                        }else if (data.includes('\r')) {
                            delimitter = '\r';
                        }
                        let lines =  data.split(delimitter);
                        let headers;
                        for (let line of lines){
                            line = line.trim();
                            if (line.length === 0 && !headers){
                                continue;
                            }else if (line.length > 0 && !headers){
                                headers = line.split(',').map(h => h.trim());
                                let intersections = Utils.intersection(IMPORT_HEADERS, headers);
                                if (intersections.length !== IMPORT_HEADERS.length){
                                    setIsImporting(false);
                                    fileInputRef.current.value = '';
                                    return setMessage(`error: Import file error.  Not all require headers are present.  Require headers are [${IMPORT_HEADERS.join(', ')}]`);
                                }
                                break;
                            }
                        }
                        file.data = `${base64Segments[0]},${btoa(lines.join('\n'))})`;
                        RequestCommon.uploadFile(file, p => {
                            console.log(p)
                        }).then(p => {
                            if (p.error) {
                                setIsImporting(false);
                                fileInputRef.current.value = '';
                                return setMessage(`error: Error uploading csv file`);
                            }
                            fileInputRef.current.value = '';
                            doImport(p.id);
                        });
                    });
                }}/>
            <AlertPane message={message} timeOut={5000} setMessage={setMessage}/>
            {isEditMode && (!isSendMessage? 
                <button className="button SendMessage-button" onClick={() => setIsSendMessage(true)}>
                    <MessageIcon/>Send a message to competitors</button> :
                <SendMessage isAdmin={isEditMode}
                    from={tournament.id}
                    message_type={MESSAGE_TYPE.tournament}
                    created_by={session.id}
                    memberships={tournament.getRegistrations().map(r => r.getMembership())} 
                    onDone={() => setIsSendMessage(false)}/>)}
            {isEditMode && <CollectionSummary ref={collectionSummaryRef} data={tournament.getRegistrations()}/>}
            <TableFormInput name="Registrations" showFilterChips omitSelectAll
                onFilterData={filterData => collectionSummaryRef.current && collectionSummaryRef.current.refresh(filterData)}
                onSelect={ss => {
                    for (let i=ss.length-1; i>=0; i--){
                        let reg = tournament.getRegistrations().find(r => r.id === ss[i]);
                        if (!reg.is_manual){
                            ss.splice(i, 1);
                        }
                    }
                    return ss;
                }}
                isEditable={isEditMode}
                ref={tableRef} isDense={isDense ? true : false}
                toolbarButtons={isEditMode && 
                    (() => <ToolbarButtons ref={toolbarButtonsRef}
                        doAdd={!localServer && doAdd} 
                        // doDelete={!localServer && doDelete} 
                        doSave={!localServer && doSave} 
                        buttons={getButtons()} />)}
                toolbarButtons1={isEditMode && 
                    (() => <ToolbarButtons ref={toolbarButtonsRef1}
                        doAdd={!localServer && doAdd} 
                        // doDelete={!localServer && doDelete} 
                        doSave={!localServer && doSave} 
                        buttons={getButtons()} />)}
                headCells={getHeaders()}
                comparator={tableComparator}
                data={tournament.getRegistrations()}
                getFilterData={getFilterData}
                onSelectedUpdate={<span ref={checkCRCMessageRef} className="warning" />}
                onSelectedUpdate1={<span ref={checkCRCMessageRef1} className="warning" />}
                renderTRow={({ row = {}, isSelected, index, handleClick, filterChips }) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    let membership = row.getMembership && row.getMembership() 
                        || {
                            getGym: () => null,
                            getTeam: () => null,
                            getImage: () => null,
                            gender: null,
                            weight: null,
                            tournament: tournament.id,
                        };
                    let gym = membership.getGym && membership.getGym() && membership.getGym();
                    let team = membership.getTeam && membership.getTeam() && membership.getTeam();
                    const getImage = (entity) => {
                        let image = entity.getImage && entity.getImage();
                        if (image){
                            return image.data.join('');
                        }
                        return '';
                    }
                    return <TableRow className={`${row.id} ${row.id && row.id.startsWith('-')? 'add-registration':''}`}
                        hover
                        onClick={(event) => {
                            handleClick(event, row.id);
                            event.stopPropagation();
                        }}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                    >

                        {isEditMode && 
                        <TableCell padding="checkbox" align="center" className="index">
                            {/* {isNonGateway(row)?  */}
                                {/* <IndexCell isItemSelected={isItemSelected} labelId={labelId} index={index + 1} /> :  */}
                            <span>{index + 1}
                                {row.id.startsWith("-") && 
                                    <RemoveIcon className="RemoveIcon"
                                        onClick={e => {
                                            e.stopPropagation();
                                            let regs = tournament.getRegistrations().filter(r => r.id !== row.id);
                                            tournament.getRegistrations = () => regs;
                                            setRefresh(!refresh);
                                            checkCRC();
                                        }}/>}
                            </span>
                        </TableCell>}
                        {filterChips.includes('refund') && isEditMode &&  
                        <TableCell>
                            <Refund row={row} 
                                onDone={refund => {
                                    row.refund = refund;
                                    checkCRC();
                                }}/>
                        </TableCell>}
                        {filterChips.includes('first_name') && <TableCell
                            id={labelId}
                            scope="row"
                            padding="none"
                            className="membership"
                        >
                            <div className="membership">
                                {isEditMode && (!membership || !membership.id)?
                                    <Search row={row} getOptions={UserModel.searchUser} title="Member"
                                        callback={o => {
                                            row.membership = o.id;
                                            let name= o.label.split(' - ')[0].split(' ');
                                            row.name = o.display;
                                            row.first_name = name.shift();
                                            row.last_name = name.pop() || '';
                                            row.getCart && (row.getCart().membership = o.id);
                                            reloadPage(setLoading, async () => {
                                                let member = await UserModel.getUser(o.id);
                                                row.getMembership = () => member;
                                                row.is_manual = true;
                                                let registrations = tournament.getRegistrations();
                                                tournament.getRegistrations = () => [...registrations];
                                                setRefresh(!refresh);
                                            });
                                        }}
                                    /> :
                                    row.first_name}
                            </div>
                        </TableCell>}
                        {filterChips.includes('last_name') && <TableCell className="last_name">{row.last_name}</TableCell>}
                        {filterChips.includes('status') && <TableCell align="center" className="status">
                            {renderCell(row, 'status')}
                        </TableCell>}
                        {filterChips.includes('email') && isEditMode && <TableCell><div className="email">{membership?.email}</div></TableCell>}
                        {/* <TableCell align="center"
                            style={{ cursor: isEditMode ? 'pointer' : '' }} 
                            onClick={(e) => {
                                e.stopPropagation();
                            }}>
                            {membership && renderCell(row, 'seed')}
                        </TableCell> */}
                        {filterChips.includes('division') && <TableCell align="left" className="division">
                            {renderCell(row, 'division')}
                        </TableCell>}
                        {filterChips.includes('pool') && <TableCell align="center" >
                            {(row.pool||'').split('|').map((p, i) => {
                                return <Link key={i} href={`tournaments/${tournament.id}/edit?tab=poolings&pool=${p}`}>{p}</Link>;
                            })}</TableCell>}
                        {filterChips.includes('team') && <TableCell align="center">
                            <div className="team">
                                {team && <Link href={`/teams/${team.id}`} className="team-link">
                                    <Avatar src={getImage(team)}/>
                                    {renderCell(row, 'team')}
                                </Link>}
                            </div>
                        </TableCell>}
                        {filterChips.includes('gym') && <TableCell align="center">
                            <div className="gym">
                                {gym && <Link href={`/gyms/${gym.id}`} className="gym-link">
                                    <span className="gym-name">{row.gym = gym && gym.name}</span>
                                </Link>}
                            </div>
                        </TableCell>}
                        {filterChips.includes('gender') && <TableCell align="center">{getGenderLabel(membership.gender)}</TableCell>}
                        {filterChips.includes('age') && <TableCell align="center">{row.age = Utils.getAge(membership?.dob)}</TableCell>}
                        {filterChips.includes('gi_level') && <TableCell align="center">{row.gi_level = membership?.gi_level}</TableCell>}
                        {filterChips.includes('weight') && <TableCell align="center" style={{ color: row.weight ? 'lightgreen' : '', cursor: isEditMode ? 'pointer' : '' }} 
                            onClick={(e) => {
                                e.stopPropagation();
                            }}>
                            {membership && renderCell(row, 'weight')}
                        </TableCell>}
                        {filterChips.includes('registered_on')&& <TableCell align="center" 
                            onClick={(e) => {
                                e.stopPropagation();
                            }}>
                            {renderCell(row, 'registered_on')}
                        </TableCell>}
                        {filterChips.includes('cart_amount') && <TableCell align="center">{renderCell(row, 'cart_amount')}</TableCell>}
                        {filterChips.includes('paid') && <TableCell align="center">{renderCell(row, 'paid')}</TableCell>}
                        {filterChips.includes('waive_fee') && <TableCell align="center">{renderCell(row, 'waive_fee')}</TableCell>}
                        {filterChips.includes('payment_method') && <TableCell align="center" >
                            {renderCell(row, 'payment_method')}
                        </TableCell>}
                        {filterChips.includes('t_shirt') && <TableCell className="t-shirt">
                            {renderCell(row, 't_shirt')}
                        </TableCell>}
                        {filterChips.includes('is_manual') && <TableCell className="registered-online">
                            <RegisteredOnline row={row} />
                        </TableCell>}
                        {filterChips.includes('contact_infos') && isEditMode && <TableCell className="contact-infos">
                            {(row.getMembership &&  row.getMembership() && row.getMembership().contact_infos||[]).map(ci => 
                                <ul>
                                    <li><b>Name:</b> {ci.name}</li>
                                    <li><b>Relation:</b> {ci.title}</li>
                                    <li><b>Phone:</b> {ci.phone}</li>
                                    <li><b>Email:</b> {ci.email}</li>
                                </ul>
                            )}
                        </TableCell>}
                    </TableRow>
                }}
            />
        </div>;
});

const loadLocalFile = (maxSizeInKB, fileInputRef, cb) => {
    if (fileInputRef.current.files === 0) {
        return cb({error: 'No file selected'});
    }
      
    let file = fileInputRef.current.files[0];
    if (file.size > maxSizeInKB * 1024) {
      fileInputRef.current.value = '';
      return cb({error: `Maximun size is ${maxSizeInKB}K bytes`});
    }
    let ext = file.name.split('.');
    ext = ext.length>1? ext.pop():'';
    let reader = new FileReader();
    reader["readAsDataURL"](file);
    reader.addEventListener(
      "load", 
      () => cb({
        data: reader.result, 
        ext, 
        name: file.name, 
        lastModifiedDate: file.lastModifiedDate.getTime(),
        size: file.size
      }),
      false
    );
}

const Refund = ({ row, onDone }) => {
    const [refund, setRefund] = useState(row.refund || {});
    const [show, setShow] = useState(false);

    const isRequest = () => refund.status === 'RR';
    const isRefunded = () => row.refund && row.refund.amount;
    const getButtonLabel = () => {
        if (isRequest()) {
            return 'Refund Request';
        }
        return `Refund${isRefunded()? 'ed':''} ${isRefunded()? row.refund.amount: ''}`;
    }
    const getRefundReason = () => {
        let r = REFUND_REASONS.find(rs => rs.value === refund.reason);
        return r && r.label;
    }
    return <div className="Refund">
            {show? <div className="refund-form">
                <div className="request-reason">
                    Request reason: {getRefundReason()}
                </div>
                {refund.request_notes && 
                <TextAreaFormInput  disabled
                    name="request_notes"
                    placeholder="Member notes" className="member-note"
                    label="Member Notes" value={refund.request_notes}
                    style={{ backgroundColor: 'inherit', width: 'inherit' }} />}
                <span>{refund.request_reason}</span>
                <TextAreaFormInput 
                    name="admin_notes"
                    placeholder="Admin Notes" className="admin-note"
                    value={refund.admin_notes}
                    style={{ backgroundColor: 'inherit', width: 'inherit' }}
                    onChange={e => setRefund({ ...refund, admin_notes: e.target.value })} />
                <SelectFormInput options={REFUND_STATUS}
                    label="Select Status"
                    value={refund.status}
                    onChange={v => {
                        setRefund({ ...refund, status: v });
                    }}
                />
                <TextFormInput value={refund.amount || ''} label="Amount" 
                    onChange={v => refund.amount = v}/>
                {row.amount && <span className="warning">Paid amount {row.amount}</span>}
                <button className="button" 
                    onClick={() => {
                        onDone(refund);
                        setShow(false);
                    }}
                >Done</button>
            </div> :
            <button className={`button ${isRequest()? 'warning':''} ${row.refund && row.refund.amount? 'refunded':''}`} 
                onClick={e => {
                    e.stopPropagation();
                    setShow(true);
                }}>{getButtonLabel()}</button>}
        </div>;
}

export const SendMessage = ({memberships, from, onDone, message_type, created_by, to, toDisplay, isAdmin}) => {
    const [selecteds, setSelected] = useState([]);
    const [list, setList] = useState([]);
    const [content, setContent] = useState('');
    const [message, setMessage] = useState();
    useEffect(() => {
        memberships && setList(Utils.uniqArrayByKey(memberships, 'id')
            .map(m => ({display: UserModel.getMembershipName(m), id: m.id})));
    }, [memberships]);
    useEffect(() => {
        to && setSelected([to]);
    }, [to]);

    const send = async () => {
        let message = {
            message_type: message_type || MESSAGE_TYPE.membership,
            tos: to? [to] : selecteds.map(s => s.id),
            content,
            created_on: new Date().getTime(),
            status: 'P',
            from,
            created_by: created_by || from
          };
        let response = await MessageModel.sendBulkMessage(message);
        if (response && !response.error) {
            setMessage('success: Successfully send message.');
            setTimeout(onDone, 2000);
        }else {
            setMessage('error: Error sending message.');
        }
    }

    return <div className="SendMessage">
        {isAdmin && <b><MessageIcon/><span>Select All Competitors
            <CheckboxFormInput onChange={v => {
                if (v) {
                    setSelected(list);
                }else {
                    setSelected([]);
                }
            }}/>
        </span></b>}
        {!to && <MultiSelectFormInput
            value={selecteds}
            name="memberships"
            label="Memberships"
            optionLabel="display"
            optionValue="id"
            fetchOptions={searchVal => {
                let filters = filterMultSelectFormInput(searchVal, list);
                return Promise.resolve(filters);
            }}
            onChange={v => setSelected(v)}
          />}
        {toDisplay && <div><b>To: </b>{toDisplay}</div>}
        <TextAreaFormInput label="Message" 
            value={content} 
            onChange={v => {
                setContent(v);
            }}/>
        <div className="send-text">
            <button className="button small_button" onClick={send}>
                <span>Send</span> <SendMessageIcon className="SendMessageIcon"/> 
            </button>
            <button className="button small_button" onClick={onDone}>Done</button>
        </div>
        <AlertPane message={message} setMessage={setMessage} timeOut={3000} />
    </div>
}
export default Registrations;
