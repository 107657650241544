import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import LocalServerModel from '../../serverUtils/models/LocalServerModel';
import Utils from '../../serverUtils/Utils';
import { useStore } from '../../Store';
import { useHistory } from 'react-router-dom';
import ScoreCard from '../../components/Bracket/ScoreCard';
import { duplicate_station_error, EmptyStation, getStationName, initStation, isMaster } from './LocalServer';
import AlertPane from "../../components/FormInput/Message";

let sse;
export const LocalScoreCard = forwardRef(({ }, ref) => {
  const history = useHistory();
  const station = useStore(state => state.station);
  const setStation = useStore(state => state.setStation);
  const localServer = useStore((state) => state.local_server);
  const tournament = useStore(state => state.local_tournament);
  const setTournament = useStore(state => state.setLocalTournament);
  const [data, setData] = useState({});
  const [isInit, setIsInit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const addNotification = useStore(state => state.addNotification);
  const [stationError, setStationError] = useState();
  const [redirect, setRedirect] = useState();
  const scorecardRef = useRef();

  useImperativeHandle(ref, () => ({
    
  }));

  const receive = (message) => {
    if (message.to !== LocalServerModel.LOCAL_SERVER.ping && message.to !== station.station) {
      return;
    }
    if (message === duplicate_station_error) {
      setStationError('Duplicate station ' + Utils.cookie(LocalServerModel.LOCAL_SERVER.local_station))
    }
    const { label, type, to, from, rtc, rtc_candidate, notification: notif, tournament_id } = message;
    if (from === station.station) {
      return;
    }
    if (to === LocalServerModel.LOCAL_SERVER.ping) {
      return LocalServerModel.sendMessage({
        from: station.station,
        type: LocalServerModel.STATION_TYPE.score_card,
        label: station.label,
        data: station.data && JSON.stringify(station.data),
      });
    }
    if (to !== station.station) {
      return;
    }
    console.log(
      '\nsse_receive:',
      Utils.formatDateTime(new Date().getTime(), 'hh:mm:ss'),
      '\nmessage:',
      message
    );
    Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament, tournament_id);
    if (LocalServerModel.setCurrentStation(message, station, setStation, setRedirect)) {
      sse.close();
      return;
    }
    if (notif) {
      let n = {...message};
      delete n.clients;
      return addNotification(n);
    }else {
      const init = async () => {
        if (!message.data) {
          return;
        }
        let t = tournament;
        if (!t) {
          t = await LocalServerModel.getTournament(Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament));
          setTournament(t);
        }
        setData(station.data = JSON.parse(message.data));
      };
      init();
    }
  };

  const initSSE = async () => {
    sse && sse.close();
    setIsInit(true);
    if (!tournament) {
      let id = Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament);
      if (id) {
        let t = await LocalServerModel.getTournament(id);
        setTournament(t);
      }
    }
    sse = initStation({
      localServer,
      type: LocalServerModel.STATION_TYPE.score_card,
      receive,
      history,
      forceUpdate: () => setRefresh(!refresh)
    });
  };

  useEffect(() => {
    station && station.type === LocalServerModel.STATION_TYPE.score_card && initSSE();
  }, [localServer, station]);

  return redirect? redirect : 
    <div className="LocalScoreCard">
      <h1>Score Card Station {getStationName(station && station.label)}</h1>
      {!(
        localServer &&
        station &&
        tournament &&
        data.schedule
      ) ? (
        <EmptyStation
            type={LocalServerModel.STATION_TYPE.score_card}
          setStation={(v) => {
            setStation(v);
          }}
            station={station}
        />
      ) : (
        <div className='ScoreCard-wrapper'>
            <ScoreCard
              ref={scorecardRef}
              disableFullScreen={isMaster()}
              data={data}
              division={data.bracket_entry1.division}
              onChange={({ }) => {
                const { schedule, bracket_entry1, bracket_entry2, clock } = data;
                LocalServerModel.sendMessage({
                  from: station.station,
                  label: station.label,
                  type: LocalServerModel.STATION_TYPE.score_card,
                  data: JSON.stringify({ schedule, bracket_entry1, bracket_entry2, clock }),
                });
              }}
              tournament={tournament}
            />
        </div>
      )}
    </div>
  ;
});
