import { useEffect, useState } from "react";
import {
  IonButtons,
  IonContent, IonFab,
  IonFabButton, IonIcon, IonPage,
  useIonViewWillEnter
} from "@ionic/react";
import PageStyles from "./../Page.module.scss";
import "./UserPage.scss";
import TournamentPageStyles from "../TournamentPage/TournamentPage.module.scss";
import { RequestUtils } from "../../serverUtils/requests";
import {
  pencil
} from "ionicons/icons";
import classNames from "classnames";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import UserModel from "../../serverUtils/models/UserModel";
import { useHistory } from "react-router-dom";
import ImageModel from "../../serverUtils/models/ImageModel";
import Photos from "../../components/Form/Photos";
import { ENTITY } from "../../serverUtils/Models";
import { useStore } from "../../Store";
import { default as SendIcon } from "@mui/icons-material/SendOutlined";
import { default as FollowIcon } from "@mui/icons-material/FavoriteBorder";
import { Tabs } from "../../components/Tabs/Tabs";
import { MyMatches } from "../DashboardPage/DashboardPage";
import { Link, Paper } from "@mui/material";
import { GI_LEVEL_OPTIONS } from "../../components/LeagueInformationForm/Ranks";
const TABS = [
  { html: "Match History", url: "match_history" },
  // { html: "Friends", url: "friends" },
  { html: "Photos", url: "photos" },
  { html: "Emergency Contact Information", url: "contact_info" },
];

const MemberOf = ({ data }) => {
  const session = useStore(state => state.session);
  if (!session) {
    return '';
  }
  const enityids = UserModel.getAdminEnityIds(session);
  return <div className={`${TournamentPageStyles.section} MemberOf`}>
    {JSON.stringify(data.getMemberOf().map(m => {
      return {
        ...m,
        isAdmin: enityids.includes(m.id)
      }
    }))}
  </div>
}

export const getFlag = async (country) => {
  if (!country){
    return '';
  }
  let f = await import(`../../icons/flags/svg/${country.toLowerCase()}.svg`);
  return f.default;
}

const UserPage = (props) => {
  const [data, setData] = useState();
  const [flag, setFlag] = useState();
  const [record, setRecord] = useState();
  const [matches, setMatches] = useState([]);
  const [metals, setMetals] = useState([]);
  const session = useStore((state) => state.session);
  const history = useHistory();
  const [sectionIndex, setSectionIndex] = useState();
  useEffect(() => {
    let params = RequestUtils.getURLParameters();
    if (params.tab) {
      setSectionIndex(TABS.findIndex(t => t.url === params.tab));
    }
  }, []);

  const getData = async (id) => {
    const user = await UserModel.getUser(id);
    if (user) {
      user.start_date = user.created_on;
      setData(user);
      let image =
        user.getImage()?.id &&
        (await ImageModel.getImageById(user.getImage().id));
      if (image) {
        user.getImage = () => image;
        setData({ ...user });
      }
      if (user.country) {
        let f = await getFlag(user.country);
        setFlag(f && <IonIcon className={`flag`} icon={f} />);
      }
      let r = await user.getRecord();
      setRecord(r);
      r = await user.getMatches();
      setMatches(r);
      r = await user.getMetals();
      setMetals(r);
    }
  };

  useIonViewWillEnter(() => {
    getData(RequestUtils.getURLId("users"));
  }, [session]);

  const Stats = () => {
    let tcount = new Set(matches.map(m => m.tournament)).size;
    let ms = Object.values(metals).reduce((acc, val) => acc + val, 0);
    const getLabel = (size, label, append = 's') => {
      return <span>{label}{size > 1 ? append : ''}</span>
    }
    return <div className={`Stats`}>
      <div>{record} <span>record</span></div>
      <div>{matches.length} <span>{getLabel(matches.length, 'match', 'es')}</span></div>
      <div>{tcount} <span>{getLabel(tcount, 'tournament')}</span></div>
      <div>{ms} <span>{getLabel(ms, 'medal')}</span></div>
    </div>
  }

  const getGiLevel = level => {
    let l = GI_LEVEL_OPTIONS.find(g => g.value === data.gi_level);
    return l? l.label : '';
  }

  const isAllowView = () => {
    return session && 
      (session.id === data.id ||
        data.coaches.find(c => c.id === session.id));
  }

  const getTabs = () => {
    if (!isAllowView()) {
      let tabs = [...TABS];
      tabs.pop();
      return tabs;
    }
    return TABS;
  }
  return (
    <IonPage>
      <IonContent>
        {data ? (
            <div className="UserPage">
              <div className={`page-title`}>
                <div className="user-info">
                  <div className={`profileImage`}>
                    <img src={data.getImage()?.data?.join("")} />
                  </div>
                  <div className={`name`}>
                    <h1>
                      {data.getName()}
                      {flag}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="details">
                <span className={`location`}>
                  {[data.city, data.state, data.region, data.suburb, data.country].filter(e => e).join(', ')}
                </span>
                {data.getGym() && <Link href={`/gyms/${data.getGym().id}`}>{data.getGym() ? data.getGym().name : ''}</Link>}
                <span className="level">{getGiLevel(data.level)}</span>
                {session && session.is_super && <b style={{color: 'blue'}}>{data.status}</b>}
              </div>
              <IonFab horizontal="end">
                {data && UserModel.isAdmin(session, null, data) && (
                  <IonFabButton className="entity-edit"
                    routerLink={`/users/${props.match.params.id}/edit`}
                  >
                    <IonIcon icon={pencil} className="button"/><span>Edit</span>
                  </IonFabButton>
                )}
              </IonFab>
              {session && session.id !== data.id && (
                <IonButtons className="buttons">
                  <button className={'button'}
                    onClick={() => {
                      history.push(
                        `/messages?id=${data.id}&message_type=user&label=${UserModel.getMembershipName(data)}`
                      );
                    }}
                  >
                    <SendIcon className={PageStyles.send_message} />Message
                  </button>
                  <button className={'button'}
                    onClick={() => {
                      console.log();
                    }}
                  >
                    <FollowIcon />Follow
                  </button>
                </IonButtons>
              )}
              <div className={classNames("ion-padding")}>
                <Stats />
                <Tabs
                  index={sectionIndex}
                  tabs={getTabs()}
                  sections={[
                    <MyMatches session={data} matches={matches} />,
                    // <Friends membership={data} />,
                    <Photos entity={data} entity_type={ENTITY.user} model={UserModel} session={session}/>,
                    isAllowView() && <div className="ContactInformation">
                        {(data.contact_infos||[]).map(ci => 
                          <Paper className="Paper">
                            <b>Name:</b> {ci.name}
                            <b>Relation:</b> {ci.title}
                            <b>Phone:</b> {ci.phone}
                            <b>Email:</b> {ci.email}
                          </Paper>
                        )}
                    </div>
                  ]}
                />

              </div>
            </div>
        ) : (
          <LoadingScreen />
        )}
      </IonContent>
    </IonPage>
  );
};

export default UserPage;
