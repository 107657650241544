import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { calculateTotalEntriesByRound, calculateTotalRounds, getDivisionSize, createInitialRound, createRoundRobinRounds, BYE, WINS_BY, WINS_BY_OPTIONS } from "./bracketUtils";
import BracketStyles from "./Bracket.module.scss";
import CompetitorStyles from "./Competitor.module.scss";
import "./BracketWrapper.scss";
import SelectFormInput from "../FormInput/SelectFormInput";
import { RequestUtils } from "../../serverUtils/requests";
import classNames from "classnames";
import FlagIcon from "../FlagIcon";
import TournamentModel from "../../serverUtils/models/TournamentModel";
import { BRACKET, BRACKET_TYPES } from "./Bracket";
import Utils from "../../serverUtils/Utils";
import AlertPane from "../FormInput/Message";
import { useHistory } from 'react-router-dom';
import FilterChips from "../Filters/FilterChips";
import { default as ZoomInIcon } from "@mui/icons-material/ZoomIn";
import { default as ZoomOutIcon } from "@mui/icons-material/ZoomOut";
import { default as ExportPDFIcon } from "@mui/icons-material/PictureAsPdfRounded";
import { default as EditIcon } from "@mui/icons-material/Edit";
import { default as ScheduleIcon } from "@mui/icons-material/CalendarTodayOutlined";
import { Checkbox, FormControlLabel, Link, Paper, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import Theme from "../FormInput/Theme";
import UserChip from "../UserChip/UserChip";
import { useStore } from "../../Store";
import UserModel from "../../serverUtils/models/UserModel";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { DivisionFilter } from "../../pages/TournamentPage/TournamentPage";
import { ENTITY } from "../../serverUtils/Models";
import Busy from "../Busy";
import { hasTableFormChange } from "../Form/Form";

const BracketWrapper = React.forwardRef(({ tournament, tabsRef, isEditMode }, ref) => {
    const session = useStore(state => state.session);
    const [isEditable, setIsEditable] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [isExportingAll, setExportingAll] = useState();
    const divsionFilterRef = useRef();
    const bracketRef = useRef();
    const urlParams = RequestUtils.getURLParameters();
    const allDivisions = tournament.getAvailableDivisions()
        .filter(d => getDivisionSize(d.id, tournament) > 0)
        .map(d => {
            d.tournament = tournament.id;
            return d;
        });
    const [currentDivision, setCurrentDivision] = useState();
    useEffect(() => {
        let curDiv = urlParams.id 
        if (curDiv) {
            curDiv = tournament.getAvailableDivisions().find(d => d.id === curDiv);
            curDiv.getTournament = curDiv.getTournament || (() => tournament);
            setCurrentDivision(curDiv);
        } 
    }, [tournament]);
    useEffect(() => {
        session && setIsEditable(UserModel.isAdmin(session, ENTITY.tournament, tournament.id));
    }, [session]);
    useImperativeHandle(ref, () => ({
        forceUpdate: () => setRefresh(!refresh),
    }));

    const ExportAll = () => {
        const Orientation = () => {
            const handleClick = (e, v) => {
                e.stopPropagation();
                GROUP_OF = v;
                setRefresh(!refresh);
            }
            return <div className="Orientation">
                <FormControlLabel control={<Radio checked={GROUP_OF===8} onClick={e => handleClick(e, 8)}/>} label="Landscape" />
                <FormControlLabel control={<Radio checked={GROUP_OF===16} onClick={e => handleClick(e, 16)}/>} label="Portrait" />
            </div>
        }

        const startExport = (divisions, groups) => {
            const {initDivision, division} = divsionFilterRef.current;
            setExportingAll({groups, divisions});
            setTimeout(() => {
                if (division !== divisions[0].id) {
                    initDivision(divisions[0].id);
                }else {
                    bracketRef.current.doExport();
                }
            }, 500);
        }

        const exportAll = () => {
            const {filters, setSelected} = divsionFilterRef.current;
            let groups = [...Object.keys(filters).filter(g => filters[g].length > 0)];
            if (groups.length>0) {
                let g = groups.shift();
                setSelected(g);
                startExport([...filters[g]], groups);
            }
        }

        const exportGroup = () => {
            const {filters, selected} = divsionFilterRef.current;
            startExport([...filters[selected]], []);
        }

        return <div className="ExportAll">
            <button className="button export" onClick={exportAll}><ExportPDFIcon /> Export All</button>
            <button className="button export" onClick={exportGroup}><ExportPDFIcon /> Export Group</button>
            <Orientation />
        </div>
    }

    const bracketRenderComplete = () => {
        if (isExportingAll){
            bracketRef.current.doExport();
        }
    }

    const bracketExportComplete = () => {
        if (isExportingAll) {
            const {filters, setSelected, initDivision} = divsionFilterRef.current;
            const {groups, divisions} = isExportingAll;
            divisions.shift();
            if (divisions.length === 0 && groups.length > 0){
                let g = groups.shift();
                setSelected(g);
                let divisions =  [...filters[g]];
                setExportingAll({groups: [...groups], divisions: [...divisions]});
                initDivision(divisions[0].id);
            }else if (divisions.length > 0){
                setExportingAll({groups: [...groups], divisions: [...divisions]});
                initDivision(divisions[0].id);
            }else {
                setExportingAll(null);
            }   
        }
    }

    return <div className="Bracket">
        <ExportAll />
        <DivisionFilter ref={divsionFilterRef}
            tournament={tournament} 
            includeBracketTypeSize={isEditMode}
            onSelect={f => {
                setCurrentDivision(allDivisions.find(d => d.id === f));
            }} />

        {currentDivision && 
            <Bracket ref={bracketRef}
                tournament={tournament}
                currentDivision={currentDivision}
                isEditable={isEditMode && isEditable}
                tabsRef={tabsRef} 
                renderComplete={bracketRenderComplete}
                exportComplete={bracketExportComplete}
            />}
    </div>
});

const CRC_Division_Fields = ['bracket_type', 'is_bracket_by_seed', 'is_block', 'max_reg', 'is_third']; 
export const CRC_Bracket_Fields = [ "division","tournament","isThird","isLoser","round","index","membership","score","score2","scorep","place","result"];
const divisions_crc = 'divisions_crc';
const bracket_entries_crc = 'bracket_entries_crc';
const Bracket = forwardRef(({ tournament, currentDivision, isEditable, tabsRef, renderComplete, exportComplete }, ref) => {
    const localServer = useStore(state => state.local_server);
    const setLocalTournament = useStore(state => state.setLocalTournament);
    const setIsBracketEntriesUpdate = useStore(state => state.setIsBracketEntriesUpdate);
    const registrations = currentDivision ? currentDivision.getRegistrations(true) : [];
    const [reload, setReload] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [message, setMessage] = useState('');
    const [reloadData, setReloadData] = useState(true);
    const [bracketEntries, setBracketEntries] = useState([]);
    const [hoveredCompetitor, setHoveredCompetitor] = useState(null);
    const [zoom, setZoom] = useState(1.0);
    const [isExporting, setIsExporting] = useState(false);
    const [isThird, setIsThird] = useState();
    const bracketTypeRef = useRef();
    const exportButtonRef = useRef();

    const [hasChange, setHasChange] = useState(false);
    const [checkCRC, setCheckCRC] = useState(false);
    useImperativeHandle(ref, () => ({
        doExport: () => exportButtonRef.current && exportButtonRef.current.click(),
    }));

    useEffect(() => {
        setHasChange(hasTableFormChange(bracketEntries, CRC_Bracket_Fields));
    }, [checkCRC]);

    useEffect(() => {
        bracketEntries.forEach(e => !e.crc && (e.crc = Utils.getCRC(e, CRC_Bracket_Fields)));
    }, [bracketEntries]);

    useEffect(() => {
        if (reloadData) {
            tournament.getBracketEntries()
                .then(entries => {
                    setBracketEntries(entries);
                    setReloadData(false);
            });
        }
    }, [reloadData]);

    useEffect(() => {
        setReloadData(true);
        currentDivision.getTournament = () => tournament;
        setIsThird(currentDivision.is_third);
        renderComplete && renderComplete();
    }, [currentDivision]);

    const saveBracketEntries = async () => {
        setMessage('');
        setIsDisabled(true);
        let entries = bracketEntries.filter(e => e.division === currentDivision.id);
        let response = await TournamentModel.updateBracketEntries(entries, tournament.id, currentDivision.id, currentDivision.bracket_type);
        if (!response || response.error) {
            setIsDisabled(false);
            return setMessage('error: Error saving bracket');
        }
        entries.forEach(e => e.crc = Utils.getCRC(e, CRC_Bracket_Fields));
        if (currentDivision.updated) {
            response = await TournamentModel.updateDivisions(currentDivision.tournament, [currentDivision]);
            if (!response || response.error) {
                setIsDisabled(false);
                return setMessage('error: Error saving bracket');
            }
        }
        tournament.getCacheBracketEntries = [];
        response = await TournamentModel.updateScheduleEntries([], tournament.id, currentDivision.id);
        tournament.getCacheSchedules = () => null
        setIsDisabled(false);
        setMessage('success: Successfully update bracket');
        setTimeout(() => setMessage(''), 2000);
        setCheckCRC(!checkCRC);
    }

    const doReload = () => {
        let r = !reload;
        setReload(r);
        setTimeout(() => {
            setReload(!r);
        }, 300);
    }

    const updateTournamentBracketEntries = (entries) => {
        for (let i = bracketEntries.length - 1; i >= 0; i--) {
            let entry = bracketEntries[i];
            if (entry.division === currentDivision.id) {
                bracketEntries.splice(i, 1);
            }
        }
        entries.forEach(e => bracketEntries.push(e));
        setBracketEntries([...bracketEntries]);
        setIsBracketEntriesUpdate();
    }

    const renderBracket = (bracketType) => {
        if (reload) {
            return '';
        }
        switch (bracketType) {
            case BRACKET.Single_Elimination:
                return <BracketSingleElimination tabsRef={tabsRef} ref={bracketTypeRef}
                    zoom={zoom}
                    registrations={registrations}
                    isThird={isThird}
                    currentDivision={currentDivision}
                    bracketEntries={bracketEntries.filter(e => e.division === currentDivision.id)}
                    isEditing={isEditing}
                    isDisabled={isDisabled}
                    hoveredCompetitor={hoveredCompetitor}
                    setHoveredCompetitor={setHoveredCompetitor}
                    setBracketEntries={(entries) => {
                        updateTournamentBracketEntries(entries);
                    }}
                    setCheckCRC={() => setCheckCRC(!checkCRC)}
                />;
            case BRACKET.Round_Robin:
                return <BracketRoundRobin tabsRef={tabsRef} ref={bracketTypeRef}
                    zoom={zoom}
                    registrations={registrations}
                    currentDivision={currentDivision}
                    bracketEntries={bracketEntries.filter(e => e.division === currentDivision.id)}
                    isEditing={isEditing}
                    isDisabled={isDisabled}
                    hoveredCompetitor={hoveredCompetitor}
                    setHoveredCompetitor={setHoveredCompetitor}
                    setBracketEntries={(entries) => {
                        updateTournamentBracketEntries(entries);
                    }}
                    setCheckCRC={() => setCheckCRC(!checkCRC)}
                />;
            case BRACKET.Double_Elimination_Loser_3RD:
            case BRACKET.Double_Elimination_Loser_2ND:
            case BRACKET.Double_Elimination_Loser_1ST:
                return <BracketDoubleElimination tabsRef={tabsRef} ref={bracketTypeRef}
                    zoom={zoom}
                    registrations={registrations}
                    currentDivision={currentDivision}
                    bracketEntries={bracketEntries.filter(e => e.division === currentDivision.id)}
                    isEditing={isEditing}
                    isDisabled={isDisabled}
                    hoveredCompetitor={hoveredCompetitor}
                    setHoveredCompetitor={setHoveredCompetitor}
                    setBracketEntries={(entries) => {
                        updateTournamentBracketEntries(entries);
                    }}
                    setCheckCRC={() => setCheckCRC(!checkCRC)}
                />;
            default:
                return <div>Not implemented</div>
        }
    }

    const clearBracket = () => {
        let entries = bracketEntries.filter(e => e.division !== currentDivision.id);
        setBracketEntries(entries);
        tournament.caches.bracketEntries = entries;
        doReload();
        localServer && setLocalTournament({...tournament});
        setHasChange(true);
    }

    const updateZoom = (isIn) => {
        const inc = 0.25;
        if (isIn) {
            let z = zoom + inc;
            z <= 1.0 && setZoom(z);
        } else {
            let z = zoom - inc;
            z >= 0.25 && setZoom(z);
        }
    }

    const NonPlacements = ({ }) => {
        if (!currentDivision) {
            return '';
        }
        let bracketEntryIds = [...new Set(bracketEntries.map(e => e.division === currentDivision.id && e.membership))];
        let nonPlaces = registrations.filter(r => !bracketEntryIds.includes(r.membership));
        return nonPlaces.length > 0 && <div className={classNames("NonPlacements", BracketStyles.Draw)}>
            <h6>Non Placements</h6>
            {nonPlaces.map((n, i) => {
                return <UserChip key={i} membership={n.getMembership()} />
            })}
        </div>;
    }

    const handleAutofill = () => {
        clearBracket();
        let entries = currentDivision.bracket_type === BRACKET.Round_Robin ?
            createRoundRobinRounds(tournament, currentDivision) : createInitialRound(tournament, currentDivision);
        setBracketEntries(entries);
        entries = [...tournament.caches.bracketEntries || [], ...entries.map(e => {
            if (!e.getDivision) {
                let found = tournament.poolings.find(p => p.pool === e.division);
                if (found) {
                    found.name = found.pool;
                    found.id = found.pool;
                    e.getDivision = () => found;
                }
            }
            return e;
        })];
        tournament.caches.bracketEntries = entries;
        doReload();
        setIsBracketEntriesUpdate();
        localServer && setLocalTournament({...tournament});
        setHasChange(true);
    }

    return currentDivision && currentDivision.getRegistrations(true).length > 0?
        <ThemeProvider theme={Theme}>
            <div className="BracketWrapper">
                <div className={`header-wrapper`}>
                    <NonPlacements />
                    <div className="header">
                        <h2 className={BracketStyles.divisionTitle}>
                            {currentDivision && (currentDivision.name || currentDivision.id)}
                        </h2>
                        <AlertPane message={message} />
                        <div className={`buttons controls ${BracketStyles.buttons}`} style={Utils.isMobile() ? { flexWrap: 'wrap' } : {}} >
                            <button onClick={() => updateZoom(true)} className={classNames("button", "icon_button", BracketStyles.button)}>
                                <ZoomInIcon />
                            </button>
                            <button onClick={() => updateZoom()} className={classNames("button", "icon_button", BracketStyles.button)}>
                                <ZoomOutIcon />
                            </button>
                            {!isEditing ? isEditable &&
                                <button className={classNames("button", "small_button", BracketStyles.button)} 
                                    onClick={() => {
                                        setIsEditing(true);
                                        setIsDisabled(false);
                                    }}><EditIcon /> Edit</button> :
                                <>
                                    <button className="button alt_button" onClick={() => {
                                        setIsEditing(false);
                                        setIsDisabled(false);
                                    }} disabled={isDisabled}>Done
                                    </button>
                                    {!localServer && 
                                        <button className="button" onClick={() => saveBracketEntries()} disabled={isDisabled}>
                                            Save
                                        </button>}
                                </>}
                            {!isEditing && 
                                <button ref={exportButtonRef}
                                    className={classNames("button", "small_button", BracketStyles.button)}
                                    onClick={() => {
                                        setIsExporting(`Exporting bracket ${currentDivision.name || currentDivision.id} to PDF...`);
                                        generatePDF(bracketTypeRef, currentDivision, 
                                            () => {
                                                setIsExporting(false);
                                                exportComplete && exportComplete();
                                            });
                                    }}>
                                    <ExportPDFIcon />Export
                                </button>}
                            {hasChange && !localServer && <span className="warning">There are unsaved bracket entries</span>}
                        </div>
                    </div>
                </div>
                {isEditing && 
                    <div className={`buttons edit ${BracketStyles.buttons}`}>
                        <button onClick={handleAutofill} className="button" disabled={isDisabled}>Autofill
                        </button>
                        <button className="button alt_button" onClick={clearBracket} disabled={isDisabled}>Clear
                        </button>
                        <SelectFormInput width="100%"
                            name="bracket_type"
                            label="Change Bracket Type"
                            value={currentDivision && currentDivision.bracket_type}
                            options={BRACKET_TYPES}
                            onChange={v => {
                                currentDivision.bracket_type = v;
                                currentDivision.updated = true;
                                clearBracket();
                                localServer && setLocalTournament({...tournament});
                            }}
                        />
                        {currentDivision.bracket_type === BRACKET.Single_Elimination && currentDivision.getRegistrations(true).length > 3 &&
                            <FormControlLabel label="Has Third Place"
                                control={<Checkbox checked={currentDivision.is_third? true:false}
                                    onChange={e => {
                                        let is_third = e.target.checked;
                                        currentDivision.is_third = is_third;
                                        currentDivision.updated = true;
                                        localServer && setLocalTournament({...tournament});
                                        setIsThird(currentDivision.getRegistrations(true).length > 3 && is_third);
                                    }}
                                        />}/>}
                    </div>}
                {currentDivision && renderBracket(currentDivision.bracket_type)}
                <Busy message={isExporting}/>
            </div> 
        </ThemeProvider>: 'No available registrations for this bracket.';
        
});

function getRoundName({ r, totalRounds, bracketType, isConsolidation, isThird }) {
    let isRR = bracketType === BRACKET.Round_Robin;
    if (isRR) {
        return;
    }
    if (!isConsolidation && bracketType !== BRACKET.Double_Elimination_Loser_1ST) {
        if (r === totalRounds - 2) {
            return ROUND_NAME.Final;
        }
        if (r === totalRounds - 3) {
            return ROUND_NAME.Semi;
        }
        if (r === totalRounds - 3) {
            return ROUND_NAME.Quarter;
        }
    }
    if (r === totalRounds - 1) {
        if (isConsolidation) {
            if (bracketType === BRACKET.Double_Elimination_Loser_3RD) {
                return ROUND_NAME.ThirdPlace;
            } else if (bracketType === BRACKET.Double_Elimination_Loser_2ND) {
                return ROUND_NAME.SecondPlace;
            } else if (bracketType === BRACKET.Double_Elimination_Loser_1ST) {
                return ROUND_NAME.Winner;
            }
        }
        if (bracketType === BRACKET.Double_Elimination_Loser_1ST) {
            return ROUND_NAME.DrawWinner;
        }
        if (isThird) {
            return ROUND_NAME.ThirdPlace;
        }
        return ROUND_NAME.Winner;
    }
    if (isConsolidation && r === totalRounds - 2) {
        return `Round ${r + 1}`;
    }
}

const selectNodeId = (thisEl) => {
    let params = RequestUtils.getURLParameters();
    if (params.node) {
        let node = thisEl.querySelector(`.${params.node}`);
        if (node) {
            node.style.border = '2px solid blue';
            node.scrollIntoView({ behavior: 'auto', block: 'center' });
        }
    }
}

const BracketDoubleElimination = React.forwardRef(({
    zoom,
    registrations=[],
    currentDivision,
    bracketEntries,
    isEditing,
    isDisabled,
    hoveredCompetitor,
    setHoveredCompetitor,
    setBracketEntries,
    setCheckCRC,
}, ref) => {
    const divConsolidationRef = useRef();
    const [filter, setFilter] = useState(0);
    const [drawFilter, setDrawFilter] = useState({ value: 0 });
    const divRef = useRef();
    const roundFilterRef = useRef();
    const consolidationRef = useRef();
    const [isPrinting, setIsPrinting] = useState();
    useEffect(() => {
        bracketZoom(divConsolidationRef, zoom);
    }, [zoom, drawFilter.value]);

    useLayoutEffect(() => {
        let thisEl = divConsolidationRef.current;
        if (thisEl) {
            let columns = thisEl.children;
            const isWinnerRoundOfLoser3rd = (i) => currentDivision.getBracketType() === BRACKET.Double_Elimination_Loser_3RD && i === columns.length - 1;
            let bottoms = [0];
            for (let c = 1; c < columns.length; c++) {
                let isOddColumn = c % 2 > 0;
                let column = columns[c];
                let prevColumn = columns[c - 1];
                if (prevColumn.classList.contains('blank')) {
                    bottoms.push(parseFloat(column.style.bottom || 0));
                    continue;
                }
                let prevChildLastNodes = prevColumn.lastChild.children;
                let nextPrevColumnLastNode = prevChildLastNodes[prevChildLastNodes.length - 2];
                let lastPrevColumnNode = prevChildLastNodes[prevChildLastNodes.length - 1];
                if (!lastPrevColumnNode || !nextPrevColumnLastNode) {
                    continue;
                }
                let bottom = bottoms[c - 1];
                if (isOddColumn && !isWinnerRoundOfLoser3rd(c)) {
                    let mid = (lastPrevColumnNode.getBoundingClientRect().top - nextPrevColumnLastNode.getBoundingClientRect().bottom) / 2;
                    bottom += mid + lastPrevColumnNode.getBoundingClientRect().height / 2;
                }
                column.style.bottom = `${bottom}px`;
                bottoms.push(bottom);
            }
            let offset = bottoms.pop()
            thisEl.style.paddingTop = `${offset}px`;
            let highestTop;
            for (let c = 0; c < columns.length; c++) {
                let column = columns[c];
                if (column.classList.contains('blank')) {
                    continue;
                }
                let firstNode = column.firstChild.firstChild;
                if (!firstNode) {
                    continue;
                }
                let b = firstNode.querySelector('b');
                if (!b) {
                    continue;
                }
                let top = firstNode.getBoundingClientRect().top - b.getBoundingClientRect().height;
                if (!highestTop || top < highestTop) {
                    highestTop = top;
                }
            }
            offset = offset - (highestTop - thisEl.getBoundingClientRect().top); 
            thisEl.style.paddingTop = `${offset + 40}px`;
            renderJoins(thisEl.children, thisEl.classList.contains('bracket_consolidation') && 1, currentDivision);
            selectNodeId(thisEl);
        }
    }, [divConsolidationRef.current, currentDivision.id, filter, drawFilter.value, zoom]);

    useImperativeHandle(ref, () => ({
        divRef,
        setFilter,
        setDrawFilter,
        roundFilterRef,
        consolidationRef,
    }));

    let totalRounds = calculateTotalRounds(registrations.length, BRACKET.Double_Elimination_Loser_3RD);
    let isLoserThird = currentDivision.getBracketType() === BRACKET.Double_Elimination_Loser_3RD;
    if (isLoserThird) {
        totalRounds -= 2;
    } else {
        totalRounds -= 1;
    }
    const handleDrawFilterClick = fs => {
        fs = drawFilter.value != fs.value ? fs : {};
        setDrawFilter(fs);
    }
    return totalRounds <= 0? '' : 
        <div className="BracketDoubleElimation">
            <div className={BracketStyles.DrawFilter}>
                <FilterChips 
                    filters={[{ value: 1, label: 'Main Draw' }, { value: 2, label: 'Consolation Draw' }]}
                    activeFilters={[drawFilter]}
                    onClick={handleDrawFilterClick}
                />
            </div>
            {(!drawFilter.value || drawFilter.value === 1) && <div>
                <BracketSingleElimination ref={consolidationRef}
                    isDoubleElimination
                    zoom={zoom}
                    registrations={registrations}
                    currentDivision={currentDivision}
                    bracketEntries={bracketEntries.filter(e => !e.isLoser)}
                    isEditing={isEditing}
                    isDisabled={isDisabled}
                    hoveredCompetitor={hoveredCompetitor}
                    setHoveredCompetitor={setHoveredCompetitor}
                    setBracketEntries={(entries) => {
                        setBracketEntries([
                            ...bracketEntries.filter(e => e.isLoser),
                            ...entries])
                    }}
                    setCheckCRC={setCheckCRC}
                />
            </div>}
            {(!drawFilter.value || drawFilter.value === 2) && <div className={BracketStyles.Draw}>
                <h5>Consolation Draw</h5>
                <RoundFilter ref={roundFilterRef}
                    totalRounds={totalRounds}
                    isConsolidation={true}
                    currentDivision={currentDivision}
                    onFilter={(f, isPrint) => {
                        setFilter(f);
                        setIsPrinting(isPrint);
                    }} />
                <div ref={divRef} className={BracketStyles.bracket_main_wrapper}>
                    <h1 style={{ display: isPrinting ? '' : 'none' }} >
                        {`${currentDivision.name || currentDivision.id} - Consolidation Draw`}
                    </h1>
                    <div ref={divConsolidationRef} className={classNames('bracket_consolidation', BracketStyles.bracket_consolidation)}>
                        {Array(totalRounds).fill(0).map((_, r) => {
                            let entriesCount = calculateTotalEntriesByRound(Math.floor(r / 2), registrations.length, BRACKET.Double_Elimination_Loser_3RD);
                            if (isLoserThird && r === totalRounds - 1) {
                                entriesCount = 1;
                            }
                            return isFilter(filter, r) ? <Round key={r}
                                round={r}
                                mainBracketEntries={bracketEntries.filter(e => !e.isLoser)}
                                bracketEntries={bracketEntries.filter(e => e.isLoser)}
                                isEditing={isEditing}
                                isDisabled={isDisabled}
                                registrations={registrations}
                                hoveredCompetitor={hoveredCompetitor}
                                setHoveredCompetitor={setHoveredCompetitor}
                                setBracketEntries={(entries) => {
                                    setBracketEntries([
                                        ...bracketEntries.filter(e => !e.isLoser),
                                        ...entries
                                    ])
                                }}
                                currentDivision={currentDivision}
                                entriesCount={entriesCount}
                                roundName={getRoundName({ r, totalRounds, isConsolidation: true, bracketType: currentDivision.getBracketType() })}
                                isConsolidation={true}
                                filter={getEliminationGroupFilter({ filter, isConsolidation: true })}
                                isPrinting={isPrinting}
                                setCheckCRC={setCheckCRC}
                            /> : <div className="blank column" />;
                        })}
                    </div>
                </div>
            </div>}
        </div>;
});

const BracketRoundRobin = React.forwardRef(({
    zoom,
    registrations,
    currentDivision,
    bracketEntries,
    isEditing,
    isDisabled,
    hoveredCompetitor,
    setHoveredCompetitor,
    setBracketEntries,
    setCheckCRC,
}, ref) => {
    const [filter, setFilter] = useState({ round: 'summary' });
    const [summary, setSummary] = useState([]);
    const bracketPoolRef = useRef();
    const roundFilterRef = useRef();
    const divRef = useRef();
    const [isPrinting, setIsPrinting] = useState();
    useEffect(() => {
        bracketZoom(bracketPoolRef, zoom);
    }, [zoom]);
    const totalRounds = calculateTotalRounds(registrations.length, BRACKET.Round_Robin);
    const getSummary = () => {
        let winners = [];
        let losers = [];
        Array(totalRounds).fill(0).map((_, r) => {
            let entries = bracketEntries.filter(e => e.round === r).sort((a, b) => Utils.sorter(a, b, 'index'));
            for (let i = 0; i < entries.length; i++) {
                let entry = entries[i];
                if (entry.result) {
                    winners.push(entry.membership);
                    let loserIndex = i;
                    if (i % 2 === 0) {
                        loserIndex++;
                    }
                    losers.push(entries[loserIndex].membership);
                }
            }
        });
        let order = registrations.map(r => {
            r.wins = winners.filter(w => w === r.membership).length;
            r.losses = losers.filter(w => w === r.membership).length;
            r.points = r.wins - r.losses;
            return r;
        }).sort((a, b) => Utils.sorter(b, a, 'points'));
        setSummary(order);

    };
    useEffect(getSummary, [bracketEntries]);
    useImperativeHandle(ref, () => ({
        divRef,
        setFilter, filter,
        roundFilterRef,
    }));

    const createEntries = (round, index) => {
        let entries = bracketEntries.filter(e => e.round === round).sort((a, b) => Utils.sorter(a, b, 'index'));
        if (entries.length > 0) {
            if (index === 0) {
                entries = entries.map(e => {
                    let ecopy = Utils.copy(e);
                    ecopy.getMembership = e.getMembership;
                    ecopy.round = index;
                    return ecopy;
                });
            } else {
                entries = entries.map((e, i) => {
                    if (!e.result) {
                        return;
                    }

                    let ecopy = Utils.copy(e);
                    ecopy.getMembership = e.getMembership;
                    ecopy.round = index;
                    ecopy.index = Math.floor(e.index / 2);
                    return ecopy;
                }).filter(e => e);
            }
        }
        return entries;
    }

    const RoundRR = ({ }) => {
        let round = filter.round;
        let totalRounds = 2;
        return <div className={classNames(BracketStyles.bracket)} >
            {Array(totalRounds).fill(0).map((_, i) => {
                let entries = createEntries(round, i);
                let entriesCount = calculateTotalEntriesByRound(i, registrations.length, BRACKET.Round_Robin);
                if (i === 1) {
                    entriesCount = entriesCount / 2;
                }
                return <Round key={i}
                    filter={getRoundRobinGroupFilter(filter, registrations.length)}
                    roundName={i === 0 ? `Round ${round + 1}` : ROUND_NAME.Winner}
                    rrIndex={i}
                    round={i}
                    entriesCount={entriesCount}
                    bracketEntries={entries}
                    mainBracketEntries={bracketEntries.filter(b => b.round===round)}
                    isEditing={isEditing}
                    isDisabled={isDisabled}
                    registrations={registrations}
                    hoveredCompetitor={hoveredCompetitor}
                    setHoveredCompetitor={setHoveredCompetitor}
                    setBracketEntries={(entries) => {
                        entries.forEach(entry => {
                            let _e = bracketEntries.find(e => e.round === round && e.membership === entry.membership);
                            if (_e) {
                                _e.result = WINS_BY.WIN.value;
                            }
                        });
                        setBracketEntries([...bracketEntries]);
                    }}
                    currentDivision={currentDivision}
                    isPrinting={isPrinting}
                    setCheckCRC={setCheckCRC}
                />
            })}
        </div>
    }

    const getPrintTitle = () => {
        let g = filter.group;
        if (g) {
            g = parseInt(filter.group.split('_')[1]) + 1;
            g = ` - Group ${g}`;
        } else {
            g = '';
        }
        let r = filter.round;
        if (!isNaN(r)) {
            r = ` - Round ${r + 1}`;
        } else {
            r = '';
        }
        return `${currentDivision.name || currentDivision.id}${r}${g}`;
    }

    console.log('RR filter:', filter);
    return <div className="RoundRobin">
        <ThemeProvider theme={Theme}>
            <RoundFilter ref={roundFilterRef}
                currentDivision={currentDivision}
                initFilter={filter}
                totalRounds={totalRounds} isRR
                onFilter={(f, isPrint) => {
                    if (f.round === filter.round) {
                        f.round = 'summary';
                        f.group = undefined;
                    } 
                    setFilter(f);
                    setIsPrinting(isPrint);
                }}
            />
            <div ref={divRef} className={BracketStyles.bracket_main_wrapper}>
                <PrintTitle getPrintTitle={getPrintTitle} isPrinting={isPrinting} currentDivision={currentDivision} />
                {filter.round === 'summary' && <div style={{ padding: 40 }}>
                    <h6>Round Robin Summary</h6>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Competitor</TableCell>
                                    <TableCell align="right">Wins</TableCell>
                                    <TableCell align="right">Losses</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {summary.map((reg, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <UserChip membership={reg.getMembership()} />
                                        </TableCell>
                                        <TableCell align="right">{reg.wins}</TableCell>
                                        <TableCell align="right">{reg.losses}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>}

                <div ref={bracketPoolRef} className={BracketStyles.BracketRoundRobin}>
                    {filter
                        && filter.round !== 'summary'
                        && <RoundRR round={filter.round} />}
                </div>
            </div>
        </ThemeProvider>
    </div>
});

let GROUP_OF = 8;
const RoundFilter = React.forwardRef(({ initFilter = {value: 0, round: 0}, totalRounds, onFilter, isConsolidation, currentDivision }, ref) => {
    const isRR = currentDivision.getBracketType() === BRACKET.Round_Robin;
    const minRounds = 1;
    const [filter, setFilter] = useState(initFilter);
    const [groupFilter, setGroupFilter] = useState();
    useEffect(() => {
        currentDivision && setFilter(initFilter);
    }, [currentDivision]);
    
    React.useImperativeHandle(ref, () => ({
        getRoundFilters,
        handleClick,
        getRRGroupFilter
    }));
    const createGroups = (groups, r) => {
        return Array(groups).fill(0)
            .map((_, f) => ({ label: `Group R${r}-${f + 1}`, value: `group_${r}_${f}` }));
    }
    const getRoundFilters = () => {
        let roundFilters = Array(totalRounds).fill(0)
            .map((_, f) => {
                let roundName = getRoundName({ r: f, totalRounds, bracketType: currentDivision.getBracketType(), isConsolidation });
                return { label: roundName ? roundName : `Round ${f + 1}`, value: f };
            });
        if (!isRR) {
            let groupRound = 0 + totalRounds + Math.log2(GROUP_OF);
            let baseEntriesCount = calculateTotalEntriesByRound(0, currentDivision.getRegistrations(true).length, BRACKET.Single_Elimination);
            for (let r=0; r<=groupRound; r++) {
                if (isConsolidation) {
                    baseEntriesCount /= 2;
                }
                if (baseEntriesCount > GROUP_OF) {
                    let groupCount = Math.floor(baseEntriesCount / GROUP_OF);
                    let groups = createGroups(groupCount, r);
                    roundFilters = [...roundFilters, ...groups];
                }
                baseEntriesCount /= 2;
            }
        }
        return roundFilters;
    }

    const getRRGroupFilter = () => {
        if (isRR) {
            let groups = Math.ceil(currentDivision.getRegistrations(true).length / GROUP_OF);
            if (groups > 1) {
                return createGroups(groups);
            }
        }
    }
    let rounds = getRoundFilters();
    let groupRRs = getRRGroupFilter();
    const handleClick = (f, isPrint) => {
        if (isRR) {
            if (f.group) {
                f.round = f.round || filter.value || 0;
            } else if (f.value === filter.value) {
                f.round = 'summary';
            } else {
                f = { round: f.value, group: groupFilter && groupFilter.value };
            }
            setFilter({ value: f.round });
            if (f.group) {
                setGroupFilter({ value: f.group });
            }
            return onFilter(f, isPrint);
        }

        f = filter.value != f.value ? f : {};
        if (!isConsolidation || !f.value) {
            setFilter(f);
            return onFilter(f.value || -1, isPrint);
        }
        setFilter({});
        return setTimeout(() => {
            setFilter(f);
            onFilter(f.value, isPrint);
        }, 200);
    }

    return totalRounds > minRounds && filter &&
        <div className={BracketStyles.RoundFilter}>
            <FilterChips
                filters={rounds}
                activeFilters={[filter]}
                onClick={handleClick}
            />
            {filter.value > -1 && 
                <div style={{ marginTop: 20 }}>
                    <FilterChips
                        filters={groupRRs}
                        activeFilters={[groupFilter]}
                        onClick={(g) => {
                            g = g || (groupFilter && groupFilter.value != g.value) ? g : {};
                            setGroupFilter(g);
                            return setTimeout(() => {
                                onFilter({ round: filter.value, group: g.value });
                            }, 200);
                        }}
                    />
                </div>}
        </div>;
});

function bracketZoom(divRef, zoom) {
    if (divRef && divRef.current) {
        divRef.current.style.zoom = zoom;
    }
}

function isFilter(filter, round) {
    if (!isNaN(filter)) {
        return filter <= round;
    }
    return true;
}

const PrintTitle = ({getPrintTitle, currentDivision, isPrinting}) => {
    return currentDivision.getTournament? <div className="bracket-title" style={{ display: isPrinting ? '' : 'none' }}>
        <h1>{getPrintTitle()}</h1>
        <div className="tournament-name">{currentDivision.getTournament().name} - {Utils.formatDate(currentDivision.getTournament().dates.start_date)}</div>
    </div> : '';
}

const BracketSingleElimination = React.forwardRef(({
    isDoubleElimination,
    zoom,
    registrations,
    currentDivision, 
    isThird,
    bracketEntries,
    isEditing,
    isDisabled,
    hoveredCompetitor,
    setHoveredCompetitor,
    setBracketEntries,
    tabsRef,
    setCheckCRC,
}, ref) => {
    const [filter, setFilter] = useState(0);
    const totalRounds = calculateTotalRounds(registrations.length) + 1;
    const divRef = useRef();
    const bracketThirdRef = useRef();
    const roundFilterRef = useRef();
    const [isPrinting, setIsPrinting] = useState();
    useEffect(() => {
        bracketZoom(divRef, zoom);
        bracketThirdRef && bracketThirdRef.current && bracketZoom(bracketThirdRef.current.thisRef, zoom);
    }, [zoom]);
    useEffect(() => {
        isThird && bracketZoom(bracketThirdRef.current.thisRef, zoom);
    }, [isThird]);

    useImperativeHandle(ref, () => ({
        divRef,
        setFilter,
        roundFilterRef,
        bracketThirdRef,
    }));

    const getPrintTitle = () => {
        let ddDraw = isDoubleElimination ? ' - Main Draw' : '';
        let g = filter.toString().startsWith('group_');
        if (g) {
            let gr = filter.toString().split('group_')[1].split('_');
            let r = parseInt(gr[0]);
            g = parseInt(gr[1]);
            g = ` - Group R${r+1}-${g + 1}`;
        } else {
            g = '';
        }
        return `${currentDivision.name || currentDivision.id}${ddDraw}${g}`;
    }
    return <div className={classNames("BracketSingleElimination")}>
        <div className={ BracketStyles.Draw}>
            {isDoubleElimination && <h5>Main Draw</h5>}
            <RoundFilter ref={roundFilterRef}
                totalRounds={totalRounds}
                currentDivision={currentDivision}
                onFilter={(f, isPrint) => {
                    setFilter(f);
                    setIsPrinting(isPrint);
                }}
            />
            <div ref={divRef} className={BracketStyles.bracket_main_wrapper}>
                <PrintTitle getPrintTitle={getPrintTitle} isPrinting={isPrinting} currentDivision={currentDivision} />
                <div className={classNames('bracket_main', BracketStyles.bracket)} >
                    {Array(totalRounds).fill(0).map((_, r) => {
                        return isFilter(filter, r) ?
                            <Round key={r}
                                tabsRef={tabsRef}
                                filter={getEliminationGroupFilter({ filter })}
                                round={r}
                                bracketEntries={bracketEntries.filter(b => !b.isThird)}
                                isEditing={isEditing}
                                isDisabled={isDisabled}
                                registrations={registrations}
                                hoveredCompetitor={hoveredCompetitor}
                                setHoveredCompetitor={setHoveredCompetitor}
                                setBracketEntries={(entries) => {
                                    setBracketEntries(entries);
                                    bracketThirdRef && bracketThirdRef.current && bracketThirdRef.current.refresh();
                                }}
                                currentDivision={currentDivision}
                                roundName={getRoundName({ r, totalRounds, bracketType: currentDivision.getBracketType() })}
                                isPrinting={isPrinting}
                                setCheckCRC={setCheckCRC}
                            /> : ''
                    }
                    )}
                </div>
            </div>
        </div>
        {isThird && 
            <ThirdPlace 
                semiRound={totalRounds-3}
                isPrinting={isPrinting}
                getPrintTitle={getPrintTitle}
                tabsRef={tabsRef}
                isEditing={isEditing}
                isDisabled={isDisabled}
                registrations={registrations}
                hoveredCompetitor={hoveredCompetitor}
                setHoveredCompetitor={setHoveredCompetitor}
                currentDivision={currentDivision}
                filter={filter}
                ref={bracketThirdRef}
                bracketEntries={bracketEntries}
                setBracketEntries={setBracketEntries} 
                setCheckCRC={setCheckCRC}
                />}
                
    </div>;
});

const ThirdPlace = React.forwardRef(({
    semiRound,
    isPrinting, 
    getPrintTitle, 
    tabsRef, 
    isEditing, 
    isDisabled, 
    registrations, 
    hoveredCompetitor, 
    setHoveredCompetitor,
    currentDivision,
    filter,
    bracketEntries,
    setBracketEntries,
    setCheckCRC,
    }, ref) => {
    
    const [refresh, setRefresh] = useState(false);
    const isBracketEntriesUpdate = useStore(state => state.isBracketEntriesUpdate);
    const divRef = useRef();
    useImperativeHandle(ref, () => ({
        refresh: () => setRefresh(!refresh),
        divRef
    }));

    useEffect(() => setRefresh(!refresh), [isBracketEntriesUpdate]);
    const totalRounds = 2;
    let entries = Utils.uniqArray(bracketEntries.filter(b => b.round === semiRound && !b.result).map(e => e.membership));
    let regs = entries.map(e => {
            return registrations.find(r => {
                let isDiv = [r.division, ...(r.pool||'').split('|')].includes(currentDivision.id);
                return isDiv && e === r.membership;
            });
        });
    if (regs.length !== 2) {
        regs = [];
    }
    return <div ref={divRef} className={`ThirdPlace ${BracketStyles.Draw} ${isPrinting? 'export':''}`}>
        <PrintTitle getPrintTitle={getPrintTitle} currentDivision={currentDivision} isPrinting={isPrinting}/>
        <h4>Third Place</h4>
        <div className={classNames('bracket_main', BracketStyles.bracket)} >
            {Array(totalRounds).fill(0).map((_, r) => {
                return <Round key={r}
                        tabsRef={tabsRef}
                        filter={getEliminationGroupFilter({ filter })}
                        round={r}
                        bracketEntries={bracketEntries.filter(b => b.isThird)}
                        isEditing={isEditing}
                        isDisabled={isDisabled}
                        registrations={regs}
                        hoveredCompetitor={hoveredCompetitor}
                        setHoveredCompetitor={setHoveredCompetitor}
                        setBracketEntries={entries => {
                            setBracketEntries([...bracketEntries.filter(b => !b.isThird), ...entries]);
                        }}
                        currentDivision={currentDivision}
                        roundName={getRoundName({ r, totalRounds, bracketType: BRACKET.Single_Elimination, isThird: true })}
                        isPrinting={isPrinting}
                        isThird={true}
                        setCheckCRC={setCheckCRC}
                    /> 
            })}
        </div>
    </div>
});

function RoundHeader({ round, name, index, isConsolidation, isThird }) {
    const isSkip = () => {
        let r = round || 0;
        if (isConsolidation) {
            if (r % 2 !== 0) {
                r--;
            }
        }
        return index * Math.pow(2, r) % GROUP_OF !== 0;
    }
    if (isSkip()) {
        return '';
    }

    name = name ? name : (isNaN(round) ? (isThird? ROUND_NAME.ThirdPlace:ROUND_NAME.Winner) : `Round ${round + 1}`);
    return <div className={classNames('round_header', CompetitorStyles.round_header)}>
        <b>{name===ROUND_NAME.Final && isThird? '':name}</b>
    </div>;
}

const ROUND_NAME = {
    Winner: 'Winner',
    Final: 'Final',
    Semi: 'Semi',
    Quarter: 'Quarter',
    ThirdPlace: '3rd Place',
    SecondPlace: '2nd Place',
    DrawWinner: 'Draw Winner'
}
function RenderNode({
    round,
    rrIndex,
    allEntries,
    index,
    registrations,
    isDisabled,
    currentDivision,
    setBracketEntries,
    hoveredCompetitor,
    isEditing,
    bracketEntries, mainBracketEntries,
    setHoveredCompetitor,
    roundName,
    isConsolidation,
    tabsRef,
    filter,
    isPrinting,
    isThird,
    setCheckCRC,
}) {
    const history = useHistory();
    const entry = allEntries.find(e => e?.index === index);
    const getMembership = (v) => {
        let found = registrations.find(r => r.membership === v);
        return found && found.getMembership();
    }
    const renderRoundNumber = () => {
        if (currentDivision.bracket_type === BRACKET.Round_Robin) {
            return index === 0 && rrIndex === 0 ? round : undefined;
        }
        return round
    }
    const getOpponentEntry = (membership) => {
        if (round === 0) {
            return;
        }
        let prevRound = round - 1;
        let prevIndex = Math.floor(index / 2);
        return bracketEntries.find(e => {
            return e.round === prevRound && [prevIndex, prevIndex + 1].includes(e.index) && e.membership !== membership;
        });
    }

    const render = () => {
        const getPrevFromBracketEntries = membership => bracketEntries
            .filter(r => {
                const getIndex = () => {
                    if (isConsolidation && round%2 !== 0 && !Object.values(ROUND_NAME).includes(roundName)) {
                        return index - 1;
                    }
                    return index;
                }
                return r.round === round - 1 && Math.floor(r.index / 2) === getIndex();
            })
            .find(be => be.membership === membership);
        let node_id = createBracketNodeId({ round: rrIndex || round, index, isConsolidation, isThird});
        if (isEditing) {
            if (!entry) {
                return <EmptyCompetitor key={index}
                    currentDivision={currentDivision}
                    isThird={isThird}
                    isConsolidation={isConsolidation}
                    round={renderRoundNumber()}
                    roundName={roundName}
                    mainBracketEntries={mainBracketEntries}
                    bracketEntries={bracketEntries}
                    index={index}
                    registrations={registrations}
                    isDisabled={isDisabled}
                    onChange={entry => {
                        entry.isThird = isThird;
                        entry.round = round;
                        entry.index = index;
                        entry.isLoser = isConsolidation;
                        entry.tournament = currentDivision.tournament;
                        entry.division = currentDivision.id;
                        entry.getMembership = () => getMembership(entry.membership);
                        entry.getDivision = () => currentDivision;
                        let prevBracketEntry = getPrevFromBracketEntries(entry.membership);
                        if (prevBracketEntry) {
                            prevBracketEntry.result = WINS_BY.WIN.value;
                        }
                        entry.crc = 'new entry';
                        bracketEntries.push(entry);
                        setBracketEntries(bracketEntries);
                        setCheckCRC();
                    }}
                />
            }
            return <EditingCompetitor key={index}
                currentDivision={currentDivision}
                isThird={isThird}
                isConsolidation={isConsolidation}
                round={renderRoundNumber()}
                roundName={roundName}
                mainBracketEntries={mainBracketEntries}
                bracketEntries={bracketEntries}
                index={index}
                registrations={registrations}
                entryId={entry?.id}
                defaultValue={entry.membership}
                defaultResult={entry.result}
                isDisabled={isDisabled}
                onChange={v => {
                    delete entry.place;
                    let oppEntry = getOpponentEntry(v);
                    if (oppEntry) {
                        let prevBracketEntry = getPrevFromBracketEntries(entry.membership);
                        if (prevBracketEntry) {
                            prevBracketEntry.result = WINS_BY.WIN.value;
                            let bOpp = prevBracketEntry.getOpponent && prevBracketEntry.getOpponent();
                            if (bOpp){
                                bOpp.opp.result = '';
                            }
                        }
                        if (roundName === ROUND_NAME.Winner) {
                            entry.place = 1;
                            oppEntry.place = 2;
                        } else if (roundName === ROUND_NAME.ThirdPlace) {
                            entry.place = 3;
                            oppEntry.place = 4;
                        } else {
                            delete oppEntry.place;
                        }
                    }
                    entry.isThird = isThird;
                    entry.isLoser = isConsolidation;
                    entry.membership = v;
                    entry.getMembership = () => getMembership(v);
                    setBracketEntries(bracketEntries);
                    setCheckCRC();
                }}
                onWinBy={v => {
                    let prevBracketEntry = getPrevFromBracketEntries(entry.membership);
                    if (prevBracketEntry) {
                        prevBracketEntry.result = v;
                        let bOpp = prevBracketEntry.getOpponent && prevBracketEntry.getOpponent();
                        if (bOpp){
                            bOpp.opp.result = '';
                        }
                        setBracketEntries(bracketEntries);
                    }
                }}
            />;
        }
        return <Competitor key={index}
            isThird={isThird}
            index={index}
            round={renderRoundNumber()}
            roundName={roundName}
            bracketEntries={bracketEntries}
            node_id={node_id}
            entry={entry}
            hoveredCompetitor={hoveredCompetitor}
            setHoveredCompetitor={setHoveredCompetitor}
            isConsolidation={isConsolidation}
            isPrinting={isPrinting}
        />
    }

    const isScheduled = () => {
        if (isEditing || round === 0 || rrIndex === 0 || (isConsolidation && round % 2 !== 0 && index % 2 === 0)) {
            return;
        }
        if (rrIndex === 1) {
            return true;
        }
        let isOddRound = round % 2 !== 0;
        let prevIndex = index * 2;
        if (isConsolidation && isOddRound) {
            prevIndex = (index - 1) * 2;
        }
        let prevEntries = bracketEntries.filter(b => b.round === round - 1 && [prevIndex, prevIndex + 1].includes(b.index));
        let prevLength = prevEntries.length;
        if ([prevLength>0 && prevEntries[0].membership, prevLength>1 && prevEntries[1].membership].includes(BYE)) {
            return;
        }
        let bracketId1 = prevLength > 0 && TournamentModel.getBracketId(prevEntries[0]);
        let bracketId2 = prevLength > 1 && TournamentModel.getBracketId(prevEntries[1]);
        return bracketId1 || bracketId2;
    }
    let scheduled = isScheduled();
    let cName = classNames(
        `competitor_wrapper ${isPrinting? 'export':''} group_of_${GROUP_OF}`,
        BracketStyles.competitor_wrapper,
        `node_R${round}_I${index}_C${isConsolidation ? 1 : 0}`);
    let isFilter = () => {
        if (!isNaN(filter)) {
            return false;
        }
        return filter && !filter.includes(createBracketNodeId({round, index, isConsolidation}));
    }
    const isJoinVisible = () => {
        if (isConsolidation && round%2!==0 && index%2===0 && !Object.values(ROUND_NAME).includes(roundName)) {
            return 'none';
        }
        return undefined;
    }
    const getJoinerId = () => [isConsolidation, round, index].join('_')
    return !isFilter() && 
        <div className={`${cName}`}>
            <div className={classNames('border_center', BracketStyles.border_center)} style={{border: isJoinVisible()}} id={getJoinerId()}>
                <div className={classNames('border_center_top', BracketStyles.border_center_top)} style={{border: isJoinVisible()}} id={getJoinerId()}/>
                <div className="border_center_bottom" style={{ position: 'relative' }}>
                    {tabsRef && scheduled && !isPrinting &&
                        <Link>
                            <ScheduleIcon style={{ position: 'absolute', zIndex: 10, left: -16, cursor: 'pointer' }}
                                onClick={() => {
                                    history.push(`/tournaments/${currentDivision.tournament}/edit?tab=schedules&id=${scheduled}`);
                                    tabsRef.current.setActiveTabByName('schedules');
                                }}
                            />
                        </Link>}
                </div>
            </div>
            <div className={classNames('border_join', BracketStyles.border_join)} style={{border: isJoinVisible()}} id={getJoinerId()}/>
            {render()}
        </div>;
}
export const createBracketNodeId = ({ round, index, isConsolidation, isThird=false }) => `node_R${round}_I${index}_C${isConsolidation ? 1:0}_T${isThird? 1:0}`;
const renderJoins = (columns, isConsolidation, currentDivision) => {
    if (!columns) {
        return;
    }
    const hideJoiner = (node, unHide) => {
        let join = node.querySelector('.border_join');
        let center = node.querySelector('.border_center .border_center_top');
        if (!join) {
            return;
        }
        if (!unHide) {
            join.style.visibility = 'hidden';
            center.style.visibility = 'hidden';
        } else {
            join.style.visibility = 'visible';
            center.style.visibility = 'visible';
        }
    }
    for (let ci = 1; ci < columns.length; ci++) {
        let column = columns[ci];
        let prevColumn = columns[ci - 1];
        if (prevColumn.classList.contains('blank')) {
            if (column.firstChild) {
                for (let n = 0; n < column.firstChild.children.length; n++) {
                    hideJoiner(column.firstChild.children[n]);
                }
            }
            continue;
        }
        try {
            let nodes = prevColumn.firstChild.children;
            if (nodes.length === 0) {
                continue;
            }
            let first = nodes[0];
            let second = nodes[1];
            let nodeHeight = first.getBoundingClientRect().height / 2;
            let firstMid = first.getBoundingClientRect().top + nodeHeight;
            let secondMid = second.getBoundingClientRect().top + nodeHeight;
            let height = secondMid - firstMid;
            const getCalculateOffsetNode = () => {
                let ciChildren = column.firstChild.children;
                for (let ci = 0; ci < ciChildren.length; ci++) {
                    if (ciChildren[ci].getBoundingClientRect().top > first.getBoundingClientRect().top) {
                        return ciChildren[ci];
                    }
                }
                return column.firstChild.firstChild;
            }
            let joinOffset = getCalculateOffsetNode();
            if (!joinOffset) {
                continue;
            }
            joinOffset = joinOffset.getBoundingClientRect().top - firstMid;
            for (let si = 0; si < column.children.length; si++) {
                let splitColumn = column.children[si];
                for (let ni = 0; ni < splitColumn.children.length; ni++) {
                    let node = splitColumn.children[ni];
                    let join = node.querySelector('.border_join');
                    if (join) {
                        let isLoser3rdFinalRound = currentDivision && currentDivision.getBracketType() === BRACKET.Double_Elimination_Loser_3RD && ci === columns.length - 1;
                        if (isConsolidation === 1 && ci % 2 > 0 && ni % 2 === 0 && !isLoser3rdFinalRound) {
                            // hideJoiner(node);
                        } else {
                            hideJoiner(node, true);
                            join.style.height = `${height}px`;
                            join.style.marginTop = `-${joinOffset}px`;
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
}

const getRoundRobinGroupFilter = (filter, regCount) => {
    if (!filter.group) {
        return [];
    }
    let groupFilters = [];
    let group = parseInt(filter.group.split('_')[1]);
    for (let round = 0; round <= 1; round++) {
        let groupOf = Math.floor(GROUP_OF / Math.pow(2, round))
        let startIndex = group * groupOf;
        let endIndex = startIndex + groupOf;
        for (let index = startIndex; index < endIndex && index < regCount; index++) {
            groupFilters.push(createBracketNodeId({ round, index }));
        }
    }
    return groupFilters;
}

const getEliminationGroupFilter = ({ filter, isConsolidation }) => {
    if (!filter || !filter.toString().startsWith('group_')) {
        return filter;
    }

    let group = filter.split('_');
    if (group.length !== 3) {
        return [];
    }
    let round = parseInt(group[1]);
    group = parseInt(group[2]);
    if (isNaN(group)) {
        return [];
    }
    let startIndex = group * GROUP_OF;
    let endIndex = startIndex + GROUP_OF;
    let groupFilter = [];
    const createFilter = () => {
        let rounds = round + calculateTotalRounds(GROUP_OF);
        for (let r = round; r < rounds; r++) {
            for (let index = startIndex; index < endIndex; index++) {
                groupFilter.push(createBracketNodeId({ round: r, index, isConsolidation }));
            }
            startIndex = Math.floor(startIndex / 2);
            endIndex = Math.floor(endIndex / 2);
        }
        return groupFilter;
    }

    const createConslidationFilter = () => {
        for (let round = 0; round <= 4; round++) {
            for (let index = startIndex; index < endIndex; index++) {
                groupFilter.push(createBracketNodeId({ round, index, isConsolidation }));
            }
            if (round % 2 !== 0) {
                startIndex /= 2;
                endIndex /= 2;
            }
        }
        return groupFilter;
    }

    if (!isConsolidation) {
        return createFilter();
    }
    return createConslidationFilter();
}

function RenderRound({
    rrIndex,
    round,
    allEntries,
    registrations,
    isDisabled,
    currentDivision,
    setBracketEntries,
    hoveredCompetitor,
    isEditing,
    bracketEntries, mainBracketEntries,
    setHoveredCompetitor,
    roundName,
    thisRef,
    isConsolidation,
    tabsRef,
    filter,
    isPrinting,
    isThird,
    setCheckCRC,
}) {
    const renderCompetitor = (i) => {
        return <RenderNode key={i}
            rrIndex={rrIndex}
            round={round}
            index={i}
            registrations={registrations}
            isDisabled={isDisabled}
            currentDivision={currentDivision}
            setBracketEntries={setBracketEntries}
            hoveredCompetitor={hoveredCompetitor}
            isEditing={isEditing}
            allEntries={allEntries}
            mainBracketEntries={mainBracketEntries}
            bracketEntries={bracketEntries}
            setHoveredCompetitor={setHoveredCompetitor}
            roundName={roundName}
            isConsolidation={isConsolidation}
            tabsRef={tabsRef} 
            filter={filter}
            isPrinting={isPrinting}
            isThird={isThird}
            setCheckCRC={setCheckCRC}
        />
    }

    return <div key={`round_${round}`} className={classNames('column', BracketStyles.column)} ref={thisRef}>
        {allEntries.length > 1 ?
            <div className={BracketStyles.topHalf}>
                {allEntries.map((_, i) => {
                    return renderCompetitor(i)
                })}
            </div>
            : <div className={BracketStyles.winner}>
                {renderCompetitor(0)}
            </div>}
    </div>
}

function Round({
    round,
    rrIndex,
    mainBracketEntries,
    bracketEntries,
    isEditing,
    isDisabled,
    registrations,
    hoveredCompetitor,
    setHoveredCompetitor,
    setBracketEntries,
    currentDivision,
    entriesCount,
    roundName,
    isConsolidation,
    filter,
    tabsRef,
    isPrinting,
    isThird,
    setCheckCRC,
}) {
    const thisRef = useRef(null);
    useEffect(() => {
        renderJoins(thisRef.current.parentElement.children);
    }, [thisRef.current && thisRef.current.getClientRects()]); 
    
    useLayoutEffect(() => {
        thisRef.current && renderJoins(thisRef.current.parentElement.children);
        thisRef.current && selectNodeId(thisRef.current);
    }, [thisRef.current, currentDivision.id, filter]);

    const numberOfEntries = entriesCount || calculateTotalEntriesByRound(round, registrations.length);
    const allEntries = bracketEntries.filter(entry => entry.round === round);
    for (let i = allEntries.length; i < numberOfEntries; i++) {
        allEntries.push(null);
    }
    return <RenderRound
        tabsRef={tabsRef} 
        rrIndex={rrIndex}
        round={round}
        allEntries={allEntries}
        mainBracketEntries={mainBracketEntries}
        registrations={registrations}
        isDisabled={isDisabled}
        currentDivision={currentDivision}
        setBracketEntries={setBracketEntries}
        hoveredCompetitor={hoveredCompetitor}
        isEditing={isEditing}
        bracketEntries={bracketEntries}
        setHoveredCompetitor={setHoveredCompetitor}
        roundName={roundName}
        thisRef={thisRef}
        isConsolidation={isConsolidation}
        filter={filter}
        isPrinting={isPrinting}
        isThird={isThird}
        setCheckCRC={setCheckCRC}
    />
}

const getWinBy = (bracketEntries, round, index, membershiId, isConsolidation, roundName) => {
    let preEntries = getPrevBracketEntries(bracketEntries, round, isConsolidation&&round%2!==0 && !Object.values(ROUND_NAME).includes(roundName)? index-1:index);
    let found = preEntries.find(e => e.membership===membershiId);
    if (found && found.result) {
        return WINS_BY[found.result].label
    }
    return '';
}

function Competitor({
    entry,
    hoveredCompetitor,
    setHoveredCompetitor,
    round,
    roundName,
    node_id,
    index,
    isConsolidation, isThird,
    isPrinting,
    bracketEntries
}) {
    const [isHighLite, setIsHighLite] = useState(false);
    const divRef = useRef();
    useEffect(() => {
        let params = RequestUtils.getURLParameters();
        const doScrollToView = () => {
            if (params.highlite.split('|')[0] === entry.membership) { 
                    divRef.current.scrollIntoView({
                        behavior: 'smooth', 
                        block: 'center',     
                    });
                }
        }
        if (entry && (params.highlite||'').split('|').includes(entry.membership)){
            if (!params.round){
                doScrollToView();
                setIsHighLite('highlite');
            }else if (parseInt(params.round) === round) {
                doScrollToView();
                setIsHighLite('highlite');
            }else {
                setIsHighLite('');
            }
        }
    }, [entry]);

    const getName = () => {
        if (!entry || !entry.getMembership || !entry.membership || entry.membership === 'tbd') {
            return 'TBD';
        }
        if (entry.membership === BYE) {
            return 'Bye';
        }
        const membership = entry.getMembership();
        return `${membership?.first_name} ${membership?.last_name}`;
    }
    const RenderFlag = () => {
        if (!isPrinting && entry.getMembership && entry.getMembership()?.country) {
            return <FlagIcon className={CompetitorStyles.flagIcon} country={entry?.getMembership()?.country.toLowerCase()} />
        }
        return '';
    }
    const getGym = () => {
        if (!entry || !entry.getMembership) {
            return;
        }
        const membership = entry.getMembership();
        return membership?.getGym()?.name;
    }
    if ([ROUND_NAME.Winner, ROUND_NAME.ThirdPlace].includes(roundName) && entry && !['tbd', 'bye'].includes(entry.membership)) {
        let isWinner = ROUND_NAME.Winner === roundName;
        entry.place = isWinner? 1:3;
        let second = bracketEntries.find(b => {
            return b.round === entry.round - 1 && b.membership !== entry.membership;
        });
        if (second) {
            second.place = isWinner? 2:4;
        }
    }
    
    return <div node_id={node_id} ref={divRef}
        index={entry?.index}
        key={entry?.id || 0}
        entry-id={entry?.id}
        className={classNames(
            `competitor ${isHighLite}`,
            CompetitorStyles.competitor,
            { [CompetitorStyles.hovered]: hoveredCompetitor === entry?.membership })}
        onMouseOver={() => {
            if (entry?.id) {
                setHoveredCompetitor(entry?.membership);
            }
        }}
        onMouseOut={() => {
            setHoveredCompetitor(null);
        }}
    >
        <RoundHeader round={round} name={roundName} index={index} isConsolidation={isConsolidation} isThird={isThird}/>
        {entry ? <div className={`competitor-info`}>
            <div className={CompetitorStyles.competitorName}>
                <div className="competitor-name">
                    <Link color="inherit" href={`/users/${entry.membership}`}>
                        {getName()}
                    </Link>
                </div>
                <RenderFlag />
                <div className={CompetitorStyles.placement}>{entry.place? TournamentModel.renderPlacement(entry.place) : ''}</div>
            </div>
            <div className={`${CompetitorStyles.competitorTeam} gym-name`}>{getGym()}</div>
            {round>0 && <b className="win-by">{getWinBy(bracketEntries, round, index, entry.membership, isConsolidation, roundName)}</b>}
        </div> : <div className={CompetitorStyles.competitorName}>TBD</div>}
    </div>
}

export const getPrevBracketEntries = (bracketEntries, round, index) => {
    return bracketEntries.filter(r => r.round === round - 1 && Math.floor(r.index / 2) === index);
}

const getCompetitorOptions = ({ registrations, round, index, bracketEntries, isConsolidation, mainBracketEntries, roundName, currentDivision }) => {
    let lastRoundComps = [];
    let isRoundOdd = round % 2 !== 0;
    let isIndexOdd = index % 2 !== 0;

    const getPrevFromMain = () => {
        const getMainIndex = (index) => {
            if (round > 0){
                return Math.floor(index/2);
            }
            return index;
        };

        const getRound = () => {
            if ([BRACKET.Double_Elimination_Loser_2ND, BRACKET.Double_Elimination_Loser_3RD].includes(currentDivision.getBracketType())){
                let finalRound = calculateTotalRounds(registrations.length) - 1;
                if (round > finalRound){
                    return finalRound;
                }
            }
            return round;
        }
        let founds = mainBracketEntries.filter(r => {
            return r.round === getRound() 
                && Math.floor(r.index / 2) === (isConsolidation? getMainIndex(index):index);
        }).map(r => r.membership);
        let winner = mainBracketEntries.find(r => r.round === getRound() + 1 && founds.includes(r.membership));
        return winner ? founds.filter(f => f !== winner.membership) : founds;
    }
    const getPrevFromBracketEntries = () => getPrevBracketEntries(bracketEntries, round, index).map(r => r.membership);

    if (round === undefined) {
        lastRoundComps = mainBracketEntries.filter(r => Math.floor(r.index / 2) === index).map(r => r.membership);
    }else if (round > 0) {
        if (!Object.values(ROUND_NAME).includes(roundName) && isConsolidation) {
            if (isRoundOdd && !isIndexOdd) {
                lastRoundComps = getPrevFromMain();
            } else if (isConsolidation && isRoundOdd) {
                lastRoundComps = bracketEntries.filter(r => r.round === round - 1 && Math.floor(r.index / 2) === (index - 1)).map(r => r.membership);
            } else {
                lastRoundComps = getPrevFromBracketEntries();
            }
        } else {
            lastRoundComps = getPrevFromBracketEntries();
        }
    } else if (isConsolidation && round === 0) {
        lastRoundComps = getPrevFromMain();
    }

    const createOption = (r, color) => {
        if (r && r.id && r.membership) {
            return {
                value: r.membership,
                label: <span className={color? CompetitorStyles.competitorSelectable: ''}>
                    {r.first_name} {r.last_name} [<b>{r.getMembership() && r.getMembership().getGym() && r.getMembership().getGym().name}</b>]
                </span>
            };
        }
    }
    let suggests = registrations.filter(r => r && lastRoundComps.includes(r.membership)).map(r => {
        return createOption(r, true);
    }).filter(o => o);
    let nonSuggests = registrations.filter(r => r && !lastRoundComps.includes(r.membership)).map(r => {
        return createOption(r);
    }).filter(o => o);
    
    let options = [...suggests, ...nonSuggests];
    return [
        { value: 'tbd', label: 'TBD' },
        { value: 'bye', label: 'Bye' },
        ...options
    ];
}
function EditingCompetitor({
    currentDivision,
    bracketEntries, mainBracketEntries,
    index,
    registrations,
    defaultValue='',
    defaultResult='',
    isDisabled,
    onChange, onWinBy,
    round,
    roundName,
    isConsolidation, isThird
}) {
    const setIsBracketEntriesUpdate = useStore(state => state.setIsBracketEntriesUpdate);
    const isBracketEntriesUpdate = useStore(state => state.isBracketEntriesUpdate);
    const [refresh, setRefresh] = useState(false);
    const [isWinBy, setIsWinBy] = useState(false);
    const [result, setResult] = useState();
    useEffect(() => {
        setResult(defaultResult)
    }, [defaultResult]);
    useEffect(() => {
        setRefresh(!refresh);
    }, [bracketEntries, mainBracketEntries, isBracketEntriesUpdate]);

    let winBy = getWinBy(bracketEntries, round, index, defaultValue, isConsolidation, roundName);
    return <div className={classNames('EditingCompetitor', CompetitorStyles.competitor, { [CompetitorStyles.hovered]: false }, CompetitorStyles.edit)}>
        <ThemeProvider theme={Theme}>
            <RoundHeader round={round} name={roundName} index={index} isThird={isThird}/>
            {!isWinBy && <SelectFormInput width="100%"
                disabled={isDisabled}
                name="competitor"
                label={<div className="select-competitor">
                        Select a competitor 
                        {round>0 && 
                            <b className="win-by" onClick={() => setIsWinBy(true)}>
                                ({winBy})
                            </b>}</div>}
                value={defaultValue}
                options={getCompetitorOptions({ registrations, round, index, bracketEntries, isConsolidation, mainBracketEntries, roundName, currentDivision })}
                onChange={v => {
                    onChange(v);
                    setIsBracketEntriesUpdate();
                    setRefresh(!refresh);
                }}
            />}
            {isWinBy && <SelectFormInput width="100%"
                disabled={isDisabled}
                name="winBy"
                label={<div className="select-competitor">Select Win By</div>}
                value={result}
                options={[{}, {value: 'cancel', label: <b style={{color: 'red'}}>cancel</b>}, ...WINS_BY_OPTIONS]}
                onChange={v => {
                    onWinBy && onWinBy(v);
                    setIsWinBy(false);
                    v!=='cancel' && setResult(v);
                }}
            />}
        </ThemeProvider>
    </div>
}

function EmptyCompetitor({
    currentDivision,
    isConsolidation, isThird,
    index,
    round, 
    registrations,
    isDisabled,
    onChange,
    roundName,
    bracketEntries, mainBracketEntries }) {
    const setIsBracketEntriesUpdate = useStore(state => state.setIsBracketEntriesUpdate);
    const isBracketEntriesUpdate = useStore(state => state.isBracketEntriesUpdate);
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        setRefresh(!refresh);
    }, [bracketEntries, mainBracketEntries, isBracketEntriesUpdate]);
    return <div className={classNames(CompetitorStyles.competitor, { [CompetitorStyles.hovered]: false }, CompetitorStyles.edit)}>
        <RoundHeader round={round} name={roundName} index={index} isThird={isThird}/>
        <SelectFormInput width="100%"
            disabled={isDisabled}
            name="competitor"
            label={"Select a competitor"}
            value={''}
            options={getCompetitorOptions({ registrations, round, index, bracketEntries, isConsolidation, mainBracketEntries, roundName, currentDivision })}
            onChange={(value) => {
                if (value.id === 'tbd') {
                    return;
                }
                const tournament = currentDivision.getTournament();
                let bracketEntry = {
                    membership: value, round, index, isThird,
                    getMembership: () => tournament.getMemberships().find(m => m.id === value),
                    getDivision: () => tournament.getAvailableDivisions().find(d => d.id === currentDivision.id)
                };
                onChange(bracketEntry);
                setIsBracketEntriesUpdate();
                setRefresh(!refresh);
            }}
        />
    </div>
}

const downloadImage = (imag) => {
    // Create a temporary anchor element
    var link1 = document.createElement('a');
    link1.href = imag;
    // Set the download attribute to specify the filename
    link1.download = 'canvas-image.png';
    // Trigger a click event on the anchor element to start the download
    link1.click();
}
export const ORIENTATION = {
    landscape: 'landscape',
    portrait: 'portrait'
}
async function generatePDF(bracketTypeRef, currentDivision, cb) {
    let isRR = currentDivision.getBracketType() === BRACKET.Round_Robin;
    let isDouble = currentDivision.getBracketType().startsWith('de');

    const doGenerate = async (contentDiv) => {
        let hasExportThirdPlace = false;
        let orientation = GROUP_OF===16? ORIENTATION.portrait : ORIENTATION.landscape;
        let roundFilterRef = bracketTypeRef.current.roundFilterRef.current;
        const init = (hide) => {
            contentDiv.style.width = hide ? contentDiv.scrollWidth + 'px' : '';
            contentDiv.style.height = hide ? contentDiv.scollHeight + 'px' : '';
            !hide && roundFilterRef.handleClick({}, false);
        }
        // Use html2canvas to capture the HTML element as an image
        if (contentDiv) {
            const maxWidth = 1353;
            const maxHeight = 1062;
            const pdfHeight = orientation===ORIENTATION.landscape? maxHeight:maxWidth;
            const pdfWidth= orientation===ORIENTATION.landscape? maxWidth:maxHeight;
            let totalPages = [0];
            let rfilter =  roundFilterRef.getRoundFilters();
            let groupFilters = rfilter.filter(f => f.value.toString().startsWith('group_'));
            totalPages = groupFilters.map(gf => gf.value);
            let filters = rfilter.filter(f => !isNaN(f.value));
            let final = filters.find(f => f.label === ROUND_NAME.Final);
            if (isRR) {
                totalPages = filters.map(f => f.value);
            } else if (groupFilters.length > 0) {
                totalPages.push(final.value - 1); //semi
            } 

            // Create a new jsPDF instance
            const pdf = new jsPDF({
                orientation,
                unit: 'pt',
                format: [pdfWidth, pdfHeight]
            });

            const doExport = async (page) => {
                init(true);
                const pageExport = async () => {
                    // Capture the content segment using html2canvas
                    let contentDivWidth = contentDiv.scrollWidth;
                    let contentDivHeight = contentDiv.scrollHeight;
                    //max size for html2canvas is 1353 x 1062
                    const getRatio = () => {
                        if (contentDivWidth <= pdfWidth && contentDivHeight <= pdfHeight) {
                            return 1;
                        }
                        let wr = pdfWidth/contentDivWidth;
                        let hr = pdfHeight/contentDivHeight;
                        if (wr > hr){
                            return hr;
                        }
                        return wr;
                    }
                    let canvas = await html2canvas(contentDiv, {
                        scale: getRatio(),
                        width: contentDivWidth,
                        height: contentDivHeight
                    });
                    
                    // Embed the captured content as an image in the PDF
                    const imgData = canvas.toDataURL("image/jpeg", 1.0);
                    // downloadImage(imgData);
                    let x = 10;
                    let y = (pdfHeight - canvas.height) / 2;
                    pdf.addImage(imgData, "JPEG", x, y, canvas.width, canvas.height);

                    if (totalPages.length <= 0) {
                        if (currentDivision.is_third && !hasExportThirdPlace){
                            pdf.addPage();
                            hasExportThirdPlace = true;
                            contentDiv = bracketTypeRef.current.bracketThirdRef.current.divRef.current;
                            await pageExport();
                        }else {
                            // Save the PDF with a filename
                            pdf.save(`${currentDivision.name || currentDivision.id}.pdf`);
                            init();
                            cb();
                        }
                    } else {
                        pdf.addPage();
                        await doExport(totalPages.pop());
                    }
                }
                if (page || page >= 0) {
                    roundFilterRef.handleClick({ value: page }, true);
                } 
                setTimeout(async () => await pageExport(), 600);
            }
            if (isRR) {
                if (totalPages.length > 1) {
                    roundFilterRef.handleClick({ value: totalPages.length - 1 }, true);
                }
            } else {
                roundFilterRef.handleClick({}, true);
            }
            await doExport(totalPages.pop());
            console.log('export exit');
        }
    }
    if (isDouble){
        bracketTypeRef.current.setDrawFilter(1);
        setTimeout(async () => {
            await doGenerate(bracketTypeRef.current.consolidationRef.current.divRef.current);
            bracketTypeRef.current.setDrawFilter(2);
            setTimeout(async () => {
                await doGenerate(bracketTypeRef.current.divRef.current);
            }, 2000);
        }, 2000);
    }else {
        await doGenerate(bracketTypeRef?.current.divRef?.current);
    }
}

export default BracketWrapper;