import { IonPage, IonSpinner } from "@ionic/react";
import { Accordion, AccordionDetails, AccordionSummary, Badge, IconButton, InputAdornment, Link, Paper, ThemeProvider, Tooltip } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef, createRef } from "react";
import Utils from "../../serverUtils/Utils";
import { useStore } from '../../Store';
import './Messages.scss';
import SendOutlined from '@mui/icons-material/SendOutlined';
import { RequestMessage, RequestUtils } from "../../serverUtils/requests";
import { STATUS } from "../../components/Bracket/Bracket";
import { MESSAGE_TYPE, MESSAGE_STATUS, getBackHistory, isKeyEnter } from "../../components/Form/Form";
import TextFormInput from "../../components/FormInput/TextFormInput";
import Theme from "../../components/FormInput/Theme";
import AlertPane from "../../components/FormInput/Message";
import { default as ExpandIcon } from "@mui/icons-material/ExpandMore";
import { default as CollapseIcon } from "@mui/icons-material/ChevronRight";
import { default as MessageIcon } from '@mui/icons-material/MailOutline';
import { default as LinkIcon } from '@mui/icons-material/Link';
import MessageModel from "../../serverUtils/models/MessageModel";
import MultiSelectFormInput from "../../components/FormInput/MultiSelectFormInput";
import TextAreaFormInput from "../../components/FormInput/TextAreaFormInput";
import { default as SendMessageIcon } from "@mui/icons-material/SendOutlined";
import { default as ExportPDFIcon } from "@mui/icons-material/PictureAsPdf";
import { default as CopyIcon } from "@mui/icons-material/ContentCopy";
import UserModel from "../../serverUtils/models/UserModel";
import { copyMessageToClipboard, ForwardButton, ForwardMessage, messageOutTemplate } from "./EntityMessagesPage";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from "moment";
import { GIHBanner } from "../../App";
import { ORIENTATION } from "../../components/Bracket/BracketWrapper";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useHistory } from 'react-router-dom';

export default function MessagesPage() {
  const history = useHistory();
  const session = useStore(state => state.session);
  const setSession = useStore(state => state.setSession);
  const browsingHistory = useStore(state => state.browsingHistory);
  const [selected, setSelected] = useState();
  const [message, setMessage] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [threads, setThreads] = useState([]);
  const [threadFilter, setThreadFilter] = useState('');
  const [isSendMessage, setIsSendMessage] = useState(false);
  const [isForwardUsers, setIsForwardUsers] = useState(null);
  const refs = {};

  let hasMessage = session && session.getMessages;
  useEffect(() => {
    if (hasMessage) {
      let ths = Utils.groupBy(session.getMessages(), ["from"]);
      setThreads(ths);
    }
    setIsLoading(false);
  }, [hasMessage]);

  let params = RequestUtils.getURLParameters();
  useEffect(() => {
    if (params.from) {
      setSelected(params.from);
    }
  }, [params.from]);

  useEffect(() => {
    if (selected) {
      getConversation();
      setTimeout(() => {
          refs[selected]
          && refs[selected].current
          && refs[selected].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    }
  }, [selected, threads]);

  useEffect(() => {
    if (!session || !session.getMessages || !selected) {
      return;
    }
    let params = RequestUtils.getURLParameters();
    if (params.id) {
      let ths = Utils.groupBy(session.getMessages(), ["from", "to"]);
      let thread = Object.keys(ths).findIndex(th => th.includes(params.id));
      if (thread > 0) {
        thread = Object.keys(ths)[thread];
        setSelected(thread);
      }else if (params.id === 'send') {
        return setIsSendMessage(true);
      }
      setSelected(null);
      setIsSendMessage(false);
    }
  }, [session]);

 
  const sendMessage = async (text) => {
    let fm = threads[selected][0];
    let message = {
      message_type: fm.message_type,
      to: (fm.from === session.id ? fm.to : fm.from) || fm.id,
      content: text,
      created_on: new Date().getTime(),
      first_name: fm.first_name,
      last_name: fm.last_name || '',
      status: STATUS.Pending,
      from: session.id,
    };
    let response = await RequestMessage.sendMessageRequest(message);
    let result = RequestUtils.getResponseData(response);
    if (result && result.id) {
      message.id = result.id;
      message.getFrom = fm.getFrom;
      threads[selected].unshift(message);
      setRefresh(!refresh);
      return true;
    }
  };

  const getConversation = async (t=selected) => {
    let thread = threads[t];
    if (!thread) {
      return;
    }
    let from = Utils.copy(thread[0].getFrom());
    let response = await MessageModel.getMessageConversation(t, session.id);
    if (response && !response.error) {
      threads[t] = response.map(m => {
        m.getFrom = () => from;
        m.count = 0;
        return m;
      });
      let found = session.getMessages().find(m => m.from === t);
      if (found) {
        found.count = 0;
        setSession({...session});
      }
    }
  }

  const MessageThreads = forwardRef(({}, ref) => {
    const [messages, setMessages] = useState([]);
    const [isThreadLoading, setIsThreadLoading] = useState(selected? true:false);
    useEffect(() => {
      let _messages = selected &&
      threads[selected] && threads[selected].sort((a, b) => Utils.sorterDate(b, a, 'created_on'))
        .filter(m => m.content && !isHide(m.content));
      setMessages(_messages);
      setIsThreadLoading(false);
    }, []);

    useImperativeHandle(ref, () => ({
      setMessageFilter: messageFilter => {
        let _messages = threads[selected].sort((a, b) => Utils.sorterDate(b, a, 'created_on'))
          .filter(m => m.content && !isHide(m.content, messageFilter));
        setMessages(_messages);
      },
    }));

    const isHide = (content, messageFilter) => {
      if (!messageFilter) {
        return false;
      }
      let words = messageFilter.toLowerCase().split(' ');
      return !words.every(word => content.toLowerCase().includes(word));
    }

    let fromName = selected && threads[selected] && getThreadName(threads[selected]) && getThreadName(threads[selected]).name;
    return isThreadLoading? <IonSpinner /> :
      <div className={`MessageThreads`}>
        {messages.map((m, i) => {
              let created_on = Utils.formatDateTime(m.created_on);
              let toName = UserModel.getMembershipName(session);
              return (
                <Paper 
                  key={i}
                  id={m.id}
                  elevation={m.from !== session.id ? 6 : 2}
                  className={
                    `content ${m.from !== session.id ? 'incoming' : 'outgoing'}`
                  }>
                  <div className={`date`} >
                    {created_on}
                  </div>
                  <p className="content-text">{m.content}</p>
                  <div className="buttons">
                    <Tooltip title="Copy message to clipboard">
                      <CopyIcon onClick={e => {
                        e.stopPropagation();
                        copyMessageToClipboard(m, fromName, toName, r => setMessage(r));
                      }}/></Tooltip>
                    {!isForwardUsers && <ForwardButton onClick={() => setIsForwardUsers(m)}/>}
                  </div>
                  {isForwardUsers === m && 
                    <ForwardMessage
                      fromName={fromName}
                      toName={toName}
                      session={session}
                      isSend={true} 
                      created_on={created_on} 
                      message={m.content}
                      onDone={r => {
                        if (r) {
                          setMessage(r);
                          if (r.startsWith('error:')) {
                            return;
                          }
                        }
                        setIsForwardUsers(null);
                      }}
                      />}
                  <AlertPane message={message} setMessage={setMessage} timeOut={4000} isFloat/>
                </Paper>
              );
            })
        }
      </div>;
  });

  let threadLength = Object.values(threads).length;
  const MessageHeader = ({}) => {
    const [unreads, setUnreads] = useState(0);
    useEffect(() => {
      setUnreads(Utils.sumArray(Object.values(threads).map(m => (m[0] && m[0].count) || 0)));
    }, [threadLength]);
    
    return <div className={`MessageHeader`}>
      <Badge
          color='primary'
          badgeContent={unreads}
        >
        <h1 style={{ textDecoration: 'underline' }}>
          Messages
        </h1>
      </Badge>
    </div>;
  }

  const SendMemberMessage = ({}) => {
    const [members, setMembers] = useState([]);
    const [content, setContent] = useState('');
    const [message, setMessage] = useState();
    
    const send = async () => {
      let tos = members.map(m => m.id);
      if (tos.length === 0) {
        return;
      }
      let message = {
          message_type: MESSAGE_TYPE.membership,
          tos,
          content,
          created_on: new Date().getTime(),
          status: 'P',
          from: session.id,
          created_by:  session.id,
        };
      let response = await MessageModel.sendBulkMessage(message);
      if (response && !response.error) {
          setMessage('success: Successfully send message.');
          let hasNewThread = false;
          let messages = session.getMessages();
          members.forEach(m => {
            let thread = messages.find(t => t.from === m.id);
            if (!thread) {
              hasNewThread = m.id;
              messages.unshift({
                from: m.id, count: 0, message_type: MESSAGE_TYPE.membership, to: session.id,
                getFrom: () => ({id: m.id, name: m.display, link: `/users/${m.id}`})
              });
            }
          });
          setTimeout(() => {
            if (hasNewThread) {
              session.getMessages = () => messages;
              setSession({...session});
            }
            setSelected(members[0].id);
            setIsSendMessage(false);
            RequestUtils.insertURLParam(null, null, history);
          }, 2000);
      }else {
          setMessage('error: Error sending message.');
      }
    }

    return <div className="SendMessage">
        <b><MessageIcon/><span>Select a Member</span></b>
        <MultiSelectFormInput
            value={members}
            name="memberships"
            label="Memberships"
            optionLabel="display"
            optionValue="id"
            fetchOptions={searchVal => UserModel.searchUser(searchVal)}
            onChange={v => setMembers(v)}
          />
        <TextAreaFormInput label="Message" 
            value={content} 
            onChange={v => {
                setContent(v);
            }}/>
        <div className="send-text">
            <button className="button small_button" onClick={send}>
                <span>Send</span> <SendMessageIcon className="SendMessageIcon"/> 
            </button>
            <button className="button small_button" 
              onClick={() => {
                setIsSendMessage(false);
                RequestUtils.insertURLParam(null, null, history);
              }}>
                <span>Done</span> 
            </button>
        </div>
        <AlertPane message={message} setMessage={setMessage} timeOut={3000} />
    </div>
  }
  
  return (
    <IonPage>
      <ThemeProvider theme={Theme}>
        {isLoading? <IonSpinner /> : 
        <div className="MessagesPage">
          {browsingHistory.length>0 && <Link href={`${getBackHistory(browsingHistory, '/messages')}`} >Back</Link>}
          <MessageHeader />
          {isSendMessage? <SendMemberMessage /> : 
            <button className="button SendMessage-button" 
              onClick={() => {
                setSelected('');
                setIsSendMessage(true);
                RequestUtils.insertURLParam('from', 'send', history, true);
              }}>
              <MessageIcon />Send a Message</button>}
          <ThreadFilter label="Thread Filter"
            filter={threadFilter}
            onFilter={searchText => {
              setThreadFilter(searchText);
              if (searchText) {
                setIsLoading(true);
                MessageModel.searchMessageThreads(session.id, searchText)
                  .then(messageObj => {
                    let messages = MessageModel.setMessageFrom(messageObj);
                    let ths = Utils.groupBy(messages, ["from"]);
                    setThreads(ths);
                    setIsLoading(false);
                  });
              }else {
                let ths = Utils.groupBy(session.getMessages(), ["from"]);
                setThreads(ths);
              }
            }}
          />
          <div className="messages">
            {threads && Object.keys(threads).map((t, i) => {
              let tname = getThreadName(threads[t]);
              if (tname) {
                let isSelected = t === selected;
                let ref = refs[t] = React.createRef();
                const messageThreadsRef = createRef();
                const messageThreadNameRef = createRef();
                return <Accordion key={i} expanded={isSelected} 
                  onChange={(_, ex) => {
                    if(ex) {
                      messageThreadsRef.current.setMessageFilter('');
                    } 
                  }}>
                  <AccordionSummary >
                    <div ref={ref} key={i} className="thread" style={{ fontWeight: isSelected ? 'bold' : '' }}
                      id={t}
                      onClick={() => {
                        if (selected === t) {
                          setSelected(null);
                          RequestUtils.insertURLParam(null, null, history);
                        } else {
                          setSelected(t);
                          RequestUtils.insertURLParam('from', t, history, true);
                        }
                        messageThreadNameRef.current && messageThreadNameRef.current.setIsToPDF(false);
                      }}>
                      {isSelected? <ExpandIcon />:<CollapseIcon />} 
                      <MessageThreadName ref={messageThreadNameRef} 
                        isSelected={isSelected}
                        length={(threads[t][0] && threads[t][0].count) || 0} 
                        name={tname} to={session} created_on={threads[t][0].created_on}
                        messages={threads[selected]}
                      />
                    </div>
                  </AccordionSummary>
                  {isSelected && <AccordionDetails >
                    <RenderSend 
                      sendMessage={sendMessage}
                      threads={threads} />
                    <ThreadFilter label="Message Filter" 
                      helper="enter to filter"
                      onFilter={searchText => {
                        if (messageThreadsRef.current) {
                          messageThreadsRef.current.setMessageFilter(searchText||'');
                        }
                      }}
                    />
                    <MessageThreads ref={messageThreadsRef} />
                  </AccordionDetails>}
                </Accordion>
              }
            }).filter(t => t)}
          </div>
        </div>}
      </ThemeProvider>
    </IonPage>
  );
}

export const ThreadFilter = ({onFilter, label, filter, helper}) => {
  const [filterInput, setFilterInput] = useState(filter || '');
  useEffect(() => {
    console.log('ThreadFilter: init');
  }, []);

  return <div className="ThreadFilter">
    <TextFormInput label={label} 
      onChange={v => {
        setFilterInput(v);
        !v && onFilter(v);
      }} 
      onKeyUp={e => {
        if (isKeyEnter(e.code)) {
          onFilter(filterInput);
        }
      }}
      value={filterInput}/>
    <div className="helper">{helper? helper:'enter to search'}</div>
  </div>
}

export const getMessageFromName = (from) => {
  const {name, first_name, last_name} = from;
  return name? name : [first_name, last_name].filter(n => n).join(' ').trim();
}

export const getThreadName = t => {
  if (!t) {
    return;
  }
  if (Array.isArray(t)) {
    if (t.length === 0 || !t[0].getFrom) {
      return;
    }
    t = t[0];
  }else {
    if (!t) {
      return;
    }
  }
  if (!t.getFrom || !t.getFrom()) {
    return;
  }
  const {link} = t.getFrom();
  let _name = getMessageFromName(t.getFrom());
  return {link, name: _name};
}

export const getUnreadMessages = (messages, to) => {
  return messages.filter(m => m.status === MESSAGE_STATUS.PENDING && m.to === to);
}

export const RenderSend = forwardRef(({sendMessage}, ref) => {
  const [message, setMessage] = useState();
  const [errorText, setErrorText] = useState();
  const textFormInputRef = useRef();
  useImperativeHandle(ref, () => ({
    textFormInputRef: textFormInputRef.current,
  }));
  useEffect(() => message && setErrorText(''), [message]);
  const doSend = () => {
    if (!message) {
      return setErrorText('This field is empty');
    }
    if (sendMessage(message)) {
      setMessage('');
    }else {
      setErrorText('Error sending message');
    }
  }
  return <div className="RenderSend">
    <TextFormInput ref={textFormInputRef}
      name="sendMessage"
      errorText={errorText}
      value={message}
      label='Enter Message...'
      id='outlined-start-adornment'
      onChange={v => setMessage(v)}
      onKeyUp={(e) => {
        if (isKeyEnter(e.code)) {
          doSend();
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='start'>
            <IconButton className="IconButton" onClick={doSend} >
              <SendOutlined className={`send_message`} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  </div>;
});

export const MessageThreadName = forwardRef(({isSelected, messages, name, length, to, created_on}, ref) => {
  const history = useHistory();
  
  return (
    <div className={`MessageThreadName`}>
      <div className="name">
        <Badge className="Badge"
          color='primary'
          badgeContent={length}>
          <span>{(name && name.name) || ''}</span>
        </Badge>
        <Link className="profile-link"
          onClick={e => {
            e.stopPropagation();
            history.push((name && name.link) || '');
          }}>profile</Link>
      </div>
      <div className="last-message-on date">{Utils.getDurationSince(created_on)}</div>
      <ExportToPDF isSelected={isSelected} name={name} to={to} messages={messages}/>
    </div>
  );
});

export const ExportToPDF = ({isSelected, name, to, messages}) => {
  const [isToPDF, setIsToPDF] = useState(false);
  const [pdfHTML, setPdfHTML] = useState();
  useEffect(() => setIsToPDF(!isSelected), [isSelected]);
  const getMessageDateRange = () => {
    if (messages) {
      let dates = messages.map(m => parseInt(m.created_on));
      if (dates.length > 0) {
        return {startDate: dates.pop(), endDate: dates.length>0 && dates.shift()}
      }
    }
    return {};  
  }

  const exportPdf = r => {
    const {startDate, endDate} = r;
    let content = messages.filter(m => {
        let created_on = parseInt(m.created_on);
        return startDate.getTime() <= created_on && created_on <= endDate.getTime();
      })
      .map(m => {
        let f = name.name;
        let t = getMessageFromName(to);
        if (m.from !== to.id) {
          f = getMessageFromName(to);
          t = name.name;
        }
        return messageOutTemplate(f, t, m.created_on, m.content);
      });
    setPdfHTML({
      content, 
      fname: `${name.name}_${[startDate, endDate].join('_')}`
    });
  }

  const PdfHTML = ({}) => {
    const divRef = useRef();
    // Target canvas dimensions (1062x1353 pixels)
    const targetWidth = 1062;
    const targetHeight = 1353;
    // Convert margin from inches to pixels (assuming 96 DPI)
    const marginInches = 0.5;
    const marginPixels = marginInches * 96;
    useEffect(() => {
      const pdf = new jsPDF({
        orientation: ORIENTATION.portrait,
        unit: 'pt',
        format: [targetWidth, targetHeight]
      });
      const doExport = async () => {
        let canvas = await breakElementToCanvases();
        for (let i=0; i<canvas.length; i++) {
          let c = canvas[i];
          pdf.addImage(c.toDataURL('image/jpeg'), "JPEG", marginPixels, marginPixels, c.width, c.height);
          if (i < canvas.length-1){
            pdf.addPage();
          }
        }
            // Generate PDF as Blob
        const pdfBlob = pdf.output('blob');
        // Create a URL for the Blob
        const pdfURL = URL.createObjectURL(pdfBlob);
        // Open the Blob URL in a new tab
        window.open(pdfURL, '_blank');
        
        pdf.save(`${pdfHTML.fname}.pdf`);
        setIsToPDF(false);
        setPdfHTML(null);
      }
      doExport();
    }, []);

    const breakElementToCanvases = async () => {
      const scale = .8;
      const element = divRef.current;
      try {
        const GIHBannerHeight = element.getElementsByClassName('GIHBanner')[0].scrollHeight;
        const lineHeight = element.getElementsByClassName('index_0')[0].scrollHeight;

        // Determine the number of canvases needed
        const pageMargin = 2*marginPixels;
        const linesPerPage = Math.ceil((targetHeight-(GIHBannerHeight+pageMargin)) / lineHeight);
        const allLines = element.getElementsByClassName('line');
        const totalPages = Math.ceil(allLines.length / linesPerPage);

        // Array to store all smaller canvases
        const canvasArray = [];

        const toggleShowLines = (startIndex, endIndex, display) => {
          for (let i=0; i<allLines.length; i++) {
            if (i < startIndex || i >= endIndex) {
              let l = allLines[i];
              l.style.display = display;
            }
          }
        }
        
        for (let page = 0, startIndex = 0; page < totalPages; page++, startIndex+=linesPerPage) {
          let endIndex = startIndex + linesPerPage;
          toggleShowLines(startIndex, endIndex, 'none');
          // Create a new canvas element
          const smallCanvas = await html2canvas(element, {
            scale,
            useCORS: true,
          });
          // Add the smaller canvas to the array
          canvasArray.push(smallCanvas);
          toggleShowLines(startIndex, endIndex, '');
        }

        // Return the array of canvases
        return canvasArray;
      }catch(error) {
        console.error('An error occurred while capturing the element:', error);
      };
    }

    let index = 0;
    return <div ref={divRef} className="PdfHTML" 
      onClick={e => {
        e.stopPropagation();
        setIsToPDF(false);
        setPdfHTML(null);
      }}>
      <GIHBanner />
      <div className="content">
        {pdfHTML.content.map((c) => {
          let clist = c.split('\n');
          return [
            <p key={-1} className={`line title index_${index++}`}>{clist.shift()}</p>,
            [...clist.map((l, i) => {
              if (i === 0 ){
                let lstack = l.split(':');
                return <p key={i} className={`line body index_${index++}`}><b>{lstack.shift()}:</b>{lstack.join(':')}</p>;
              }
              return <p key={i} className={`line body index_${index++}`}>{l}</p>;
            })]
          ]
        })}
      </div>
    </div>
  }

  return <div className="toPDF" onClick={e => e.stopPropagation()}>
    {isSelected && messages && messages.length>0 &&
      <Tooltip title="Export messages to a pdf file by date range">
        <ExportPDFIcon onClick={e => {
          e.stopPropagation();
          setIsToPDF(!isToPDF);
        }}/>
      </Tooltip>}
    {isToPDF && isSelected &&
      <DateRange 
        start={getMessageDateRange().startDate}
        end={getMessageDateRange().endDate}
        onDone={r => {
            if (r) {
              exportPdf(r)
            }
            setIsToPDF(!isToPDF);
        }}/>}
    {pdfHTML && <PdfHTML />}
  </div>;
}

export const DateRange = ({start = new Date().getTime(), end, onDone}) => {
  const [startDate, setStartDate] = useState(new Date(start));
  const [endDate, setEndDate] = useState(end? new Date(end) : moment(start).add(1, 'days').toDate());
  return <div className="DateRange">
    <DateRangePicker showSelectionPreview={false}
      ranges={[{startDate, endDate, key: 'selection'}]}
      onChange={ranges => {
        const {startDate, endDate} = ranges.selection;
        setStartDate(startDate);
        setEndDate(endDate);
      }}
    />
    <div className="buttons">
      <button className="button" 
        onClick={e => {
          e.stopPropagation();
          onDone({startDate, endDate})
        }}>Select</button>
      <button className="button"
        onClick={e => {
          e.stopPropagation();
          onDone();
        }}>Cancel</button>
    </div>
  </div>
}