import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import Theme from "./Theme";
import Utils from "../../serverUtils/Utils";

const SelectFormInput = forwardRef(({
  value,
  name,
  label,
  setValue,
  options,
  onChange,
  onMouseLeave,
  disabled = false,
  width,
  isSortOption
}, ref) => {
  const [inputValue, setInputValue] = useState();
  const [open, setOpen] = useState(false);
  const selectRef = useRef();

  useImperativeHandle(ref, () => ({
    selectRef: selectRef.current,
    setInputValue,
  }));

  useEffect(() => {
    inputValue && setValue && setValue(name, inputValue||'');
  }, [inputValue]);

  useEffect(() => {
    let v = value;
    if (!v && !inputValue && options.length > 0){
      v = options[0].value;
    }
    v && setInputValue(v);
    // onChange && onChange(v);
  }, [value, options]);
  
  const handleChange = (e) => {
    let v = e.target.value;
    setInputValue(v);
    onChange && onChange(v);
    handleClose();
  };

  const handleOpen = (e) => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  let _options = options || [];
  if (isSortOption) {
    _options = _options.sort((a, b) => Utils.sort(a.label, b.value));
  }
  // console.log(name, inputValue);
  return <div className={`SelectFormInput ${(name||'').split('.').pop()}`} >
    <ThemeProvider theme={Theme}>
    <FormControl margin="normal" disabled={disabled} style={{ width: (Utils.isMobile() || !width) ? '100%' : width }}>
        <InputLabel id={name}>
          {label}
        </InputLabel>
      <Select 
        // {..._register}
        labelId={name}
        label={label}
        name={name}
        value={inputValue || ''}
        open={open}
        onClose={!disabled && handleClose}
        onOpen={!disabled && handleOpen}
        onChange={!disabled && handleChange}
        onClick={(e) => {
          e.stopPropagation();
          if (!open) {
            !disabled && handleOpen();
          }
        }}
        onMouseLeave={onMouseLeave}
        ref={selectRef}
      >
        {_options?.map((option, i) => (
          <MenuItem key={i} value={option.value} style={{ whiteSpace: 'normal' }}>
            <span className="menu-item">{option.label || option.value}</span>
          </MenuItem>
        ))}
      </Select>
      </FormControl>
    </ThemeProvider>
  </div>;
});

export default SelectFormInput;
