import { Route } from 'react-router-dom';
import {
  IonApp,
  IonButton,
  IonButtons,
  IonContent,
  IonIcon, IonRouterOutlet, IonToolbar,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { personCircle, logOutOutline } from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
// import './theme/variables.css';
import './theme/variables1.css';

// Custom CSS
import './theme/global.scss';
import './App.scss';
import { useEffect, useRef, useState } from 'react';
import { pages } from './components/Nav/pages';
import { createTheme, CssBaseline, ThemeProvider, Tooltip } from '@mui/material';
import Utils from './serverUtils/Utils';
import UserModel from './serverUtils/models/UserModel';
import { useStore } from './Store';
import { useHistory } from 'react-router-dom';
import { RequestUtils, setGraphServerUrl } from './serverUtils/requests';
import LocalServerModel from './serverUtils/models/LocalServerModel';
import './LocalServerApp.scss';
import './pages/LocalServer/LocalServer.scss';
import { isMaster, NotificationMessages } from './pages/LocalServer/LocalServer';
import TextFormInput from "./components/FormInput/TextFormInput";
import SetttingsIcon from '@mui/icons-material/Settings';
import { default as TournamentsIcon } from "@mui/icons-material/List";
import { default as NotificationIcon } from "@mui/icons-material/ChatBubbleOutline";
import { default as OnLineIcon } from "@mui/icons-material/Cloud";
import AlertPane from "./components/FormInput/Message";
const origin_request = Utils.cookie('origin_request')
export const FromApp = origin_request && origin_request.includes('fromApp');

setupIonicReact();

const GlobalNavBar = ({ setIsSettings, setNotification }) => {
  const notifications = useStore(state => state.notifications);
  const [message, setMessage] = useState();
  const notficationCount = useRef();
  useEffect(() => notficationCount.current = 0, []);
  useEffect(() => {
    if (notficationCount.current < notifications.length && notifications.length > 0) {
      let notif = notifications[notifications.length - 1];
      notif[1] && notif[1].notification && setMessage(`info: ${notif[1].notification}`);
    }
  }, [notifications.length]);

  return (
    <IonToolbar className="GlobalNavBar">
      <ProfileButtons setIsSettings={setIsSettings} setNotification={setNotification} />
      <AlertPane message={message} timeOut={5000} setMessage={setMessage}/>
    </IonToolbar>
  );
};

const ProfileButtons = ({ setIsSettings, setNotification }) => {
  const history = useHistory();
  const session = useStore(state => state.session);
  const station = useStore(state => state.station);
  const localServer = useStore(state => state.local_server);
  const isGraphQLOnline = useStore(state => state.isGraphQLOnline);
  const notifications = useStore(state => state.notifications);
  const [unReads, setUnreads] = useState(0);
  useEffect(() => {
    station && setUnreads(notifications.filter(n => n[1].to === station.station && !n[1].read).length);
  }, [notifications, station]);

  const getStationName = () => {
    if (document.location.host.includes('localhost')) {
      return LocalServerModel.STATION_TYPE_NAME.MASTER;
    }
    if (station && LocalServerModel.STATION_TYPE_NAME[station.type]) {
      return LocalServerModel.STATION_TYPE_NAME[station.type];
    }
    return 'Settings';
  }
  return <div className="ProfileButtons" >
    <div className="left">
      <h1>
        GIH OnSite App {localServer && localServer.version} 
        <OnLineIcon className={`online-indicator ${localServer && isGraphQLOnline? 'online': ''}`}/>
      </h1>
      <div className="buttons">
        {!FromApp && isMaster() && 
          <button className="button small_button" onClick={() => setIsSettings()} >
            <SetttingsIcon /> <div>{getStationName()}</div>
          </button>}
        {isMaster() && session && session.id &&
          <button className="button small_button" onClick={() => {
            setIsSettings(false);
            Utils.deleteCookie(LocalServerModel.LOCAL_SERVER.local_tournament);
            document.location.pathname !== '/tournaments' && history.push('/tournaments');
          }}>
            <TournamentsIcon /> Tournaments
          </button>}
      </div>
    </div>
    <div className="profile-buttons">
      <IonButtons
        key={1}
        className='local-server'
        slot='end'>
        <IonButtons>
          {session && session.id &&
            [<IonButton key={0}>
              <IonIcon
                slot='icon-only'
                icon={personCircle}
              />
              <Tooltip title={UserModel.getMembershipName(session)}>
                <span
                  className='user-name'
                  id={session && session.id}>
                  {UserModel.getMembershipNameInitial(session)}
                </span>
              </Tooltip>
            </IonButton>,
            <IonButton
              key={1}
              routerLink={'/logout'}>
              <IonIcon
                slot='icon-only'
                icon={logOutOutline}
              />
            </IonButton>]
          }
          {!isMaster() && 
            <div className="notifications">
              <NotificationIcon className="NotificationIcon" onClick={() => setNotification(true)}/>
              <span>{unReads}</span>
            </div>}
            
        </IonButtons>
      </IonButtons>
    </div>
  </div>
};

const SplitPane = ({ }) => {
  const session = useStore(state => state.session);
  const setSession = useStore(state => state.setSession);
  const history = useHistory();
  const localServer = useStore((state) => state.local_server);
  const setIsGraphQLOnline = useStore(state => state.setIsGraphQLOnline);
  const [isSettings, setIsSettings] = useState(false);
  const [notification, setNotification] = useState(false);
  const markNotificationsRead = useStore(state => state.markNoficationsRead);
  const station = useStore(state => state.station);
  const setLocalServer = useStore((state) => state.setLocalServer);
  const setStation = useStore(state => state.setStation);

  const initLocalServer = async () => {
    let gih_mode = Utils.cookie('gih_mode');
    if (gih_mode) {
      let localServer = JSON.parse(decodeURIComponent(gih_mode));
      localServer.isDevServer = Utils.cookie('gih_debug');
      setLocalServer(localServer);
      LocalServerModel.setLocalServerURL(localServer.isDevServer ? localServer.isDevServer : '');
      setGraphServerUrl(localServer.isDevServer ? `${localServer.isDevServer}/graphql` : '/graphql');
    }

    let devServerUrl = Utils.cookie('gih_debug');
    let url = `${devServerUrl || ''}/local_server_sse?id=master`;
    const source = new EventSource(url);
    source.addEventListener('error', function (event) {
      // Handle the error here
      console.error('EventSource error:', event);
    });
    let message;
    source.onmessage = (event) => {
      const { data } = event;
      try {
        message = JSON.parse(data);
        setIsGraphQLOnline(message.is_graph_server_online);
      } catch (e) {
        console.log(e);
      }
    };
  }

  useEffect(() => {
    initLocalServer();
  }, []);

  useEffect(() => {
    let st = station || 
              getStationFromCookie() || 
              { station: `_${new Date().getTime()}`, type: LocalServerModel.STATION_TYPE.not_set };
    try{
      if (isMaster()) {
        st.label = LocalServerModel.STATION_TYPE_NAME.MASTER;
        st.station = LocalServerModel.STATION_TYPE.master;
        if (session && session.id) {
          let tourId = RequestUtils.getURLId('tournaments');
          if (!tourId) {
            let p = '/tournaments';
            document.location.pathname !== p && history.push(p);
          } else {
            let p = `/local/tournaments/${tourId}`;
            document.location.pathname !== p && history.push(p);
          }
        } else {
          UserModel.getSession(setSession, null, true)
            .then(s => {
              if (!s) {
                history.push('/login');
              }
            });
        }
      } else {
        let origin_request = Utils.cookie('origin_request');
        if (origin_request.startsWith('/local/') && origin_request !== document.location.pathname) {
          let type = origin_request.split('?')[0].split('/local/').pop();
          st.type = type;
          history.replace(origin_request);
        }else {
          let p = `/local/${st.type || 'NA'}`
          document.location.pathname !== p && history.push(p);
        } 
      }
    }finally{
      if (st !== station) {
        setStation(st);
        setStationToCookie(st);
      }
    }
  }, [session, localServer, station]);

  let routes = pages.filter(p => p.localSever);

  return (
    <>
      <GlobalNavBar 
        setIsSettings={(s) => setIsSettings(s === undefined ? !isSettings : s)}
        setNotification={setNotification} />
      <div
        id='main-content'
        className={`mainContent`}>
        <IonContent>
          {isSettings ? (isMaster() && <MasterSettings setIsSettings={setIsSettings} />)
            : <IonRouterOutlet>
              {routes.map((p) => (
                <Route
                  key={p.path}
                  exact
                  path={p.path}
                  component={p.component}
                />
              ))}
            </IonRouterOutlet>}
        </IonContent>
      </div>
      {!isMaster() && notification &&
        <div className="Notifications"
          onMouseLeave={() => {
            markNotificationsRead();
            setNotification(false);
          }}>
          <NotificationMessages
            height={200}
            onPostMessage={() => setNotification(false)}
            station={station} />
        </div>}
    </>
  );
};

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export const getStationFromCookie = (setStation) => {
  let st = Utils.cookie(LocalServerModel.LOCAL_SERVER.local_station);
  if (st) {
    setStation && setStation(st);
    return JSON.parse(st);
  }
}

export const setStationToCookie = (st, setStation) => {
  st.type = st.type || LocalServerModel.STATION_TYPE.not_set;
  Utils.cookie(LocalServerModel.LOCAL_SERVER.local_station, JSON.stringify(st));
  setStation && setStation(st);
}

const LocalServerApp = ({ }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <IonApp>
        <IonReactRouter>
          <SplitPane />
        </IonReactRouter>
      </IonApp>
    </ThemeProvider>
  );
};

const MasterSettings = ({ setIsSettings }) => {
  const [config, setConfig] = useState();
  useEffect(() => {
    LocalServerModel.getConfig()
      .then(c => {
        setConfig(c);
      });
  }, []);
  const update = () => {
    LocalServerModel.saveConfig(config)
      .then(() => {
        setIsSettings(false);
        LocalServerModel.sendElectronjsMessage({ message: 'updateApp', data: config });
      });
  }
  return !config ? '' : <div className="Settings">
    <TextFormInput InputProps={{ readOnly: true, style: { background: '#f0f0f0', color: '#333' } }}
      label={'Master Server URL'}
      value={config.master_url}
    />
    <TextFormInput
      label={'GIH Server URL'}
      value={config.graphql_server_url}
      onChange={graphql_server_url => setConfig({ ...config, graphql_server_url })}
    />
    <TextFormInput
      label={'Server Port'}
      value={config.port}
      onChange={e => {
        !isNaN(e.target.value) && setConfig({ ...config, port: parseInt(e.target.value) });
      }}
    />
    <IonButtons>
      <button className="button" onClick={update}>Update</button>
      <button className="button" onClick={() => setIsSettings(false)}>Cancel</button>
    </IonButtons>
  </div>
}

export default LocalServerApp;
