import { useEffect, useState } from "react";
import {
  IonContent, IonPage, IonButtons,
  IonIcon,
  useIonViewWillEnter
} from "@ionic/react";
import pageStyles from "./../Page.module.scss";
import LoadingList from "../../components/LoadingList/LoadingList";
import classNames from "classnames";
import UserModel from "../../serverUtils/models/UserModel";
import { addCircleOutline as addIcon } from "ionicons/icons";
import { Link, ThemeProvider } from "@mui/material";
import { useStore } from "../../Store";
import Theme from "../../components/FormInput/Theme";
import UserChip from "../../components/UserChip/UserChip";
import "./UsersPage.scss";
import { getFlag } from "../UserPage/UserPage";
import { FilterInput } from "../../components/Form/Form";
import Utils from "../../serverUtils/Utils";

const UsersPage = (props) => {
  const session = useStore((state) => state.session);
  const [users, setUsers] = useState(props.memberships);
  const searchStrings = useStore(state => state.searchStrings);
  const setSearchStrings = useStore(state => state.setSearchStrings);

  const getData = async (search_string = searchStrings.users) => {
    const users = await UserModel.getUsers({ search_string });
    if (users) {
      setUsers(users.map(u => {
        u.last_name = u.last_name.trim();
        return u;
      }).sort((a, b) => Utils.sorter(a, b, 'last_name')));
    }
  };

  useIonViewWillEnter(() => {
    if (!users) {
      getData(searchStrings.users);
    }
  }, [users, searchStrings.users, session]);

  const doFilter = s => {
    setSearchStrings('users', s);
    getData(s);
  };

  const UserItem = ({user}) => {
    const [flag, setFlag] = useState();
    useEffect(() => getFlag(user.country).then(f => setFlag(f)), [user]);
    return <div className="UserItem">
      <div className="user">
        <UserChip membership={user}/>
      </div>
      {user.getGym() && <Link href={`/gyms/${user.getGym().id}`}>{user.getGym().name || ''}<span className="entity-type">@gym</span></Link>}
      {user.getTeam() && <Link href={`/teams/${user.getTeam().id}`}>{user.getTeam().name || ''}<span className="entity-type">@team</span></Link>}
      {user.getLeague() && <Link href={`/leagues/${user.getLeague().id}`}>{user.getGym().name || ''}<span className="entity-type">@league</span></Link>}
      <p>{user.country} {flag && <IonIcon className={`flag`} icon={flag} />}</p>
      {/*{tournament.locations.map((location, i) => <p key={`location-${i}`}>{location.city + ','} {location.state + ','} {location.country}</p>)}*/}
    </div>;
  }

  return (
    <IonPage className={`${pageStyles.page} UsersPage`}>
      <ThemeProvider theme={Theme}>
        <IonContent>
          <div className={`${pageStyles.pageHeader}`}>
            <div
              className={pageStyles.pageHeaderImage}
            />
            <div className={pageStyles.pageHeaderTitleWrapper}>
              <h1
                id="users_page"
                className={classNames("display-font", pageStyles.pageHeaderTitle)}
              >
                Users
              </h1>
              <FilterInput label="Search User" entity="users" onFilter={v => doFilter(v)} />
            </div>
          </div>
          <div className={pageStyles.marginBottom}>
            {UserModel.isAdmin(session) && (
              <IonButtons className={pageStyles.create_button}>
                <Link href={`/users/add`} id="user_add" >
                  <button className="button">
                    <IonIcon icon={addIcon} /> Add a new User
                  </button>
                </Link>
              </IonButtons>
            )}
            {users ? (
              <div className="users">
                {users.map((user, i) => (
                  <UserItem user={user} key={i}/>
                ))}
              </div>
            ) : (
              <LoadingList />
            )}
          </div>
        </IonContent>
      </ThemeProvider>
    </IonPage>
  );
};

export default UsersPage;
