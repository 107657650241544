import { IonButton } from "@ionic/react";
import { TextareaAutosize } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SelectFormInput from "../FormInput/SelectFormInput";
import { STATUS } from "./Form";
import AlertPane from "../FormInput/Message";
import "./MembershipInfo.scss";
import DateFormInput1 from "../FormInput/DateFormInput1";

export default function MembershipInfo({info, onUpdate}) {
    const {control} = useForm();
    const [data, setData] = useState();
    const [message, setMessage] = useState();
    useEffect(() => {
        setData(info);
    }, [info]);

    const update = (value) => {
        let d = {...data, ...value};
        setData({...d});
    }
    
    const getStatus = () => {
        return data.status || 'P';
    }

    return !data? '' :
        <div className="MembershipInfo">
            <AlertPane message={message} />
            <div className="fields">
                <div className="status">
                    <SelectFormInput options={STATUS} label="Status" name="status"
                        value={getStatus()}
                        onChange={v => update({status: v})}
                    />
                    {/* <SelectFormInput options={[]} label="Membership Type" name="membership_type"
                        value={data.membership_level || ''}
                        onChange={v => update({membership_level: v})}
                    /> */}
                </div>
                <div className="dates">
                    <div className="date">
                        <DateFormInput1 value={data?.registered_date} 
                            label="Register Date"
                            name="registered_date"
                            onChange={v => update({registered_date: v})}
                            control={control}/>
                    </div>
                    <div className="date">
                        <DateFormInput1 value={data?.period?.start_date} 
                            label="Start Date"
                            name="period.start_date"
                            onChange={v => update({period: {...data.period, start_date: v}})}
                            control={control}/>
                    </div>
                    <div className="date">
                        <DateFormInput1 value={data?.period?.end_date} 
                            label="End Date"
                            name="period.end_date"
                            onChange={v => update({period: {...data.period, end_date: v}})}
                            control={control}/>
                    </div>
                </div>
                <TextareaAutosize label={'Note'}
                    defaultValue={data.note}
                    minRows={3}
                    placeholder={'Enter note here...'}
                    style={{color: 'black'}}
                    onChange={e => update({note: e.target.value})}
                />
            </div>
            <IonButton onClick={() => {
                setMessage('');
                onUpdate && onUpdate(data)
                .then(result => {
                    if (result && result.id){
                        setMessage('sucess: Successfully update membership.')
                    }else {
                        setMessage('error: Error updating membership.');
                    }
                });
                
            }}>Save</IonButton>
        </div>;
}