import { IonSpinner } from "@ionic/react";
import "./Busy.scss";

const Busy = ({message}) => {
    if (!message) {
        return '';
    }
    return <div className="Busy">
        <div className="parentWrapper">
            <div className="overlayPane" />
            <div className="messagePane">
                <div className="content">
                    <IonSpinner className="spinner" color="green"/>
                    <h3 className="message">{message}</h3> 
                </div>
            </div>
        </div>
    </div>;
}

export default Busy;