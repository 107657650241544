import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './LocalServer.scss';
import LocalServerModel from '../../serverUtils/models/LocalServerModel';
import Utils from '../../serverUtils/Utils';
import { useStore } from '../../Store';
import { useHistory } from 'react-router-dom';
import { duplicate_station_error, getRTCConnState, getStationName, initStation } from './LocalServer';
import { default as RecordIcon } from '@mui/icons-material/RadioButtonChecked';
import { default as StopRecordIcon } from '@mui/icons-material/Stop';
import { default as SaveRecordingIcon } from '@mui/icons-material/Save';
import { default as PlayRecordingIcon } from '@mui/icons-material/PlayCircle';
import { default as FullScreenIcon } from '@mui/icons-material/Fullscreen';
import { default as RTCStatusIcon } from '@mui/icons-material/Cast';
import SelectFormInput from "../../components/FormInput/SelectFormInput";
import AlertPane from "../../components/FormInput/Message";
import UserModel from "../../serverUtils/models/UserModel";
import html2canvas from "html2canvas";
import { IconButton } from "@mui/material";

let recordedData=[], recordedBlob, processedStream;
let sse;
let rtcPeerConnection = new RTCPeerConnection(null);
const offscreenCanvas = new OffscreenCanvas(0, 0);
export const LocalCamera = forwardRef(({ }, ref) => {
    const history = useHistory();
    const station = useStore(state => state.station);
    const setStation = useStore(state => state.setStation);
    const localServer = useStore((state) => state.local_server);
    const tournament = useStore(state => state.local_tournament);
    const setTournament = useStore(state => state.setLocalTournament);
    const [refresh, setRefresh] = useState(false);
    const [isPreview, setIsPreview] = useState();
    const [isRecording, setIsRecording] = useState();
    const [recordingSize, setRecordingSize] = useState('');
    const [isSaving, setIsSaving] = useState();
    const [message, setMessage] = useState();
    const [redirect, setRedirect] = useState();
    const [cameraDeviceId, setCameraDeviceId] = useState();
    const [selectCameraDevices, setSelectCameraDevices] = useState([]);
    const [availScorecardStations, setAvailableScorecardStations] = useState([]);
    const [overlayStation, setOverlayStation] = useState('');
    const [overlayStationData, setOverlayStationData] = useState();
    const addNotification = useStore(state => state.addNotification);

    const cameraStreamRef = useRef();
    const canvasRef = useRef();
    const videoPlaybackRef = useRef();
    const overLayScoreCardRef = useRef();
    const videoRef = useRef();
    let isStationRTCReady = false;
    let offer;

    useEffect(() => {
        listCameraDevices().then(devices => {
            setSelectCameraDevices(devices);
            devices.length> 0 && startCamera(devices[0].value);
        });
        // Event listener for the video's "play" event
        cameraStreamRef.current.addEventListener('play', () => {
            function drawOverlay() {
                if (!cameraStreamRef.current && cameraStreamRef.current.paused || cameraStreamRef.current.ended) {
                    return;
                }
                let w = cameraStreamRef.current.videoWidth;
                let h = cameraStreamRef.current.videoHeight;
                offscreenCanvas.width = w
                offscreenCanvas.height = h;
                let ctx = offscreenCanvas.getContext('2d');

                // Draw the video frame onto the canvas
                ctx.drawImage(cameraStreamRef.current, 0, 0, w, h);

                // Draw the overlay image onto the canvas
                if (overLayScoreCardRef.current && overLayScoreCardRef.current) {
                    let img = overLayScoreCardRef.current.getImage();
                    if (img) {
                        ctx.drawImage(img, 0, 10, img.width, img.height); // Adjust position and size as needed
                    }
                }

                canvasRef.current.width = w;
                canvasRef.current.height = h;
                ctx = canvasRef.current.getContext('2d');
                ctx.drawImage(offscreenCanvas, 0, 0);
                // Request the next animation frame
                requestAnimationFrame(drawOverlay);
            }

            // Start drawing frames
            drawOverlay();
        });
    }, []);

    useEffect(() => {
        if (station && station.type === LocalServerModel.STATION_TYPE.camera) {
            initSSE();
        } 
    }, [station, overlayStation]);

    useEffect(() => {
        if (station && station.mediaRecorder) {
            if (isRecording) {
                station.mediaRecorder.start(100); // collect 100ms of data
                console.log('MediaRecorder started', station.mediaRecorder);
            }
        }
    }, [isRecording])

    useImperativeHandle(ref, () => ({
        setMessage,
    }));

    rtcPeerConnection.oniceconnectionstatechange = (event) => {
        let state = rtcPeerConnection.iceConnectionState;
        console.log('ICE state change:', state, ' event:', event);
    };

    rtcPeerConnection.onconnectionstatechange = (e) => {
        let state = rtcPeerConnection.connectionState;
        console.log('RTC state change:', state, ' event:', e);
      }
    
    rtcPeerConnection.ontrack = e => {
        console.log('Received remote stream', e);
    }

    rtcPeerConnection.onaddstream = (e) => {
        console.log('onaddstream', e);
    }

    const listCameraDevices = async () => {
        let devices = await navigator.mediaDevices.enumerateDevices();
        let cameras = devices.filter(device => device.kind === 'videoinput');
        cameras =  cameras.map(camera => {
            console.log(`- ${camera.label || 'Camera'} (${camera.deviceId})`);
            return {
                label: camera.label,
                value: camera.deviceId
            }
        });
        
        return cameras.length > 0 ? cameras : [{label: 'No camera device found'}];
    }

    const createOffer = async () => {
        try {
            if (!offer) {
                offer = await rtcPeerConnection.createOffer({
                    offerToReceiveAudio: 0,
                    offerToReceiveVideo: 1
                });
                await rtcPeerConnection.setLocalDescription(offer);
                console.log(`SetLocalDescription complete:`, offer);
            }
            await LocalServerModel.sendMessage({
                from: station.station,
                label: station.label,
                to: LocalServerModel.STATION_TYPE.master,
                type: station.type,
                rtc: JSON.stringify(offer)
            });
        } catch (e) {
            console.log('Error creating offer: ', e)
        }
    }

    const sendIceCandidates = async () => {
        const gatherAllIceCandidates = async () => {
            return new Promise((resolve) => {
                const gatheredCandidates = [];
                rtcPeerConnection.onicecandidate = (event) => {
                    if (event.candidate) {
                        gatheredCandidates.push(event.candidate);
                    } else {
                        resolve(gatheredCandidates); // Resolves when all candidates have been gathered
                    }
                };
            });
        }
        let iceCandidates = await gatherAllIceCandidates();
        for (let iceCandidate of iceCandidates) {
            await LocalServerModel.sendMessage({
                from: station.station,
                label: station.label,
                to: LocalServerModel.STATION_TYPE.master,
                type: LocalServerModel.STATION_TYPE.camera,
                rtc_candidate: JSON.stringify(iceCandidate)
            });
        }
    }

    const initRTCPeerConnection = async () => {
        console.log('Station createOffer start');
        await createOffer();
    }

    const NO_OVERLAY = {value:'-1', label: 'No Overlay'}
    const receive = (message) => {
        const {stations: Stations, from} = message;
        const updateAvailScorecardStations = () => {
            if (Stations) {
                let ss = Object.values(JSON.parse(Stations)).filter(s => s.type === LocalServerModel.STATION_TYPE.score_card)
                        .map(s => ({value: s.station, label: s.label}));
                setAvailableScorecardStations([NO_OVERLAY, ...ss]);
            }
        }
        const updateOverlay = () => {
            const { data } = message;
            if (overlayStation === from && data) {
                setOverlayStationData(JSON.parse(data));
            }
        }
        try{
            if (message.to !== LocalServerModel.LOCAL_SERVER.ping && message.to !== station.station) {
                return;
            }

            if (message === duplicate_station_error) {
                setMessage('Duplicate station: ' + Utils.cookie(LocalServerModel.LOCAL_SERVER.local_station))
            }
            const { to, rtc, rtc_candidate, notification, tournament_id } = message;

            if (from === station.station ) {
                return;
            }
            if (to === LocalServerModel.LOCAL_SERVER.ping) {
                return LocalServerModel.sendMessage({
                    tournament_id,
                    from: station.station,
                    to: LocalServerModel.STATION_TYPE.master,
                    type: LocalServerModel.STATION_TYPE.camera,
                    label: station.label,
                    rtc: JSON.stringify(offer)
                });
            }
            if (to !== station.station) {
                return;
            }
            console.log(
                '\nsse_receive:',
                Utils.formatDateTime(new Date().getTime(), 'hh:mm:ss'),
                '\nmessage:',
                message
            );
            Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament, tournament_id);
            if (LocalServerModel.setCurrentStation(message, station, setStation, setRedirect)) {
                sse.close();
                return;
            };

            if (notification) {
                let n = {...message};
                delete n.clients;
                return addNotification(n);
            } else {
                if (rtc) {
                    if (rtc === 'initRTCPeerConnection') {
                        initRTCPeerConnection();
                    }else if (rtc === 'initRTCPeerConnectionReady'){
                    isStationRTCReady = true;
                    }else {
                        let answer = JSON.parse(rtc)
                        rtcPeerConnection.setRemoteDescription(new RTCSessionDescription(answer))
                            .then(async () => {
                                console.log('SetRemoteDescription: ', answer);
                                sendIceCandidates();
                            });
                    }
                } else if (rtc_candidate) {
                    let candidate = JSON.parse(rtc_candidate);
                    rtcPeerConnection.addIceCandidate(new RTCIceCandidate(candidate)).then(() => {
                        console.log('Add new ICE candidate: ', candidate);
                    }).catch((e) => {
                        console.log('Error Add new ICE candidate: ', e);
                    });
                }

                if (Stations) {
                    try{
                        let scoreCards = Object.values(JSON.parse(Stations))
                            .filter(s => s.type === LocalServerModel.STATION_TYPE.score_card)
                            .map(s => ({value: s.station, label: s.label || s.station}));
                        let ids = scoreCards.map(s => s.value);
                        let avails = availScorecardStations.map(s => s.value);
                        if (Utils.intersection(ids, avails).length > 0) {
                            setAvailableScorecardStations([NO_OVERLAY, ...scoreCards]);
                        }
                    }catch(e) {
                        console.log('Parsing Stations: ', e);
                    }
                }
                const init = async () => {
                    if (!message.data) {
                        return;
                    }
                    let t = tournament;
                    if (!t) {
                        t = await LocalServerModel.getTournament(Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament));
                        setTournament(t);
                    }
                };
                init();
            }
        }finally{
            updateAvailScorecardStations();
            updateOverlay();
        }
    };

    const initSSE = async () => {
        sse && sse.close();
        if (!tournament) {
            let id = Utils.cookie(LocalServerModel.LOCAL_SERVER.local_tournament);
            if (id) {
                let t = await LocalServerModel.getTournament(id);
                setTournament(t);
            }
        }
        sse = initStation({
            localServer,
            type: LocalServerModel.STATION_TYPE.camera,
            receive,
            history,
            forceUpdate: () => setRefresh(!refresh)
        });
    };

    const startCamera = async (_cameraDeviceId) => {
        // Try to access the camera
        navigator.mediaDevices.getUserMedia({ video: true, audio: true, deviceId: _cameraDeviceId || cameraDeviceId })
            .then((stream) => {
                // Check if the camera supports changing constraints (aspect ratio)
                if ('getVideoTracks' in stream && 'applyConstraints' in stream.getVideoTracks()[0]) {
                    const track = stream.getVideoTracks()[0];

                    // Define the new aspect ratio (16:9 in this example)
                    const newConstraints = {
                        aspectRatio: 16 / 9
                    };

                    // Apply the new constraints
                    track.applyConstraints(newConstraints);
                }
                // Attach the camera stream to the video element
                cameraStreamRef.current.srcObject = stream;
                stream.getTracks().forEach(track => {
                    rtcPeerConnection.addTrack(track, stream);
                });
                // if (isStationRTCReady) {
                //     initRTCPeerConnection();
                // }
            })
            .catch((error) => {
                console.error('Error accessing camera:', error);
            });
    }

    const saveVideo = () => {
        const {data} = station;
        if (tournament && data) {
            setIsSaving(true);
            const {schedule} = data;
            let fileName = [schedule.division, schedule.bracket_entry1 || schedule.bracket_entry2]
            let filePath = `data/tournament_${tournament.id}_${fileName.join('_')}.webm`;
            LocalServerModel.uploadFile(filePath, recordedBlob, status => setIsSaving(Utils.toPercentage(status)))
                .then(r => {
                    console.log('Saving to server completed: ', r);
                    setIsSaving(false);
                })
                .catch(e => {
                    console.log('Error: Saving video to server: ', e);
                    setIsSaving(false);
                });
        }
    }

    // The nested try blocks will be simplified when Chrome 47 moves to Stable
    const startRecording = () => {
        recordedBlob = null;
        recordedData = [];
        setIsPreview(false);
        setIsRecording(true);
        let recSize = 0;
        setRecordingSize(recSize);
        const stream = canvasRef.current.captureStream();
        station.mediaRecorder = new MediaRecorder(stream);
        station.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                setRecordingSize(Utils.bytesToSize(recSize += event.data.size));
                recordedData.push(event.data);
            }
        };
        station.mediaRecorder.onstop = () => {
            recordedBlob = new Blob(recordedData, { type: 'video/webm' });
            recordedData = [];
            setRecordingSize('');
        };
    }
    
    const isButton = (b) => {
        switch (b) {
            case 'rec': return !isPreview;
            case 'stop': return isRecording;
            case 'save': return !isRecording && recordedBlob;
            case 'preview': return !isPreview && !isRecording && (recordedBlob || recordedData.length>0) ;
            case 'done': return isPreview;
            case 'video': return !isPreview;
            case 'playback': return isPreview;
        }
    }
    const isHideButton = (b) => {
        if (!isButton(b)){
            return 'hide';
        }
        return '';
    }
    return redirect? redirect :
        <div className="LocalCamera">
            <h1>Camera Station {getStationName(station && station.label)}</h1>
            <AlertPane message={message} />
            <div className="camera">
                <div className="SelectFormInput-wrapper">
                    <SelectFormInput name="camera"
                        value={cameraDeviceId}
                        options={selectCameraDevices} 
                        label={'Select camera device'} 
                        onChange={v => {
                            setCameraDeviceId(v);
                        }}
                    /> 
                    <SelectFormInput name="overlay"
                        value={overlayStation}
                        options={availScorecardStations} 
                        label={'Select score card station'} 
                        onChange={v => {
                            setOverlayStation(v);
                        }}
                    /> 
                </div>
                <div className="buttons">
                    <button className={`button rec ${isHideButton('rec')}`} 
                        onClick={startRecording}>
                            <RecordIcon className={isRecording? 'blink':''}/> Rec <span className="recording">{recordingSize||''}</span>
                    </button>
                    <button className={`button ${isHideButton('stop')}`} 
                        onClick={() => {
                            setIsRecording(false);
                            station.mediaRecorder.stop();
                        }}><StopRecordIcon /> Stop</button>
                    <button className={`button save ${isHideButton('save')}`}  
                        onClick={saveVideo}>
                        <SaveRecordingIcon className={isSaving? 'blink':''}/> Save <span className="saving">{isSaving}</span>
                    </button>
                    <button className={`button ${isHideButton('preview')}`}  
                        onClick={() => {
                            console.log('Recorded Blobs: ', recordedBlob.size);
                            videoPlaybackRef.current && (videoPlaybackRef.current.src = window.URL.createObjectURL(recordedBlob));
                            setIsPreview(true);
                        }}><PlayRecordingIcon/> Preview</button>
                    <button className={`button ${isHideButton('done')}`}  
                        onClick={() => setIsPreview(false)}> Done</button>
                </div>
            </div>
            <div className={`playback`}>
                <video className={`videoPlaybackRef ${isHideButton('playback')}`} 
                    ref={videoPlaybackRef} autoPlay controls />
            </div>
            <div className={`video`}>        
                <div className={`stream_wrapper ${isHideButton('video')}`}>
                    <canvas className={`canvasRef`} ref={canvasRef}/>
                    <div className={`cameraStreamRef`} >
                        <div ref={videoRef} className="camera-wrapper">
                            <video autoPlay ref={cameraStreamRef} controls/>
                            <FullScreenIcon className="fullscreen" 
                                onClick={() => Utils.toggleFullscreen(videoRef.current)}/>
                            <OverLayScoreCard ref={overLayScoreCardRef}
                                data={overlayStation!=='-1' && overlayStationData} 
                                isRecording={isRecording}/>
                            <RTCStatusIcon 
                                className={`RTCStatusIcon ${getRTCConnState(rtcPeerConnection)}`} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ;
});

const OverLayScoreCard = forwardRef(({data={}, isRecording}, ref) => {
    const tournament = useStore(state => state.local_tournament);
    const [flag1, setFlag1] = useState();
    const [flag2, setFlag2] = useState();
    const [membership1, setMembership1] = useState();
    const [membership2, setMembership2] = useState();
    const [bracketEntry1, setBraketEntry1] = useState();
    const [bracketEntry2, setBraketEntry2] = useState();
    const imgRef = useRef();
    const OverLayScoreCardRef = useRef();
    useImperativeHandle(ref, () => ({
        getImage: () => imgRef.current && imgRef.current.length>0 && imgRef.current[imgRef.current.length-1],
    }));
    useEffect(() => {
        if (data && tournament) {
            const { bracket_entry1: be1, bracket_entry2: be2 } = data;
            const getFlag = (country, setFlag) => {
                import(`./../../icons/flags/png/${(country || 'us').toLowerCase()}.png`)
                .then(({ default: flag }) => {
                    setFlag(
                        <IconButton>
                            <img src={flag} alt="Icon" className="flag"/>
                        </IconButton>
                    );
                });
            }
            if (be1) {           
                let m1 = tournament.getMemberships().find(m => m.id === be1.membership);
                setMembership1(m1);
                !flag1 && getFlag(m1.country, setFlag1);
                setBraketEntry1(be1);
            }
            if (be2) {
                let m2 = tournament.getMemberships().find(m => m.id === be2.membership);
                setMembership2(m2);
                !flag2 && getFlag(m2.country, setFlag2);
                setBraketEntry2(be2);
            }
            if (isRecording) {
                html2canvas(OverLayScoreCardRef.current, {
                    scale: 1,
                    width: OverLayScoreCardRef.current.width,
                    height: OverLayScoreCardRef.current.height
                }).then(canvas => {
                    if (!imgRef.current) {
                        imgRef.current = [];
                    }
                    imgRef.current.push(canvas);
                });
            }else {
                imgRef.current = [];
            }
        }else {
            setBraketEntry1(undefined);
            setBraketEntry2(undefined);
            setMembership1(undefined);
            setMembership2(undefined);
            imgRef.current = [];
        }
    }, [data, tournament, isRecording]);

    const Scores = ({bracket_entry={}}) => {
        const {score, score2, scorep} = bracket_entry;
        return <div className="Scores"> 
            <span className="point">{Utils.padZeros(score || 0, 2)}</span>
            <span className="point2">{Utils.padZeros(score2 || 0, 2)}</span>
            <span className="neg">{Utils.padZeros(scorep || 0, 2)}</span>
        </div>;
    }

    const Comp = ({className, flag, membership, bracket_entry}) => {
        return <div className={`Reg ${className}`}>
            {flag}
            <div className="reg">{`${UserModel.getMembershipName(membership)}`}</div>
            <Scores bracket_entry={bracket_entry}/>
        </div>
    }

    return !data? '' :
        <div className={`OverLayScoreCard`} ref={OverLayScoreCardRef}>
            <div className="comps">
                <Comp className="reg1" flag={flag1} bracket_entry={bracketEntry1} membership={membership1}/>
                <Comp className="reg2" flag={flag2} bracket_entry={bracketEntry2} membership={membership2}/>
            </div>
            <div className="clock">{Utils.toDuration(data.clock)}</div>
        </div>;
});
